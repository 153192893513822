import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { enqueueSnackbar } from 'notistack';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const getSearchResults = createAsyncThunk('header/getSearchResults', async (searchText: string, { rejectWithValue }: Redux) => {
    try {
        const response = await api.get(`/api/search?text=${searchText}`);

        console.log(searchText);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const headerSlice = createSlice({
    name: 'header',
    initialState: {
        isSearching: false,
        openSearchDropDown: false,
        isSelectedSearchItem: false,
        searchResults: {
            prompts: [],
            hashtags: []
        }
    },
    reducers: {
        setOpenSearchDropDown: (state, action) => {
            state.openSearchDropDown = action.payload;
        },
        setIsSelectedSearchItem: (state, action) => {
            state.isSelectedSearchItem = action.payload;
        },
        setIsSearching: (state, action) => {
            state.isSearching = action.payload;
        },
        initSearchResult: (state) => {
            state.searchResults.hashtags = [];
            state.searchResults.prompts = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSearchResults.pending, (state) => {
                state.isSearching = true;
                state.searchResults.prompts = [];
                state.searchResults.hashtags = [];
            })
            .addCase(getSearchResults.fulfilled, (state, action) => {
                state.isSearching = false;
                state.searchResults.prompts = action.payload.prompts ?? [];
                state.searchResults.hashtags = action.payload.hashtags ?? [];

                console.log(action.payload);
            })
            .addCase(getSearchResults.rejected, (state, action) => {
                console.log(action.payload);

                state.isSearching = false;
                state.searchResults.prompts = [];
                state.searchResults.hashtags = [];
                enqueueSnackbar(
                    action.payload['error'] ?? 'Unexpected error occurred.',
                    { variant: 'error' }
                );
            });
    }
});

export const { setIsSearching, setOpenSearchDropDown, setIsSelectedSearchItem, initSearchResult } = headerSlice.actions;

export default headerSlice.reducer;
