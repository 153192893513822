export const GenerationsPointCols = {
    default: 5,
    1920: 5,
    1600: 4,
    1366: 3,
    1280: 3,
    960: 2,
    600: 2,
    480: 1
};

export const GenerationViewMethod = [
    'square',
    'ratio'
];

export const GenerationGridDefault = 8;
export const GenerationGridMax = 12;
export const GenerationGridMin = 6;
export const GenerationGridMobile = 3;