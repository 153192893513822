import { useState } from 'react';
import { IGif } from '@giphy/js-types';
import { Skeleton } from '@mui/material';
import { useAsync } from 'react-async-hook';
import { Gif } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';

const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_SDK_API_KEY);

const GiphyItem = ({ gifId }) => {
    const [gif, setGif] = useState<IGif | null>(null);
    
    useAsync(async () => {
        const { data } = await giphyFetch.gif(gifId);
        setGif(data);
    }, [gifId]);

    // return gif && <Gif gif={gif} width={200} />;
    return gif ? <Gif gif={gif} width={200} hideAttribution noLink /> : <Skeleton variant="rounded" width={200} height={200} />;
}

export default GiphyItem;