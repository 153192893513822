import axios from 'axios';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'store';
import { initResolutionForPaidUserAfterLogin } from 'store/generate';
import { AuthValuesType, ErrCallbackType, LoginParams, UserDataType } from 'types/gallerai/authTypes';
import { auth } from 'utils/firebase';

const defaultProvider: AuthValuesType = {
    user: null,
    badUser: null,
    loading: true,
    setUser: () => null,
    setBadUser: () => null,
    openAuthModal: false,
    openNsfwModal: false,
    openBirthdayModal: false,
    setLoading: () => Boolean,
    signIn: () => Promise.resolve(),
    signOut: () => null,
    setOpenAuthModal: () => Boolean,
    setOpenNsfwModal: () => Boolean,
    setOpenBirthdayModal: () => Boolean
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [user, setUser] = useState<UserDataType>(defaultProvider.user);
    const [badUser, setBadUser] = useState<any>(defaultProvider.badUser);
    const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
    const [openAuthModal, setOpenAuthModal] = useState<boolean>(defaultProvider.openAuthModal);
    const [openNsfwModal, setOpenNsfwModal] = useState<boolean>(defaultProvider.openNsfwModal);
    const [openBirthdayModal, setOpenBirthdayModal] = useState<boolean>(defaultProvider.openBirthdayModal);

    const handleSignIn = (params: LoginParams, errorCallback?: ErrCallbackType) => {
        if (!params.idToken) {
            console.error('Invalid id token');

            const customEvent = new CustomEvent('stopSocialProcess');
            window.dispatchEvent(customEvent);

            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}api/login`, params)
            .then(async (response) => {
                const { accessToken, refreshToken, user } = response.data;

                if (user?.account_status !== 'active') {
                    setBadUser(user);

                    return;
                }

                if (user?.plan != 'free') {
                    dispatch(initResolutionForPaidUserAfterLogin())
                }
                
                setUser({
                    uid: user.uid,
                    email: user.email,
                    username: user.username,
                    bio: user.bio,
                    url: user.url,
                    adult: user.adult,
                    credits: user.credits,
                    dailyLimits: user.daily_limit,
                    avatar: user.avatar,
                    blocky: user.blocky,
                    plan: user.plan,
                    firstName: user?.first_name ?? '',
                    lastName: user?.last_name ?? '',
                    country: user?.country ?? null,
                    timezone: user?.timezone ?? 'timezone',
                    createdAt: user?.created_at ?? 0
                });

                setLoading(false);
                setOpenBirthdayModal(false);
                setOpenNsfwModal(false);
                setOpenAuthModal(false);

                window.localStorage.setItem('accessToken', accessToken);
                window.localStorage.setItem('refreshToken', refreshToken);

                if(user.username == null) {
                    navigate('/account-setup');
                } else {
                    const previousLocation = localStorage.getItem('galleraiLocation');
                    if(previousLocation) {
                        navigate(previousLocation);
                    } else {
                        navigate('/home');
                    }
                }
            })
            .catch((err) => {
                if (errorCallback) errorCallback(err);
            })
            .finally(() => {
                const customEvent = new CustomEvent('stopSocialProcess');
                window.dispatchEvent(customEvent);
            });
    };

    const handleSignOut = (route: string | null) => {
        auth.signOut();
        setUser(null);
        setLoading(false);

        window.localStorage.removeItem('galleraiLocation');
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('refreshToken');

        if(route != null) {
            navigate(route);
        }        
    };

    useEffect(() => {
        const handleSignInWithEmailLink = async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                const email = window.localStorage.getItem('emailForSignIn');

                try {
                    const result = await signInWithEmailLink(auth, email, window.location.href);
                    const { user } = result;
                    const idToken = await user.getIdToken();
                    handleSignIn({ idToken });
                    window.localStorage.removeItem('emailForSignIn');
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const initAuth = async () => {
            const storedAccessToken = window.localStorage.getItem('accessToken');
            const storedRefreshToken = window.localStorage.getItem('refreshToken');

            if (!storedAccessToken || !storedRefreshToken) {
                handleSignOut(null);

                return;
            }

            setLoading(true);

            try {
                await handleToken(storedAccessToken);
            } catch {
                try {
                    await handleToken(storedRefreshToken);
                } catch {
                    handleSignOut(null);
                }
            } finally {
                setLoading(false);
                const customEvent = new CustomEvent('stopSocialProcess');
                window.dispatchEvent(customEvent);
            }
        };

        const handleToken = async (token: string) => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/refresh`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=utf-8'
                }
            });

            const { accessToken, refreshToken, user } = response.data;

            if (user?.plan != 'free') {
                dispatch(initResolutionForPaidUserAfterLogin())
            }

            setUser({
                uid: user.uid,
                email: user.email,
                username: user.username,
                bio: user.bio,
                url: user.url,
                adult: user.adult,
                credits: user.credits,
                dailyLimits: user.daily_limit,
                avatar: user.avatar,
                blocky: user.blocky,
                plan: user.plan,
                firstName: user?.first_name ?? '',
                lastName: user?.last_name ?? '',
                country: user?.country ?? null,
                timezone: user?.timezone ?? 'timezone',
                createdAt: user?.created_at ?? 0
            });

            window.localStorage.setItem('accessToken', accessToken);
            window.localStorage.setItem('refreshToken', refreshToken);

            if(user.username == null) {
                navigate('/account-setup');
            }
        };

        handleSignInWithEmailLink();
        initAuth();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            // This callback will be called when localStorage changes
            const idToken = window.localStorage.getItem('idToken');

            handleSignIn({ idToken: idToken });
            window.localStorage.removeItem('idToken');
        };

        // Add event listener for the storage event
        window.addEventListener('storage', handleStorageChange);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };

        // eslint-disable-next-line
    }, []);

    const values = {
        user,
        badUser,
        loading,
        setUser,
        setBadUser,
        setLoading,
        openAuthModal,
        openNsfwModal,
        openBirthdayModal,
        setOpenAuthModal,
        setOpenNsfwModal,
        setOpenBirthdayModal,
        signIn: handleSignIn,
        signOut: handleSignOut
    };

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
