import api from '../../utils/api';
import { ForumTopic } from 'types/gallerai/forumTypes';
import { Dispatch, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const getTopic = createAsyncThunk('topic/getTopic', async (id: string) => {
    const response = await api.get('/api/admin/forum/topic', { params: { id: id }});

    return response.data;
});

export const getTopics = createAsyncThunk('topic/getTopics', async (categoryId: string, { dispatch } : Redux) => {
    dispatch(initTopicAdapter());

    const response = await api.get('/api/admin/forum/topics', { params: { categoryId: categoryId }});

    return response.data;
});

export const createTopic = createAsyncThunk('topic/createTopic', async (data: any, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.post('/api/admin/forum/topic/create', data);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateTopic = createAsyncThunk('topic/updateTopic', async (data: any, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.post('/api/admin/forum/topic/update', data);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteTopic = createAsyncThunk('topic/deleteTopic', async (id: string, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.delete('/api/admin/forum/topic', { params: { id: id } });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const likeTopic = createAsyncThunk('topic/likeTopic', async (id: string, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.get('/api/forum/topic/like', { params: { id: id } });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

const topicAdapter = createEntityAdapter<ForumTopic>({});

export const { selectById: selectTopicById, selectAll: selectAllTopics } = topicAdapter.getSelectors(
    (state: RootState) => state.topic
);

export const topicSlice = createSlice({
    name: 'topic',
    initialState: topicAdapter.getInitialState({
        isLoading: false,
    }),
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        initTopicAdapter: (state) => {
            topicAdapter.removeAll(state);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTopic.fulfilled, (state, action) => {
                console.log(state.isLoading)
                console.log(action.payload)
            })
            .addCase(updateTopic.fulfilled, (state, action) => {
                const { topic } = action.payload;
                
                const temp = {
                    id: topic._id,
                    name: topic.name,
                    categoryId: topic.category,
                    content: topic.content,
                    author: {
                        email: topic?.email,
                        name: topic?.name,
                        avatar: topic?.avatar,
                        photoURL: topic?.photo_url
                    },
                    favorite: topic?.favorite ?? [],
                    createdAt: topic.created_at
                };

                topicAdapter.upsertOne(state, temp);
            })
            .addCase(deleteTopic.fulfilled, (state, action) => {
                const { status } = action.payload;

                if(status === true) {
                    topicAdapter.removeOne(state, action.meta.arg);
                }
            })
            .addCase(getTopics.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTopics.fulfilled, (state, action) => {
                state.isLoading = false;
                const { topics } = action.payload;

                topics.map((topic: any) => {
                    const temp = {
                        id: topic._id,
                        name: topic.name,
                        categoryId: topic.category,
                        content: topic.content,
                        author: topic?.author,
                        favorite: topic?.favorite ?? [],
                        createdAt: topic.created_at
                    };

                    topicAdapter.addOne(state, temp);
                });
            })
            .addCase(likeTopic.fulfilled, (state, action) => {
                const { topic } = action.payload;
                
                const temp = {
                    id: topic._id,
                    name: topic.name,
                    categoryId: topic.category,
                    content: topic.content,
                    author: {
                        email: topic?.email,
                        name: topic?.name,
                        avatar: topic?.avatar,
                        photoURL: topic?.photo_url
                    },
                    favorite: topic?.favorite ?? [],
                    createdAt: topic.created_at
                };

                topicAdapter.upsertOne(state, temp);
            })
    }
});

export const { setIsLoading, initTopicAdapter } = topicSlice.actions;

export default topicSlice.reducer;
