import { useEffect } from 'react';
import { Stack } from '@mui/material';
import styles from './home-ad-card.module.scss';
import { ImageCardPropType } from 'types/gallerai/homeTypes';

// Define the type for adsbygoogle
type AdsByGoogle = {
    push: (args: any) => void;
};

const HomeAdCard = ({ isMobile }: ImageCardPropType) => {
    useEffect(() => {
        try {
            // Cast window.adsbygoogle to AdsByGoogle type
            ((window.adsbygoogle as unknown) as AdsByGoogle).push({});
        } catch (e) {
            console.error('AdSense error:', e);
        }
    }, []);

    return (
        <Stack
            className={styles.container}
            sx={{
                borderRadius: isMobile ? 0 : '6px',
                width: '100%',
                maxWidth: isMobile ? '100%' : '500px', // Ensure a reasonable max width
                // minWidth: '250px', // Ensure minimum width for ad
            }}
        >
            <Stack position="relative">
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-3786687070238201"
                    data-ad-slot="7348217726"
                    data-ad-format="fluid"
                    data-ad-layout-key="+24+rx+1+2-3"
                    ></ins>
            </Stack>
        </Stack>
    );
};

export default HomeAdCard;
