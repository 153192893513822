import { lazy } from 'react';
import Loadable from 'components/Loadable';

const MainLayout = Loadable(lazy(() => import('layouts/main')));
const AdminLayout = Loadable(lazy(() => import('layouts/admin')));
const UserListPage = Loadable(lazy(() => import('pages/admin/users/list')));
const UserEditPage = Loadable(lazy(() => import('pages/admin/users/edit')));
const ModelListPage = Loadable(lazy(() => import('pages/admin/models/list')));
const ModelCreatePage = Loadable(lazy(() => import('pages/admin/models/create')));
const ModelEditPage = Loadable(lazy(() => import('pages/admin/models/edit')));
const LoraListPage = Loadable(lazy(() => import('pages/admin/loras/list')));
const LoraCreatePage = Loadable(lazy(() => import('pages/admin/loras/create')));
const LoraEditPage = Loadable(lazy(() => import('pages/admin/loras/edit')));
const RunpodSettingPage = Loadable(lazy(() => import('pages/admin/runpod/setting')));
const RunpodDownloadPage = Loadable(lazy(() => import('pages/admin/runpod/download'))); 
const CategoryListPage = Loadable(lazy(() => import('pages/admin/forum/category/list')));
const CategoryCreatePage = Loadable(lazy(() => import('pages/admin/forum/category/create')));
const CategoryUpdatePage = Loadable(lazy(() => import('pages/admin/forum/category/update')));
const TopicListPage = Loadable(lazy(() => import('pages/admin/forum/topic/list')));
const TopicCreatePage = Loadable(lazy(() => import('pages/admin/forum/topic/create')));
const TopicUpdatePage = Loadable(lazy(() => import('pages/admin/forum/topic/update')));
const AdminDashboardPage = Loadable(lazy(() => import('pages/admin/dashboard')));
const GenerationListPage = Loadable(lazy(() => import('pages/admin/generations/list')));
const GenerationShowPage = Loadable(lazy(() => import('pages/admin/generations/show')));
const PostListPage = Loadable(lazy(() => import('pages/admin/posts/list')));
const PostEditPage = Loadable(lazy(() => import('pages/admin/posts/edit')));

const Admin = [
    {
        path: '/admin/dashboard',
        element: <AdminLayout />,
        children: [{ element: <AdminDashboardPage />, index: true }]
    },
    {
        path: '/admin/users',
        element: <AdminLayout />,
        children: [{ element: <UserListPage />, index: true }]
    },
    {
        path: '/admin/users/edit/:id',
        element: <AdminLayout />,
        children: [{ element: <UserEditPage />, index: true }]
    },
    {
        path: '/admin/models',
        element: <AdminLayout />,
        children: [{ element: <ModelListPage />, index: true }]
    },
    {
        path: '/admin/models/create',
        element: <AdminLayout />,
        children: [{ element: <ModelCreatePage />, index: true }]
    },
    {
        path: '/admin/models/edit/:id',
        element: <AdminLayout />,
        children: [{ element: <ModelEditPage />, index: true }]
    },
    {
        path: '/admin/loras',
        element: <AdminLayout />,
        children: [{ element: <LoraListPage />, index: true }]
    },
    {
        path: '/admin/loras/create',
        element: <AdminLayout />,
        children: [{ element: <LoraCreatePage />, index: true }]
    },
    {
        path: '/admin/loras/edit/:id',
        element: <AdminLayout />,
        children: [{ element: <LoraEditPage />, index: true }]
    },
    {
        path: '/admin/blog/categories',
        element: <AdminLayout />,
        children: [{ element: <CategoryListPage />, index: true }]
    },
    {
        path: '/admin/blog/topics',
        element: <AdminLayout />,
        children: [{ element: <TopicListPage />, index: true }]
    },
    {
        path: '/admin/runpod',
        element: <MainLayout />,
        children: [{ element: <RunpodSettingPage />, index: true }]
    },
    {
        path: '/admin/runpod/download',
        element: <MainLayout />,
        children: [{ element: <RunpodDownloadPage />, index: true }]
    },
    {
        path: '/admin/forum/category/list',
        element: <MainLayout />,
        children: [{ element: <CategoryListPage />, index: true }]
    },
    {
        path: '/admin/blog/categories/create',
        element: <AdminLayout />,
        children: [{ element: <CategoryCreatePage />, index: true }]
    },
    {
        path: '/admin/blog/categories/edit/:id',
        element: <AdminLayout />,
        children: [{ element: <CategoryUpdatePage />, index: true }]
    },
    {
        path: '/admin/blog/topics/create',
        element: <AdminLayout />,
        children: [{ element: <TopicCreatePage />, index: true }]
    },
    {
        path: '/admin/blog/topics/:id/edit',
        element: <AdminLayout />,
        children: [{ element: <TopicUpdatePage />, index: true }]
    },
    {
        path: '/admin/generations',
        element: <AdminLayout />,
        children: [{ element: <GenerationListPage />, index: true }]
    },
    {
        path: '/admin/generations/:id/show',
        element: <AdminLayout />,
        children: [{ element: <GenerationShowPage />, index: true }]
    },
    {
        path: '/admin/posts',
        element: <AdminLayout />,
        children: [{ element: <PostListPage />, index: true }]
    },
    {
        path: '/admin/posts/:id/edit',
        element: <AdminLayout />,
        children: [{ element: <PostEditPage />, index: true }]
    }
];

export default Admin;
