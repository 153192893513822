import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import useDebounce from 'hooks/useDebounce';
import styles from './edit-dialog.module.scss';
import { AppDispatch, RootState } from 'store';
import { extractHashtags, numberWithCommas } from 'utils/utils';
import { useSnackbar } from 'components/snackbar';
import { AuthContext } from 'contexts/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import FormProvider, { RHFSwitch } from 'components/hook-form';
import { useContext, useEffect, useState, useMemo } from 'react';
import { updatePost, getSuggestedHashtags, initSuggestedHashtags } from 'store/generations';
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';
import { Dialog, DialogProps, Typography, Stack, TextareaAutosize, CircularProgress, Box, Skeleton } from '@mui/material';

const matcher = new RegExpMatcher({
	...englishDataset.build(),
	...englishRecommendedTransformers,
});

interface Props extends DialogProps {
    onClose: VoidFunction;
    data?: any;
    user?: any;
    handleUpdate: (data: any) => void;
}

const EditDialog = ({ onClose, open, data, handleUpdate }: Props) => {
    // Hooks
    const methods = useForm();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();
    
    // Context
    const { user } = useContext(AuthContext);

    // Component state
    const [isNSFW, setIsNSFW] = useState(false);
    const [caption, setCaption] = useState('');
    const [hideSeed, setHideSeed] = useState(false);
    const debouncedCaption = useDebounce(caption, 500);
    const disabled = useMemo(() => !!(caption && (caption.length > 280 || !caption.length)), [caption]);
    const [imageLoaded, setImageLoaded] = useState(false);

    // Redux state
    const isPosting = useSelector((state: RootState) => state.generations.isPosting);
    const isGettingSuggestedHashtags = useSelector((state: RootState) => state.generations.isGettingSuggestedHashtags);
    const suggestedHashtags = useSelector((state: RootState) => state.generations.suggestedHashtags);
    const is_vertical = useMemo(
        () => (data?.metadata.W || data?.metadata.width || 1) / (data?.metadata.H || data?.metadata.height || 0) < 0.6,
        [data]
    );

    useEffect(() => {
        if(!data) return;

        setCaption(data?.caption);
        setHideSeed(data?.hideSeed);
        setIsNSFW(data?.isNSFW);
        dispatch(initSuggestedHashtags());
    }, [data, dispatch]);

    const handleCaption = (value: string) => setCaption(value);

    const handleTag = (value: string) => {
        console.log(value);
        const words = caption.split(' ');

        let lastHashtagIndex = -1;
        for (let i = words.length - 1; i >= 0; i--) {
            if (words[i].startsWith('#')) {
                lastHashtagIndex = i;
                break;
            }
        }

        if (lastHashtagIndex !== -1) {
            words[lastHashtagIndex] = '#' + value;

            const modifiedString = words.join(' ');
            setCaption(modifiedString + ' ');
        }

        const ref = document.getElementById('caption_textarea');
        if(ref) ref.focus();

        dispatch(initSuggestedHashtags());
    }

    const removeWordsInRange = (text: string, startIndex: number, endIndex: number) => {
        const words = text.split(' ');
        const sanitizedWords = words.filter((word, index) => {
            console.log(index);

            const wordStartIndex = text.indexOf(word);
            const wordEndIndex = wordStartIndex + word.length - 1;

            return wordEndIndex < startIndex || wordStartIndex > endIndex;
        });

        return sanitizedWords.join(' ');
    };

    const handleSave = async () => {
        if (disabled) return;

        const { hashtags, filteredCaption } = extractHashtags(caption);

        if(hashtags.length < 1) {
            enqueueSnackbar('Please input at least 1 hashtag.', { variant: 'error' });

            return;
        }

        let profanityRemovedCaption = filteredCaption;
        let matches = [];
        
        do {
            matches = matcher.getAllMatches(profanityRemovedCaption);
            console.log(matches);
            matches.forEach((item) => {
                profanityRemovedCaption = removeWordsInRange(profanityRemovedCaption, item?.startIndex, item?.endIndex);
            });
        } while (matches.length !== 0);

        const postData = {
            id: data.id,
            isNSFW,
            hideSeed,
            generationId: null,
            caption: profanityRemovedCaption
        }

        dispatch(updatePost(postData)).then(() => {
            setCaption(profanityRemovedCaption);
            handleUpdate(postData);
        });
    };

    useEffect(() => {
        if (debouncedCaption && debouncedCaption.trim()) {
            const { hashtags } = extractHashtags(debouncedCaption);
            const lastInputIsHashtag = hashtags && debouncedCaption.trim().endsWith(hashtags[hashtags.length - 1]);
            const lastCharIsWhitespace = debouncedCaption.slice(-1).trim() === '';
    
            if (lastInputIsHashtag && !lastCharIsWhitespace) {
                dispatch(getSuggestedHashtags(hashtags[hashtags.length - 1]));
            } else {
                dispatch(initSuggestedHashtags());
            }
        } else {
            dispatch(initSuggestedHashtags());
        }
    }, [debouncedCaption, dispatch]);

    return (
        <>
            <Dialog
                maxWidth="md"
                onClose={onClose}
                open={open}
                classes={{
                    paper: styles.dialog_paper,
                    paperScrollPaper: styles.dialog_paper_poot
                }}
                sx={{
                    '& .MuiModal-backdrop': {
                        background: 'var(--secondary-background-color)'
                    }
                }}
            >
                <div className={styles.share_dialog_header_container}>
                    <div style={{ marginLeft: '16px' }}>
                        <Typography className={styles.btn_cancel} onClick={onClose}>
                            Cancel
                        </Typography>
                    </div>
                    <div>
                        <Typography className={styles.edit_info}>
                            Edit info
                        </Typography>
                    </div>
                    <div className={styles.load_btn_container}>
                        <LoadingButton
                            disableRipple
                            className={disabled ? styles.share_disabled_btn : styles.share_btn}
                            loading={isPosting}
                            disabled={disabled}
                            onClick={handleSave}
                            sx={{
                                '& .MuiCircularProgress-root': {
                                    color: '#67FC84', // Change the color of the circular progress indicator
                                },
                            }}
                        >
                            Save
                        </LoadingButton>
                    </div>
                </div>
                <Stack sx={{ flexDirection: { md: 'row', xs: 'column' } }} flex={1}>
                    <Stack sx={{ width: { md: 'auto', xs: '100%' }, minWidth: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} flex={1}>
                        {!imageLoaded && <Skeleton variant="rectangular" animation="pulse" width="100%" height='100%' style={{ position: 'absolute', zIndex: '10', top: 0, left: 0}} />}
                        {data && (
                            <img
                                src={data?.original}
                                alt="my-generations"
                                onLoad={() => setImageLoaded(true)}
                                style={{
                                    width: '100%',
                                    // height: { md: is_vertical ? 720 : 'auto', xs: 'auto' },
                                    height: is_vertical ? 720 : 'auto',
                                    maxHeight: '100%',
                                    objectFit: 'cover',
                                    minHeight: imageLoaded ? 'unset' : '100%'
                                }}
                            />
                        )}
                    </Stack>
                    <Stack sx={{ width: { md: 340, xs: '100%' }, borderTop: '1px solid #313131' }}>
                        <div className={styles.share_dialog_left_info}>
                            <div className={styles.avatar}>
                                <GalleraiAvatar user={user} width={28} height={28} size={7} scale={4}/>
                                <Typography className={styles.avatar_name}>{user?.username}</Typography>
                            </div>
                            <TextareaAutosize
                                id="caption_textarea"
                                className={`primary-text-style ${styles.caption_text_area}`}
                                value={caption}
                                onChange={(event) => handleCaption(event.target.value)}
                                placeholder="Write a caption..."
                            />
                            <div className={styles.caption_limit}>
                                {caption && (
                                    <p className={`primary-text-style ${styles.caption_limit_text}`}>
                                        {caption.length}/280
                                    </p>
                                )}
                            </div>
                            <Stack spacing={1} flex={1} style={{ maxHeight: '260px', overflow: 'scroll' }}>
                                {
                                    (!isGettingSuggestedHashtags && suggestedHashtags.length == 0) && (
                                        <FormProvider methods={methods}>
                                            <Stack>
                                                <Stack pl={2} py={1}>
                                                    <RHFSwitch
                                                        name="hideSeed"
                                                        checked={hideSeed}
                                                        onChange={(e: any) => setHideSeed(e.target.checked)}
                                                        labelPlacement="start"
                                                        label="Hide Seed"
                                                        className={styles.switch}
                                                    />
                                                    <Typography className={`primary-text-style ${styles.switch_description}`}>
                                                        You can change this later by going to the menu at the top of your post.
                                                    </Typography>
                                                </Stack>

                                                <Stack pl={2}>
                                                    <RHFSwitch
                                                        name="nsfw"
                                                        checked={isNSFW}
                                                        onChange={(e: any) => setIsNSFW(e.target.checked)}
                                                        labelPlacement="start"
                                                        label="NSFW"
                                                        className={styles.switch}
                                                    />
                                                    <Typography className={`primary-text-style ${styles.switch_description}`}>
                                                        Is this content NSFW? Tag this image as NSFW to keep our community protected.
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </FormProvider>
                                    )
                                }
                                {
                                    isGettingSuggestedHashtags ?
                                        <Box className={styles.tag_loading}>
                                            <CircularProgress size={16} />
                                        </Box>
                                        :
                                        suggestedHashtags.map((hashtag, index) => {
                                            return (
                                                <Stack key={index} className={styles.hashtag_cantainer} onClick={() => { handleTag(hashtag.tag) }}>
                                                    <Typography className={`primary-text-style ${styles.hashtag}`}>
                                                        #{hashtag.tag}
                                                    </Typography>
                                                    <Typography className={'primary-text-style'}>
                                                        {numberWithCommas(parseInt(hashtag.count, 10))} posts
                                                    </Typography>
                                                </Stack>
                                            );
                                        })
                                }
                            </Stack>
                        </div>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    );
};

export default EditDialog;
