import styles from './header-credit.module.scss';
import { Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { AuthContext } from '../../../contexts/AuthContext';
import { calculateCreditsTimeSimply, getPlan, numberWithCommas } from 'utils/utils';

const GpuTimer = ({ balance } : { balance: number }) => {
    return (
        <>
            {
                calculateCreditsTimeSimply(balance).type == 'hour' ? 
                <div style={{ display: 'flex', gap: '2px' }}>
                    <Typography className={styles.remain_time}>
                        {calculateCreditsTimeSimply(balance).hours + ' hr'}
                    </Typography>
                    <Typography className={styles.remain_time}>
                        :
                    </Typography>
                    <Typography className={styles.remain_time}>
                        {calculateCreditsTimeSimply(balance).minutes + ' min'}
                    </Typography>
                </div>                                    
                :
                calculateCreditsTimeSimply(balance).type == 'minute' ? 
                <div style={{ display: 'flex', gap: '2px' }}>
                    <Typography className={styles.remain_time}>
                        {calculateCreditsTimeSimply(balance).minutes + ' min'}
                    </Typography>
                    <Typography className={styles.remain_time}>
                        :
                    </Typography>
                    <Typography className={styles.remain_time}>
                        {calculateCreditsTimeSimply(balance).seconds + ' sec'}
                    </Typography>
                </div> 
                :
                calculateCreditsTimeSimply(balance).type == 'second' ? 
                <div style={{ display: 'flex', gap: '2px' }}>
                    <Typography className={styles.remain_time}>
                        {calculateCreditsTimeSimply(balance).seconds + ' sec'}
                    </Typography>
                </div> 
                :
                <></>
            }
        </>
    )
}

const HeaderGpuTimer = ({ balance } : { balance: number }) => {
    return (
        <>
            {
                calculateCreditsTimeSimply(balance).type == 'hour' ? 
                <div style={{ display: 'flex', gap: '2px' }}>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        {calculateCreditsTimeSimply(balance).hours + ' hr'}
                    </Typography>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        :
                    </Typography>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        {calculateCreditsTimeSimply(balance).minutes + ' min'}
                    </Typography>
                </div>                                    
                :
                calculateCreditsTimeSimply(balance).type == 'minute' ? 
                <div style={{ display: 'flex', gap: '2px' }}>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        {calculateCreditsTimeSimply(balance).minutes + ' min'}
                    </Typography>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        :
                    </Typography>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        {calculateCreditsTimeSimply(balance).seconds + ' sec'}
                    </Typography>
                </div> 
                :
                calculateCreditsTimeSimply(balance).type == 'second' ? 
                <div style={{ display: 'flex', gap: '2px' }}>
                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>
                        {calculateCreditsTimeSimply(balance).seconds + ' sec'}
                    </Typography>
                </div> 
                :
                <></>
            }
        </>
    )
}

const UserBalance = ({ user, show }) => {
    const maxBalance = user?.plan != 'free' ? getPlan(user?.plan).maxBalance : 3;
    const percent = user?.plan != 'free' ? parseInt(user?.credits) / maxBalance : parseInt(user?.dailyLimits) / 3;
    let color = '#FB2047';
    
    if(user?.plan == 'free') {
        color = percent >= 0.67 ? '#67FC84' : percent >= 0.34 ? '#FFC700' : percent >= 0.01 ? '#FB2047' : '#FB2047';        
    } else {
        color = percent >= 0.5 ? '#67FC84' : percent >= 0.2 ? '#FFC700' : percent >= 0.01 ? '#FB2047' : '#FB2047';
    }

    const sliderValue = {
        width: `${percent * 100}%`,
        maxWidth: '100%',
        background: color
    }

    const [openSlider, setOpenSlider] = useState(true);

    useEffect(() => {
        if(!show) setOpenSlider(true);
    }, [show])

    return (
        <Stack className={styles.user_credit_information}>
            <Stack className={styles.user_balancer} onClick={() => setOpenSlider(!openSlider)}>
                <Stack sx={{ gap: '2px' }}>
                    <Stack className={styles.credit_balance_title}>
                        <GalleraiIcon name='clockRight' width={14} height={14} />
                        <Typography className={styles.gpu_balance}>GPU Balance</Typography>
                    </Stack>
                    {user.plan == 'free' ? (
                        <Typography className={styles.credits_description}>You receive 3 free generations per day on the starter plan.</Typography>        
                    ) : (
                        <Typography className={styles.credits_description}>Monitor your GPU spend with millisecond accuracy. Server delays and model cold starts do not count towards your generation consumption.</Typography>
                    )}
                </Stack>
            </Stack>
            {openSlider && (
                <Stack className={styles.balance_slider_container}>
                    <Stack className={styles.ms_container}>
                        {(user?.plan != 'free' && percent < 0.5) && (
                            <GalleraiIcon name='alert' width={14} height={14} className={percent >= 0.2 ? styles.alert_yellow : percent >= 0.01 ? styles.alert_red : styles.alert_red}/>
                        )}
                        {(user?.plan == 'free' && percent <= 0.67) && (
                            <GalleraiIcon name='alert' width={14} height={14} className={percent >= 0.2 ? styles.alert_yellow : percent >= 0.01 ? styles.alert_red : styles.alert_red}/>
                        )}
                        {user?.plan == 'free' ? (
                            <div className={styles.valance_and_type}>
                                <Typography className={styles.daily_limit_value}>{user?.dailyLimits}</Typography>
                                <span className={styles.generation_remaining}>generations remaining</span>
                            </div>
                        ) : (
                            <div className={styles.valance_and_type}>
                                <Typography className={styles.credits_value}>{numberWithCommas(user?.credits)}</Typography>
                                <span className={styles.generation_remaining}>ms</span>
                            </div>
                        )}
                    </Stack>
                    <Stack className={styles.slider_container}>
                        <Stack className={styles.background_slider} />
                        <Stack className={styles.value_slider} style={sliderValue} />
                    </Stack>
                    {
                        user?.plan != 'free' && (
                            <Stack className={styles.balance_timer}>
                                <GpuTimer balance={user?.credits} />
                                <Typography className={styles.remain_time_percentage}>{(percent * 100).toFixed(2)}%</Typography>
                            </Stack>
                        )
                    }
                    {
                        user?.plan == 'free' && (
                            <Stack className={styles.balance_timer}>
                                <Typography className={styles.upgrade_your_plan}>Upgrade your plan</Typography>
                                <Typography className={styles.remain_time_percentage}>{(percent * 100).toFixed(0)}%</Typography>
                            </Stack>
                        )
                    }
                </Stack>
            )}
        </Stack>
    );
}

const HeaderCredit = () => {
    // Context
    const { user } = useContext(AuthContext);

    // State
    const [showMilliseconds, setShowMilliseconds] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const maxBalance = user?.plan != 'free' ? getPlan(user?.plan).maxBalance : 3;
    const percent = user?.plan != 'free' ? user?.credits / maxBalance : user?.dailyLimits / 3;

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleCreditView = () => {
        setShowMilliseconds((prev) => !prev);
        window.localStorage.setItem('creditView', (!showMilliseconds).toString());
    }

    useEffect(() => {
        const creditView = window.localStorage.getItem('creditView');
        if (creditView && creditView == 'true') {
            setShowMilliseconds(true)
        } else {
            setShowMilliseconds(false)
        }
    }, [])

    return (
        <Stack className={styles.container} onClick={handleCreditView}>
            {
                user?.plan != 'free' ? (
                    <>
                    {
                        percent < 0.5 && (
                            <GalleraiIcon name='alert' width={14} height={14} className={percent >= 0.2 ? styles.alert_yellow : percent >= 0.01 ? styles.alert_red : styles.alert_red}/>
                        )
                    }
                    </>
                ) : (
                    <>
                    {
                        percent <= 0.67 && (
                            <GalleraiIcon name='alert' width={14} height={14} className={percent >= 0.2 ? styles.alert_yellow : percent >= 0.01 ? styles.alert_red : styles.alert_red}/>
                        )
                    }
                    </>
                )
            }
            <Stack fontSize={'0.875rem'} fontWeight={510} fontFamily={'var(--primary-font-family)'} lineHeight={'1.25rem'}>
                {
                    user.plan == 'free' ? (
                        <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>{numberWithCommas(user.dailyLimits) + ' Credits'}</Typography>
                    ) : (
                        <>
                            {
                                showMilliseconds ? (
                                    <Typography className={styles.remain_time} sx={{ fontSize: 'var(--primary-font-size) !important' }}>{numberWithCommas(user.credits) + ' ms'}</Typography>
                                ) : (
                                    <HeaderGpuTimer balance={user?.credits} />
                                )
                            }
                        </>
                    )
                }
            </Stack>
            <div
                className={styles.info_icon_container}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <GalleraiIcon name='infoWhite' width={16} height={16} className={styles.info_white} />
            </div>
            { isHovered && (
                <Stack direction="column" className={styles.pop_up_container}>
                    <UserBalance user={user} show={true} />
                </Stack >
            )}
        </Stack >
    );
};

export default HeaderCredit;
