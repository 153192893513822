// @mui
import { Button, Stack, Typography } from '@mui/material';
// import MenuPopover from 'components/menu-popover';
import useResponsive from 'hooks/useResponsive';
// import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './footer.module.scss';

import { socials } from './config-footer';

// ----------------------------------------------------------------------

export default function Footer() {
    // const [language, setLanguage] = useState<string>('English(US)');
    // const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const isMD = useResponsive('down', 'md');
    const isSM = useResponsive('down', 500);

    // const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    //     setOpenPopover(event.currentTarget);
    // };

    // const handleClosePopover = () => {
    //     setOpenPopover(null);
    // };

    return (
        <>
            {
                (isMD || isSM) ? 
                    <Stack id="gallerai-footer-mobile" direction='column' width="100%" sx={{ p: '1.5rem', background: '#131313' }}>
                        <Stack direction='row' flexWrap='wrap' spacing={0.5}>
                            <Link to="/mission">
                                <Typography className={styles.footer_text}>
                                    Mission
                                </Typography>
                            </Link>
                            <Typography className={styles.footer_text}>·</Typography>
                            <Link to="/pricing">
                                <Typography className={styles.footer_text}>
                                    Pricing
                                </Typography>
                            </Link>
                            <Typography className={styles.footer_text}>·</Typography>
                            <Link to="/legal">
                                <Typography className={styles.footer_text}>
                                    Legal
                                </Typography>
                            </Link>
                            <Typography className={styles.footer_text}>·</Typography>
                            <Typography className={styles.footer_text_disabled}>
                                Change log
                            </Typography>
                            <Typography className={styles.footer_text}>·</Typography>
                            <Link to="/sitemap">
                                <Typography className={styles.footer_text}>
                                    Sitemap
                                </Typography>
                            </Link>
                        </Stack>
                        <Stack sx={{ mt: '1rem' }}>
                            {
                                <Typography className={styles.footer_text}>
                                    © <>{new Date().getFullYear()}</> Gallerai. All rights reserved
                                </Typography>
                            }
                        </Stack>
                    </Stack>
                    :
                    <Stack id="gallerai-footer-desktop" width="100%" sx={{ px: 0, background: '#131313' }}>
                        <Stack alignItems="center" my={2} spacing={1} sx={{ px: { md: 3, xs: 3 } }}>
                            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" width="100%" spacing={3}>
                                <Stack>
                                    {
                                        <Typography className={styles.footer_text}>
                                            © <>{new Date().getFullYear()}</> Gallerai. All rights reserved
                                        </Typography>
                                    }
                                </Stack>
                                <Stack direction="row" spacing={3}>
                                    <Link to="/mission">
                                        <Typography className={styles.footer_text}>
                                            Mission
                                        </Typography>
                                    </Link>
                                    <Link to="/pricing">
                                        <Typography className={styles.footer_text}>
                                            Pricing
                                        </Typography>
                                    </Link>
                                    <Link to="/legal">
                                        <Typography className={styles.footer_text}>
                                            Legal
                                        </Typography>
                                    </Link>
                                    <Typography className={styles.footer_text_disabled}>
                                        Change log
                                    </Typography>
                                    <Link to="/sitemap">
                                        <Typography className={styles.footer_text}>
                                            Sitemap
                                        </Typography>
                                    </Link>
                                </Stack>
                                <Stack
                                    flexGrow={1}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    spacing={2}
                                    pr={0.2}
                                >
                                    {socials.map((list, index) => (
                                        <Link
                                            key={index}
                                            to={list.path}
                                            style={{ alignItems: 'center', display: 'flex', color: 'white' }}
                                        >
                                            <Button disableRipple className={styles.btn_social}>
                                                {list.icon}
                                            </Button>
                                        </Link>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
            }
        </>
    );
}
