import { useContext, useEffect, useState, createContext } from 'react';
import { ChildrenProps } from 'types/ChildrenProps';
import BigNumber from 'bignumber.js';
// import { useSui } from 'hooks/useSui';

export const useBalance = () => {
  return useContext(BalanceContext);
};

interface BalanceContextProps {
  balance: BigNumber;
  isLoading: boolean;
  handleRefreshBalance: () => void;
}

export const BalanceContext = createContext<BalanceContextProps>({
  balance: BigNumber(0),
  isLoading: true,
  handleRefreshBalance: () => {return},
});

export const SuiBalanceProvider = ({ children }: ChildrenProps) => {
  const [balance, setBalance] = useState(BigNumber(0));
  const [isLoading, setIsLoading] = useState(false);
  console.log(setBalance, setIsLoading);
  // const { suiClient } = useSui();

  useEffect(() => {
    handleRefreshBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshBalance = async () => {
   return true
  };

  return (
    <BalanceContext.Provider
      value={{ balance, handleRefreshBalance, isLoading }}
    >
      {children}
    </BalanceContext.Provider>
  );
};
