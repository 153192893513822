import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';

const Web3 = ({ children }) => {
    function getLibrary(provider: any): Web3Provider {
        const library = new Web3Provider(provider);
        library.pollingInterval = 12000;

        return library;
    }

    return <Web3ReactProvider getLibrary={getLibrary}>{children}</Web3ReactProvider>;
};

export default Web3;
