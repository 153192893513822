// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type ConfirmDialogProps = {
    title: React.ReactNode;
    content?: React.ReactNode;
    action: React.ReactNode;
    open: boolean;
    onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ConfirmDialog({ title, content, action, open, onClose, ...other }: ConfirmDialogProps) {
    return (
        <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
            <DialogTitle sx={{ pb: 2, fontFamily: 'var(--primary-font-family)' }}>{title}</DialogTitle>

            {content && <DialogContent sx={{ typography: 'body2', fontFamily: 'var(--primary-font-family)' }}> {content} </DialogContent>}

            <DialogActions>
                {action}

                <Button sx={{ fontFamily: 'var(--primary-font-family)' }} variant="outlined" color="inherit" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog >
    );
}
