import { ListSubheader } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styles from './header-sub-main.module.scss';
import HeaderSearchBar from 'components/header/header-search-bar';

const HeaderSubMain = ({ isSearch, trigger, isMobile }) => {
    const theme = useTheme();

    return (
        <>
        {
            isSearch && (
                <ListSubheader
                    sx={{
                        bgcolor: '#131313',
                        position: 'fixed',
                        top: trigger ? -56 : 0,
                        width: '100%',
                        height: 104,
                        zIndex: theme.zIndex.appBar + 2,
                        transition: '0.22s',
                        alignItems: 'end',
                        display: 'flex',
                        px: isMobile ? 1.5 : 2
                    }}
                >
                    <HeaderSearchBar
                        className={styles.mobile_search_bar}
                        dropDownClassName={styles.mobile_search_bar_drop_down}
                    />
                </ListSubheader>
            )
        }
        </>
    );
}

export default HeaderSubMain;