import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/snackbar';
import GalleraiIcon from 'components/shared/gallerai-icon';
import styles from './image-container.module.scss';
import { Button, Skeleton, Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setMetadata, setSelectedModel } from 'store/generate';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#131313',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: '300px',
        fontSize: theme.typography.pxToRem(12),
    },
}));

const ImageContainer = ({ imageLoaded, user, image, hideSeed, isNSFW, handleNSFW, onLoad, onError }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();
    const { setOpenBirthdayModal, setOpenNsfwModal } = useContext(AuthContext);

    // Store
    // const selectedModel = useSelector((state: RootState) => state.generate.selectedModel)
    const textToImageModels = useSelector((state: RootState) => state.generate.textToImageModels)

    const handleEyeBtn = () => {
        if(!user) {
            setOpenNsfwModal(true);

            return;
        };

        if(user.adult == true) {
            handleNSFW(false);
        } else {
            setOpenBirthdayModal(true);
        }
    }

    const handleRemix = () => {
        if (!image || user?.plan == 'free') return;

        if (image?.modelId !== null && textToImageModels.find((item) => item?._id === image?.modelId) != null) {
            dispatch(setSelectedModel(textToImageModels.find((item) => item?._id === image?.modelId)))
            const initMetadata = textToImageModels.find((item) => item?._id === image?.modelId)?.metadata;
            const remixMetadata = image?.metadata;

            const metadata = {
                override_settings_restore_afterwards: remixMetadata.override_settings_restore_afterwards ?? initMetadata.override_settings_restore_afterwards,
                denoising_strength: remixMetadata.denoising_strength ?? initMetadata.denoising_strength,
                refiner_checkpoint: remixMetadata.refiner_checkpoint ?? initMetadata.refiner_checkpoint,
                refiner_switch_at: remixMetadata.refiner_switch_at ?? initMetadata.refiner_switch_at,
                prompt: remixMetadata.prompt ?? initMetadata.prompt,
                negative_prompt: remixMetadata.negative_prompt ?? initMetadata.negative_prompt,
                seed: !hideSeed ? (remixMetadata.seed ?? initMetadata.seed) : null,
                batch_size: remixMetadata.batch_size ?? initMetadata.batch_size,
                steps: remixMetadata.steps ?? initMetadata.steps,
                cfg_scale: remixMetadata.cfg_scale ?? initMetadata.cfg_scale,
                width: remixMetadata.width ?? initMetadata.width,
                height: remixMetadata.height ?? initMetadata.height,
                sampler_name: remixMetadata.sampler_name ?? initMetadata.sampler_name,
                sampler_index: remixMetadata.sample_index ?? initMetadata.sampler_index,
                restore_faces: remixMetadata.restore_faces ?? initMetadata.restore_faces
            }

            dispatch(setMetadata(metadata))
            navigate('/generate/text-to-image');
        } else {
            enqueueSnackbar('Can not find model for the image.', { variant: 'error' });

            return;
        }
    };
    
    return (
        <div className={imageLoaded ? styles.image_loaded_container : styles.image_container}>
            {!imageLoaded && (
                <Skeleton variant="rounded" style={{ borderRadius: 'unset', width: '100%', minWidth: '460px', height: '100%' }} />
            )}
            { imageLoaded && (
                <div
                    className={`${styles.image_background}`}
                    style={{ backgroundImage: `url(${image?.original})` }}
                />
            )}
            {
                !isNSFW && (
                    <Stack className={styles.btn_group}>
                        <HtmlTooltip
                            title={
                                user?.plan == 'free' && (
                                    <Stack style={{ color: '#FFFFFF' }}>
                                        <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontFamily: 'SF Pro Text', fontWeight: '400' }}>
                                            Remix is available for all monthly subscribers, <a style={{ cursor: 'pointer' }} onClick={() => { navigate('/pricing') }}>sign up</a> now to enjoy this featrure.
                                        </Typography>
                                    </Stack>
                                )
                            }
                        >
                            <Button
                                color="inherit"
                                onClick={handleRemix}
                                disableRipple
                                startIcon={<GalleraiIcon name='arrowCircle' width={14} height={14} />}
                                className={user?.plan == 'free' ? styles.btn_remix_for_free : styles.btn_remix}
                            >
                                Remix
                            </Button>
                        </HtmlTooltip>
                        {/* <Button
                            color="inherit"
                            disableRipple
                            endIcon={<FavoriteBorderIcon style={{ width: '14px', height: '14px' }} />}
                            className={styles.btn_favorite}
                        >
                            1.3k Likes
                        </Button> */}
                    </Stack>
                ) 
            }
            <img
                src={image?.original}
                className={isNSFW ? styles.nsfw_image_shower : styles.image_shower}
                onLoad={onLoad}
                onError={onError}
            />
            {(isNSFW && imageLoaded) && (
                <Stack className={styles.nsfw_container}>
                    <Stack height="100%" alignItems="center" justifyContent="center">
                        <Stack alignItems="center" spacing={1.5}>
                            <Typography
                                className={styles.nsfw_description}
                                sx={{ display: { md: 'block', xs: 'none' } }}
                            >
                                This content may not be appropriate 
                                <br/>
                                for certain users
                            </Typography>
                            <Button
                                color="inherit"
                                onClick={handleEyeBtn}
                                className={styles.btn_nsfw}
                                sx={{ background: { md: 'transparent', xs: '#00000099' } }}
                            >
                                View NSFW content
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </div>
    );
}

export default ImageContainer;