import api from '../../utils/api';
import { ForumCategory } from 'types/gallerai/forumTypes';
import { Dispatch, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (id: string, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.delete('/api/admin/forum/category', { params: { id: id } });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getCategory = createAsyncThunk('category/updateCategory', async (id: string, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.get('/api/admin/forum/category', { params: { id: id } });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateCategory = createAsyncThunk('category/updateCategory', async (data: any, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.post('/api/admin/forum/category/update', data);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createCategory = createAsyncThunk('category/createCategory', async (data: any, { rejectWithValue }: Redux,) => {
    try {
        const response = await api.post('/api/admin/forum/category/create', data);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getCategories = createAsyncThunk('category/getCategories', async () => {
    const response = await api.get('/api/admin/forum/categories');

    return response.data;
});

const categoryAdapter = createEntityAdapter<ForumCategory>({});

export const { selectById: selectCaregoryById, selectAll: selectAllCategories } = categoryAdapter.getSelectors(
    (state: RootState) => state.category
);

export const categorySlice = createSlice({
    name: 'category',
    initialState: categoryAdapter.getInitialState({
        isLoading: false,
    }),
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                const { categories } = action.payload;

                categories.map((category: any) => {
                    const temp = {
                        id: category._id,
                        name: category.name,
                        description: category.description,
                        createdAt: category.created_at
                    };

                    categoryAdapter.addOne(state, temp);
                });
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                const { category } = action.payload;
                
                const temp = {
                    id: category._id,
                    name: category.name,
                    description: category.description,
                    createdAt: category.created_at
                };

                categoryAdapter.upsertOne(state, temp);
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                const { status } = action.payload;

                if(status === true) {
                    categoryAdapter.removeOne(state, action.meta.arg);
                }
            })
    }
});

export const { setIsLoading } = categorySlice.actions;

export default categorySlice.reducer;
