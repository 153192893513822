// import { Typography } from '@mui/material';
// import styles from './gallerai-cookie-consent.module.scss';
// import { useNavigate } from 'react-router-dom';
// import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useLayoutEffect } from 'react';
// import { useCookies } from 'react-cookie';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from 'utils/constants/plugin-config';

const GalleraiCookieConsent = () => {
    // const navigate = useNavigate();
    
    // const [show, setShow] = useState(false);
    // const [cookies, setCookie, removeCookie] = useCookies([]);
    // const [cookies, setCookie] = useCookies([]);
    // console.log('**', cookies, show)

    // const goToPrivacy = () => navigate('/privacy');

    // const handleClose = () => {
    //     setCookie('gallerai_cookie', 'true', { maxAge: 2592000 }); // Expires after 30 days
    //     setShow(false);
    // }

    useLayoutEffect(() => {
        document.documentElement.classList.add('cc--elegant-black');
    })

    useEffect(() => {
        // if(!cookies.gallerai_cookie) {
        //     setShow(true);
        // }
        
        // CookieConsent.reset(true)        

        CookieConsent.run(pluginConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <></>
        // <div className={styles.container}>
        //     <div className={styles.text_container}>
        //         <Typography className={styles.text}>Gallerai uses cookies to help us provide you with the best possible experience. By continuing to visit this site, you agree to our use of cookies as described <span className={styles.privacy_link} onClick={goToPrivacy}>here</span>.</Typography>
        //         <button onClick={CookieConsent.showPreferences}>
        //             Show Cookie Preferences
        //         </button>
        //         <button type='button' data-cc='show-preferencesModal'>Manage cookie preferences</button>
        //     </div>
        //     <CloseIcon onClick={handleClose} style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
        // </div>
    );
}

export default GalleraiCookieConsent;