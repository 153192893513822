import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from './header-user-auth.module.scss';

const HeaderUserAuth = () => {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Link to="/" className={styles.link_normal}>
                <Typography className={styles.sign_up}>Sign up</Typography>
            </Link>
            <Typography>or</Typography>
            <Link to="/login" className={styles.link_normal}>
                <Typography className={styles.log_in}>Log in</Typography>
            </Link>
        </Stack>
    );
};

export default HeaderUserAuth;
