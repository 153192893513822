
// import { InputBase, Stack, Typography } from '@mui/material';
import { InputBase, Stack } from '@mui/material';
import styles from './giphy-panel.module.scss';
import GalleraiIcon from 'components/shared/gallerai-icon';
// import { useContext, useState } from 'react';
import { useContext, useState } from 'react';
import GiphyTrendGrid from 'components/giphy/giphy-trend-grid';
import GiphySearchGrid from 'components/giphy/giphy-search-grid';
// import GiphyEmojiGrid from 'components/giphy/giphy-emoji-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { realTimeDB } from 'utils/firebase';
import { push, ref, serverTimestamp, set } from 'firebase/database';
import { AuthContext } from 'contexts/AuthContext';

const GiphyPanel = ({ roomID, setOpenGiphy, setShowComments }) => {
    // Context
    const { user } = useContext(AuthContext);

    // Component State
    // const [giphyType, setGiphyType] = useState('gif');
    const [query, setQuery] = useState('');

    // Redux state
    const reply = useSelector((state: RootState) => state.homeDetail.reply);

    const handleGifSelect = async (gif: any) => {
        setOpenGiphy(false);
        setShowComments(true);

        if(!gif || reply.replayID != null || reply.receiver != null) return;

        try {
            const newMessageKey = push(ref(realTimeDB, `comments/${roomID}`)).key;
            const newMessageRef = ref(realTimeDB, `comments/${roomID}/${newMessageKey}`);

            const messageData = {
                message: 'giphy_' + gif.id,
                sender: {
                    username: user.username,
                    email: user.email,
                    avatar: user.avatar,
                    blocky: user.blocky
                },
                created_at: serverTimestamp()
            };

            await set(newMessageRef, messageData);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    }

    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         const isGIFButtonClicked =
    //                 event.target === document.querySelector('[id="gif_icon_svg"]') ||
    //                 event.target === document.querySelector('[id="gif_icon_svg_path"]');
    //         if(isGIFButtonClicked) return;

    //         if (giphyPanel.current && !giphyPanel.current.contains(event.target)) {
    //             setOpenGiphy(false);
    //         }
    //     };

    //     // Add event listener when the component mounts
    //     document.addEventListener('mousedown', handleClickOutside);

    //     // Clean up event listener when the component unmounts
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    //     // eslint-disable-next-line
    // }, []);

    return (
        <Stack className={styles.giphy_container_new}>
            <Stack direction="row" alignItems="center" spacing={1} className={styles.search_bar_container}>
                <GalleraiIcon name='search' width={17} height={16} />
                <InputBase
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    className={styles.input_base}
                    placeholder="Search GIPHY"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Stack>
            <div className={styles.giphy_container_content}>
                {
                    query == '' ?
                    <GiphyTrendGrid handleSelect={handleGifSelect} key={'trend-grid'} />
                    :
                    <GiphySearchGrid handleSelect={handleGifSelect} query={query} key={query} />
                }
                {/* {
                    giphyType === 'gif' ?
                    <GiphyGifGrid handleSelect={handleGifSelect} />
                    :
                    <GiphyEmojiGrid handleSelect={handleGifSelect} />
                } */}                
            </div>
            {/* <div className={styles.giphy_container_footer}>
                <div className={giphyType == 'gif' ? styles.btn_gif_selected : styles.btn_gif} onClick={() => setGiphyType('gif')}>
                    <Typography className={giphyType === 'gif' ? styles.btn_emoji_text_selected : styles.btn_emoji_text}>
                        GIF
                    </Typography>
                </div>
                <div className={giphyType == 'emoji' ? styles.btn_emoji_selected : styles.btn_emoji} onClick={() => setGiphyType('emoji')}>
                    <Typography className={giphyType === 'emoji' ? styles.btn_emoji_text_selected : styles.btn_emoji_text}>
                        Emoji
                    </Typography>
                </div>
            </div> */}
        </Stack>
    )
}

export default GiphyPanel;