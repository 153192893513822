import { AppDispatch } from 'store';
import Masonry from 'react-masonry-css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { HomeBreakPointCols, InitScrollTopMax } from 'utils/constants';
import HomeImageCard from 'components/home/home-image-card/home-image-card';
import { getRelatedImages, selectAllRelatedImages, setFilter } from 'store/home/detail';
import HomeAdCard from 'components/home/home-ad-card';
import useResponsive from 'hooks/useResponsive';
import styles from './related-images.module.scss';
import useRole from 'hooks/useRole';

let scrollTopMax = InitScrollTopMax;

const RelatedImages = ({ id, user, nsfwList }) => {
    const { isAdmin } = useRole();
    const isMobile = useResponsive('down', 481);
    const dispatch = useDispatch<AppDispatch>();
    const relatedImages = useSelector(selectAllRelatedImages);

    const renderImages = () => {
        return relatedImages.map((image, index) => (
            <React.Fragment key={index}>
                {image.isAd ? (
                    <HomeAdCard
                        user={user}
                        isMobile={isMobile}
                        isAdmin={isAdmin}
                        nsfwList={nsfwList}
                        item={image}
                        key={`list-${image.id}`}
                    />
                ) : (
                    <HomeImageCard
                        user={user}
                        isAdmin={isAdmin}
                        isMobile={isMobile}
                        nsfwList={nsfwList}
                        item={image}
                        key={`list-${image.id}`}
                    />
                )}
            </React.Fragment>
        ));
    };

    const handleScroll = () => {
        // const homeDetailContainer = document.getElementById('home-detail-container');
        // const { scrollHeight, clientHeight } = homeDetailContainer ?? document.documentElement;
        const { scrollHeight, clientHeight } = document.documentElement;
        const scrollTop = window.scrollY;    
        const loadMoreThreshold = scrollHeight * 2 / 3;
    
        if (clientHeight + scrollTop >= loadMoreThreshold) {
            if (scrollTopMax > scrollTop) {
                scrollTopMax = scrollTop;
                dispatch(getRelatedImages())
                    .then(({ payload }) => {
                        scrollTopMax = payload.length === 0 ? 0 : InitScrollTopMax + scrollTop;
                    })
                    .catch(() => {
                        scrollTopMax = InitScrollTopMax + scrollTop;
                    });
            }
        }
    };

    const handleCustomEvent = (event: any) => {
        console.log('Custom event received:', event.detail); // Access event details
        scrollTopMax = InitScrollTopMax;
        // Handle the custom event
    };

    useEffect(() => {
        scrollTopMax = InitScrollTopMax;

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('clearScrollPositoin', handleCustomEvent);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('clearScrollPositoin', handleCustomEvent);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!id) return;

        dispatch(setFilter({
            imageId: id,
            pageNumber: 1,
            pageSize: 30
        }));

        dispatch(getRelatedImages());
        // eslint-disable-next-line
    }, [id]);

    return (
        <>
            {
                relatedImages.length > 0 && (
                    <Typography className={styles.more_like_this_image}>
                        More generations like this
                    </Typography>
                )
            }
            <Stack position="relative">
                <Box
                    sx={{
                        // marginTop: (theme) => theme.spacing(7),
                        // paddingTop: (theme) => theme.spacing(6.5),
                        justifyContent: 'center',
                        display: 'flex',
                        zIndex: 10
                    }}
                >
                    <Stack sx={{ width: '100%', height: 'fit-content' }}>
                        <Stack>
                            <Box>
                                <Masonry
                                    breakpointCols={HomeBreakPointCols}
                                    className={isMobile ? styles.masonry_grid_mobile : styles.masonry_grid}
                                    columnClassName={styles.masonry_grid_column}
                                >
                                    {relatedImages.length > 0 && renderImages()}
                                </Masonry>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </>
    );
}

export default RelatedImages;