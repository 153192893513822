import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const downloadInvoice = createAsyncThunk('payment/downloadInvoice', async (invoiceId: string, { rejectWithValue }: Redux) => {
    try {
        const response = await api.get('/api/download/invoice', {
            params: {
                invoice_id: invoiceId
            }
        });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const orderSuccess = createAsyncThunk('payment/orderSuccess', async (sessionId: string, { rejectWithValue }: Redux) => {
    try {
        const response = await api.get('/api/order/success', {
            params: {
                session_id: sessionId
            }
        });
    
        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: {
        isLoading: false,
        successResult: {
            priceId: '',
            price: 0,
            currency: 'usd',
            invoiceNumber: '',
            cardBrand: '',
            cardNumber: '',
            paymentDate: 0
        },
        updatedUser: null
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(orderSuccess.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(orderSuccess.fulfilled, (state, action) => {
                const { updatedUser, price, currency, invoiceNumber, cardBrand, cardNumber, paymentDate, priceId } = action.payload;
                state.updatedUser = updatedUser;
                state.successResult = {
                    priceId: priceId,
                    price: price,
                    currency: currency,
                    invoiceNumber: invoiceNumber,
                    cardBrand: cardBrand,
                    cardNumber: cardNumber,
                    paymentDate: paymentDate
                };

                state.isLoading = false;
            })
            .addCase(orderSuccess.rejected, (state) => {
                state.updatedUser = null;
                state.successResult = {
                    priceId: '',
                    price: 0,
                    currency: '',
                    invoiceNumber: '',
                    cardBrand: '',
                    cardNumber: '',
                    paymentDate: 0
                };

                state.isLoading = false;
            });
    }
});

export const { setIsLoading } = paymentSlice.actions;

export default paymentSlice.reducer;
