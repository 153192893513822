import Iconify from 'components/iconify';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'store';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import styles from './header-sub-generations.module.scss';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { deleteGenerations, setFilterForGenerations, setGridCols, setIsMultiDelete, setViewMethod } from 'store/generations';
import GalleraiSlider from 'components/shared/gallerai-slider';
import { Button, InputBase, ListSubheader, Stack, Typography } from '@mui/material';
import HeaderSearchBar from 'components/header/header-search-bar';
import { GenerationGridDefault, GenerationGridMax, GenerationGridMin, GenerationGridMobile, GenerationViewMethod } from 'utils/constants';
import { LoadingButton } from '@mui/lab';
import GalleraiConfirmDialog from 'components/shared/gallerai-confirm-dialog';

const HeaderSubGenerations = ({ trigger, isMobile, isSearch }) => {
    // Hooks
    const theme = useTheme();
    const dispatch = useDispatch<AppDispatch>();

    // There are tow tabs: history, projects
    const [tab, setTab] = useState('history');
    const [isDeleting, setIsDeleting] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isGenerationSearch, setIsGenerationSearch] = useState(false);
    console.log(tab);

    const gridCols = useSelector((state: RootState) => state.generations.gridCols);
    const viewMethod = useSelector((state: RootState) => state.generations.viewMethod);
    const isMultiDelete = useSelector((state: RootState) => state.generations.isMultiDelete);
    const filter = useSelector((state: RootState) => state.generations.filter);

    const handleViewMethod = () => {
        if(viewMethod == GenerationViewMethod[0]) {
            dispatch(setViewMethod(GenerationViewMethod[1]))
        } else if(viewMethod == GenerationViewMethod[1]) {
            dispatch(setViewMethod(GenerationViewMethod[0]))
        }
    }

    const handleSliderChange = (event: any) => {
        dispatch(setGridCols({
            value: parseInt(event.target.value),
            isMobile: false
        }));
    }

    const handleMinusClick = () => {
        if(gridCols - 1 >= GenerationGridMin) {
            const temp = gridCols - 1;
            dispatch(setGridCols({
                value: temp,
                isMobile: false
            }));
        }
    }

    const handlePlusClick = () => {
        if(gridCols + 1 <= GenerationGridMax) {
            const temp = gridCols + 1;
            dispatch(setGridCols({
                value: temp,
                isMobile: false
            }));
        }
    }

    const handleMultiDelete = () => {
        dispatch(setIsMultiDelete(!isMultiDelete));
    }

    const handleDeleteClick = () => {
        setIsDeleting(true);
        dispatch(deleteGenerations())
            .finally(() => {
                setIsDeleting(false);
                setOpenConfirm(false);
            });
    }

    const openSearchBar = () => {
        setIsGenerationSearch(true);
    }

    const closeSearchBar = () => {
        dispatch(setFilterForGenerations({ ...filter, pageNumber: 1, pageSize: 25, searchQuery: '' }));
        setIsGenerationSearch(false);
    }

    const handleSearchQuery = (query: string) => {
        dispatch(setFilterForGenerations({ ...filter, pageNumber: 1, pageSize: 25, searchQuery: query }));
    }

    // Handle grid colnums based on screen resolution
    useEffect(() => {
        const storedValue = sessionStorage.getItem('gridCols');
        const parsedValue = parseInt(storedValue);
        const value = isMobile ? GenerationGridMobile : (isNaN(parsedValue) ? GenerationGridDefault : parsedValue);
        dispatch(setGridCols({ value, isMobile }));
    }, [dispatch, isMobile]);

    return (
        <ListSubheader
            sx={{
                bgcolor: '#131313',
                position: 'fixed',
                top: trigger ? -56 : 0,
                width: '100%',
                height: 104,
                zIndex: theme.zIndex.appBar + 2,
                transition: '0.22s',
                alignItems: 'end',
                display: 'flex',
                px: isMobile ? 1.5 : 2
            }}
        >
            {isSearch ? (
                <HeaderSearchBar
                    className={styles.mobile_search_bar}
                    dropDownClassName={styles.mobile_search_bar_drop_down}
                />
            ) : (
                <Stack
                    sx={{
                        height: 48,
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'relative'
                    }}
                >
                    <Stack direction='row' spacing={2}>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing='6px'
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setTab('history')}
                        >
                            {!isMobile && (
                                <GalleraiIcon name='historyClock' width={16} height={16} />
                            )}
                            <Typography className={styles.history}>
                                History
                            </Typography>
                        </Stack>

                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing='6px'
                            sx={{ cursor: 'pointer' }}
                        >
                            {!isMobile && (
                                <GalleraiIcon name='folderDouble' width={20} height={20} />
                            )}
                            <Typography className={styles.projects}>
                                Projects
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack 
                        className={
                            !isMobile ? 
                            styles.generations_middle_controllers_container :
                            styles.generations_middle_controllers_container_mobile
                        }
                    >
                        {
                            !isGenerationSearch ? 
                            <>
                                <div
                                    className={styles.btn_generations_middle_controller}
                                    onClick={() => handleViewMethod()}
                                >
                                    {
                                        viewMethod == GenerationViewMethod[0] ?
                                        <GalleraiIcon name='fullScreen' width={14} height={14} /> :
                                        <GalleraiIcon name='squareGrid' width={16} height={16} />
                                    }
                                </div>
                                <div className={styles.btn_generations_middle_controller}>
                                    <GalleraiIcon name='folderPlus' width={20} height={20} />
                                </div>
                                <div
                                    onClick={() => handleMultiDelete()}
                                    className={styles.btn_generations_middle_controller}
                                >
                                    <GalleraiIcon
                                        name='delete'
                                        width={16}
                                        height={16}
                                        className={styles.icon_delete}
                                    />
                                </div>
                                <div
                                    onClick={() => openSearchBar()}
                                    className={styles.btn_generations_middle_controller}
                                >
                                    <GalleraiIcon name='search' width={16} height={16} />
                                </div>
                            </>
                            :
                            <Stack direction="row" alignItems="center" spacing={1} className={styles.search_container}>
                                <GalleraiIcon name='search' width={17} height={16} className={styles.search_generations_icon} />
                                <InputBase value={filter.searchQuery} onChange={(event) => handleSearchQuery(event.target.value)} className={styles.input_base} placeholder="Search generations" inputProps={{ 'aria-label': 'search' }} />
                                <div className={styles.search_close_icon_container} onClick={closeSearchBar}>
                                    <GalleraiIcon name='close' width={9} height={9} />
                                </div>                                
                            </Stack>
                        }
                    </Stack>
                    {
                        (!isMobile) && (
                            !isMultiDelete ?
                            <Stack className={styles.page_grid_controller}>
                                <div className={styles.grid_scroller}>
                                    <div style={{ width: '14px', height: '14px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleMinusClick}>
                                        <Iconify icon='jam:minus' sx={{ color: '#CBCBCB' }}/>
                                    </div>
                                    <GalleraiSlider
                                        defaultValue={gridCols}
                                        value={gridCols}
                                        max={GenerationGridMax}
                                        step={1}
                                        min={GenerationGridMin}
                                        aria-label='Disabled slider'
                                        onChange={handleSliderChange}
                                    />
                                    <div style={{ width: '14px', height: '14px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handlePlusClick}>
                                        <Iconify icon='jam:plus' sx={{ color: '#CBCBCB' }}/>
                                    </div>
                                </div>
                            </Stack>
                            :
                            <LoadingButton
                                disableRipple
                                loading={isDeleting}
                                className={styles.btn_multi_delete}
                                onClick={() => setOpenConfirm(true)}
                                sx={{
                                    '& .MuiCircularProgress-root': {
                                        color: '#000000', // Change the color of the circular progress indicator
                                    },
                                }}
                            >
                                Delete
                            </LoadingButton>
                        )
                    }
                </Stack>
            )}

            <GalleraiConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title="Delete item"
                content="This action cannot be undone and will permanently delete this item from your generations history and our servers."
                action={
                    <>
                        <Button className={styles.btn_action_back} disableRipple onClick={() => setOpenConfirm(false)}>
                            <span className={styles.btn_action_back_text}>Back</span>
                        </Button>
                        <LoadingButton
                            className={styles.btn_action_delete}
                            onClick={handleDeleteClick}
                            loading={isDeleting}
                            sx={{
                                '& .MuiCircularProgress-root': {
                                    color: '#000000', // Change the color of the circular progress indicator
                                },
                            }}
                        >
                            {!isDeleting && (<span className={styles.btn_action_delete_text}>Delete</span>)}
                        </LoadingButton>
                    </>                    
                }
            />
        </ListSubheader>
    );
}

export default HeaderSubGenerations;