import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { NewsletterType } from 'types/gallerai/authTypes';
// import { enqueueSnackbar } from 'notistack';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const discordLogin = createAsyncThunk('user/discordLogin', async (code: string) => {
    const response = await api.get('/api/discord/login', { params: { code }});

    return response.data;
})


export const checkTurnstileToken = createAsyncThunk('user/turnstileToken', async (data: NewsletterType) => {
    const response = await api.post('/api/user/check/turnstile', data);

    return response.data;
})

export const getStripePortal = createAsyncThunk('user/getStripePortal', async () => {
    const response = await api.get('/api/user/stripe/portal');

    return response.data;
})

export const getInvoiceIds = createAsyncThunk('user/getInvoiceIds', async (checkedList: any) => {
    const response = await api.post('/api/user/invoices', checkedList);

    return response.data;
})

export const cancelSubscription = createAsyncThunk('user/cancelSubscription', async () => {
    const response = await api.get('/api/user/subscription/cancel');

    return response.data;
})

export const getLoginActivities = createAsyncThunk('user/getLoginActivities', async (id: string) => {
    const response = await api.get(`/api/user/${id}/activities`);

    return response.data;
})

export const getBills = createAsyncThunk('user/getBills', async (data: any) => {
    const response = await api.post('/api/user/bills', data);

    return response.data;
})

export const getUser = createAsyncThunk('user/getUser', async (id: string) => {
    const response = await api.get(`/api/user/${id}`);

    return response.data;
})

export const deleteUser = createAsyncThunk('user/checkUsername', async (id: string) => {
    const response = await api.delete('/api/user', { params: { id } });

    return response.data;
});

export const getTesters = createAsyncThunk('user/checkUsername', async () => {
    const response = await api.get('/api/testers');

    return response.data;
});

export const checkUsername = createAsyncThunk('user/checkUsername', async (username: string) => {
    const response = await api.get('/api/username', { params: { username } });

    return response.data;
});

export const uploadProfilePicture = createAsyncThunk('user/uploadProfilePicture', async (file: any) => {
    const response = await api.post('/api/upload/picture', { image: file });

    return response.data;
});

export const updateBasicInfo = createAsyncThunk('user/updateBasicInfo', async (data: any, { rejectWithValue }: Redux) => {
    try {
        console.log(data);

        const response = await api.put(`/api/user/${data.id}`, data);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const updateUser = createAsyncThunk('user/updateUser', async (data: any, { rejectWithValue }: Redux) => {
    try {
        console.log(data);

        const response = await api.put('/api/user/update', data);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isSearching: false,
        openSearchDropDown: false,
        isSelectedSearchItem: false,
        searchResults: {
            prompts: [],
            hashtags: []
        },
        selectedTab: 'profile'
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setOpenSearchDropDown: (state, action) => {
            state.openSearchDropDown = action.payload;
        },
        setIsSelectedSearchItem: (state, action) => {
            state.isSelectedSearchItem = action.payload;
        },
        setIsSearching: (state, action) => {
            state.isSearching = action.payload;
        },
        initSearchResult: (state) => {
            state.searchResults.hashtags = [];
            state.searchResults.prompts = [];
        }
    },
    extraReducers: (builder) => {
        console.log(builder)
    }
});

export const { setIsSearching, setOpenSearchDropDown, setIsSelectedSearchItem, initSearchResult, setSelectedTab } = userSlice.actions;

export default userSlice.reducer;
