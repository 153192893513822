import { jwtDecode } from 'jwt-decode'
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';

const useRole = () => {
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [isUser, setIsUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const token = window.localStorage.getItem('accessToken')
        if(token) {
            const decoded = jwtDecode(token) as any;
            console.log('Decoded token', decoded)
            const { role } = decoded;

            setIsUser(role === 'user')
            setIsAdmin(role === 'admin' || role ==='superAdmin')
            setIsSuperAdmin(role === 'superAdmin')

            setLoading(false)
        } else {
            setIsUser(false)
            setIsAdmin(false)
            setIsSuperAdmin(false)

            setLoading(false)
        }
    }, [user]);

    return { loading, isUser, isAdmin, isSuperAdmin };
}

export default useRole;