const useSocialShare = () => {
    const fbShare = (data?: any) => {
        const { url = '', image = '', descr = '', title = 'Gallerai share', winWidth = 700, winHeight = 700 } = data;

        const winTop = window.screen.height / 2 - winHeight / 2;
        const winLeft = window.screen.width / 2 - winWidth / 2;
        const shareLink =
            'http://www.facebook.com/sharer.php?s=100&p[title]=' +
            title +
            '&p[summary]=' +
            descr +
            '&p[url]=' +
            url +
            '&p[images][0]=' +
            image;
        window.open(
            shareLink,
            'sharer',
            'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight
        );
    };

    const shareToTikTok = (data?: any) => {
        const shareLink = `https://www.tiktok.com/upload?source=h5_m&imageUrl=${encodeURIComponent(
            data.image
        )}&desc=${data.image(data.descr)}`;

        window.open(shareLink, '_blank');
    };

    const shareToInstagram = (data?: any) => {
        const shareLink = `https://www.instagram.com/create/batch/?media=${encodeURIComponent(
            data.image
        )}&caption=${encodeURIComponent('Gallerai share')}`;
        const { winWidth = 700, winHeight = 700 } = data;

        const winTop = window.screen.height / 2 - winHeight / 2;
        const winLeft = window.screen.width / 2 - winWidth / 2;
        window.open(
            shareLink,
            'sharer',
            'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight
        );
    };

    const shareToMessenger = (data?: any) => {
        const shareLink = `fb-messenger://share?link=${encodeURIComponent(data.image)}&app_id=${encodeURIComponent(
            'Gallerai'
        )}`;

        window.open(shareLink, '_blank');
    };

    const shareToTwitter = (data?: any) => {
        const shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            data.image
        )}&text=${encodeURIComponent('Gallerai')}`;

        window.open(shareLink, '_blank');
    };

    const shareToDiscord = (data?: any) => {
        console.log(data);

        return;
    };

    const shareToReddit = (data?: any) => {
        const shareLink = `https://reddit.com/submit?url=${encodeURIComponent(data.image)}&title=${encodeURIComponent(
            'Gallerai'
        )}`;

        window.open(shareLink, '_blank');
    };

    const shareToTelegram = (data?: any) => {
        const shareLink = `https://t.me/share/url?url=${encodeURIComponent(data.image)}&text=${encodeURIComponent(
            'Gallerai'
        )}`;

        window.open(shareLink, '_blank');
    };

    const shareToWhatsapp = (data?: any) => {
        const shareLink = `whatsapp://send/?text=${encodeURIComponent('Gallerai')}%20${encodeURIComponent(data.image)}`;

        window.open(shareLink, '_blank');
    };

    const shareToMail = (data?: any) => {
        const shareLink = `mailto:${encodeURIComponent(data.email)}?subject=${encodeURIComponent(
            'Gallerai'
        )}&body=${encodeURIComponent(data.image)} `;

        window.open(shareLink, '_blank');
    };

    return {
        fbShare,
        shareToTikTok,
        shareToInstagram,
        shareToMessenger,
        shareToTwitter,
        shareToDiscord,
        shareToReddit,
        shareToTelegram,
        shareToWhatsapp,
        shareToMail
    };
};

export default useSocialShare;
