import { Route, Routes, useLocation } from 'react-router-dom';
import SnackbarProvider from 'components/snackbar/SnackbarProvider';
import { AuthProvider } from 'contexts/AuthContext';
import { AppProvider } from 'contexts/AppContext';
import { SuiBalanceProvider } from 'contexts/SuiBalanceContext';
import { SuiConfig } from 'config';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider, MuiThemeProvider, OnBoardProvider, Web3Provider } from './providers';
import { store } from 'store';
import { Provider } from 'react-redux';
import CustomRoutes from './routes';
import HomeDetailModal from 'views/home/home-detail-modal';
import { createNetworkConfig, SuiClientProvider, WalletProvider } from '@mysten/dapp-kit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
 
// Config options for the networks you want to connect to
// @ts-expect-error
const { networkConfig } = createNetworkConfig(SuiConfig);
const queryClient = new QueryClient();

function App() {
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;

    return (
        <Provider store={store}>
            <HelmetProvider>
                <ConfigProvider>
                    <AuthProvider>
                        <AppProvider>
                            <OnBoardProvider>
                                <QueryClientProvider client={queryClient}>
                                    <SuiClientProvider networks={networkConfig} defaultNetwork='mainnet' onNetworkChange={(network => console.log(`Switched to SUI ${network}`))}>
                                        <WalletProvider>
                                            <Web3Provider>
                                                <MuiThemeProvider>
                                                    <SnackbarProvider>
                                                        <SuiBalanceProvider>
                                                            <CustomRoutes location={previousLocation || location} />
                                                            {previousLocation && (
                                                                <Routes>
                                                                    <Route path="/home/:id" element={<HomeDetailModal />} />
                                                                </Routes>
                                                            )}
                                                            </SuiBalanceProvider>
                                                    </SnackbarProvider>
                                                </MuiThemeProvider>
                                            </Web3Provider>
                                        </WalletProvider>
                                    </SuiClientProvider>
                                </QueryClientProvider>        
                            </OnBoardProvider>
                        </AppProvider>
                    </AuthProvider>
                </ConfigProvider>
            </HelmetProvider>
        </Provider>
    );
}

export default App;
