import { useRoutes } from 'react-router-dom';

// Routes for authentication
import Auth from './auth';

// Routes for service
import Main from './main';

// Routes for admin
import Admin from './admin';

export default function ThemeRoutes({ location = null }) {
    return useRoutes([...Auth, ...Main, ...Admin], location);
}
