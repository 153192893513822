import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { useEffect, useState } from 'react';
import ResizeObserver from 'react-resize-observer';
import GalleraiIcon from 'components/shared/gallerai-icon';
import styles from './giphy.module.scss'

const giphyFetch = new GiphyFetch(process.env.REACT_APP_GIPHY_SDK_API_KEY);

const GiphyTrendGrid = ({ handleSelect }) => {
    const [gifs, setGifs] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const fetchItems = (offset: number) => giphyFetch.trending({ offset, limit: 10 });

    useEffect(() => {
        const fetchGifs = async () => {
            const { data } = await fetchItems(0);
            setGifs(data);
        };

        // Introduce a delay of 1 second before setting isFetching to false
        const delay = setTimeout(() => {
            fetchGifs();
        }, 500);

        // Cleanup function to clear the timeout if component unmounts or if useEffect runs again
        return () => {
            clearTimeout(delay);
            setGifs([]);
        }
    }, []); // Trigger effect when offset changes

    return (
        <>
            {
                gifs.length === 0 ? (
                    <div style={{ width: '100%', height: '99999px', maxHeight: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <GalleraiIcon name='snipper' width={24} height={24} /> 
                    </div>
                ) : (
                    <Grid
                        className={styles.giphy_grid}
                        onGifClick={(gif, e) => {
                            console.log('gif', gif);
                            e.preventDefault();
                            handleSelect(gif);
                        }}
                        fetchGifs={fetchItems}
                        width={width}
                        columns={2}
                        gutter={6}
                        hideAttribution // Hide user attribute
                    />                    
                )
            }
            <ResizeObserver
                onResize={({ width }) => {
                    setWidth(width);
                }}
            />
        </>
    );
}

export default GiphyTrendGrid;
