// ----------------------------------------------------------------------

export default function Badge(theme: any) {
    console.log(theme);

    return {
        MuiBadge: {
            styleOverrides: {
                dot: {
                    width: 10,
                    height: 10,
                    borderRadius: '50%'
                }
            }
        }
    };
}
