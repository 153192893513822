// Bounding Import
// import Layout from 'layouts';
import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AuthLayout = Loadable(lazy(() => import('layouts/auth')));
const LoginPage = Loadable(lazy(() => import('pages/auth/login')));
const DiscordLoginPage = Loadable(lazy(() => import('pages/auth/discord-login')));
const SignUpPage = Loadable(lazy(() => import('pages/auth/signup')));
const AccountSetupPage = Loadable(lazy(() => import('pages/auth/account-setup')));
const Page404 = Loadable(lazy(() => import('pages/page404')));

const Auth = [
    {
        path: '/login',
        element: <AuthLayout />,
        children: [{ element: <LoginPage />, index: true }]
    },
    {
        path: '/discord/login',
        children: [{ element: <DiscordLoginPage />, index: true }]
    },
    {
        path: '/signup',
        element: <AuthLayout />,
        children: [{ element: <SignUpPage />, index: true }]
    },
    {
        path: '/account-setup',
        element: <AuthLayout />,
        children: [{ element: <AccountSetupPage />, index: true }]
    },
    {
        path: '/404',
        element: <Page404 />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
];

export default Auth;
