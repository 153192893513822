import { Stack, Typography } from '@mui/material';
import styles from './emoji-giphy-container.module.scss'
import EmojiPanel from 'components/home/detail/emoji-panel'
import GiphyPanel from 'components/home/detail/giphy-panel'
import { useEffect, useRef } from 'react';
import GalleraiIcon from 'components/shared/gallerai-icon';

const EmojiGiphyContainer = ({ id, openEmoji, openGiphy, message, totalComments, setMessage, setOpenEmoji, setOpenGiphy, setShowComments }) => {
    const emojiGiphyPanel = useRef(null);

    const handleTabClick = (key: string) => {
        if (key === 'emoji') {
            setOpenEmoji(true);
            setOpenGiphy(false);
        } else if (key === 'gif') {
            setOpenEmoji(false);
            setOpenGiphy(true);
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const isEmojiBtnClicked =
                    event.target === document.querySelector('[id="emoji_btn_png"]');

            if(isEmojiBtnClicked) return;

            if (emojiGiphyPanel.current && !emojiGiphyPanel.current.contains(event.target)) {
                setOpenEmoji(false);
                setOpenGiphy(false);

                if(totalComments === 0) {
                    setShowComments(false);
                } else {
                    setShowComments(true);
                }                
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [])
    
    return (
        <Stack ref={emojiGiphyPanel} className={styles.emoji_giphy_container}>
            <Stack className={styles.emoji_giphy_container_header}>
                <Stack className={styles.tab} onClick={() => handleTabClick('emoji')}>
                    <Typography className={openEmoji ? styles.tab_text_active : styles.tab_text}>Emoji</Typography>
                    <Stack className={openEmoji ? styles.divider_active : styles.divider} />
                </Stack>
                <Stack className={styles.tab} onClick={() => handleTabClick('gif')}>
                    {/* <Typography className={openGiphy? styles.tab_text_active : styles.tab_text}>GIF</Typography> */}
                    <Stack className={styles.btn_giphy_container}>
                        <GalleraiIcon
                            name='gif'
                            width={29}
                            height={20}
                            className={openGiphy ? styles.btn_giphy : 'none'}
                            onClick={() => setOpenGiphy((prev: boolean) => !prev)} 
                        />
                    </Stack>                    
                    <Stack className={openGiphy ? styles.divider_active : styles.divider} />
                </Stack>
            </Stack>
            { openEmoji && <EmojiPanel message={message} setMessage={setMessage} setShowComments={setShowComments}/> }
            { openGiphy && <GiphyPanel roomID={id} setOpenGiphy={setOpenGiphy} setShowComments={setShowComments}/> }
        </Stack>
    );
}

export default EmojiGiphyContainer;