// @mui
import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//
import getRatio from 'utils/getRatio';

// ----------------------------------------------------------------------

type ImageProps = {
    sx?: any;
    effect?: any;
    disabledEffect?: boolean;
    visibleByDefault?: boolean;
    alt?: string;
    src?: string;
    placeholderSrc?: string;
    onLoad?: VoidFunction;
    onError?: VoidFunction;
    ratio?: '4/3' | '3/4' | '6/4' | '4/6' | '16/9' | '9/16' | '21/9' | '9/21' | '1/1';
};

const Image = forwardRef(
    (
        { ratio, disabledEffect = false, effect = 'blur', placeholderSrc, onLoad, onError, sx, ...other }: ImageProps,
        ref: any
    ) => {
        console.log(placeholderSrc)
        
        const content = (
            <Box
                component={LazyLoadImage}
                onLoad={onLoad}
                onError={onError}
                wrapperClassName="wrapper"
                effect={disabledEffect ? undefined : effect}
                // placeholderSrc={placeholderSrc || '/images/placeholder.svg'}
                sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}
                {...other}
            />
        );

        if (ratio) {
            return (
                <Box
                    ref={ref}
                    component="span"
                    sx={{
                        width: 1,
                        lineHeight: 1,
                        display: 'block',
                        overflow: 'hidden',
                        position: 'relative',
                        pt: getRatio(ratio),
                        '& .wrapper': {
                            top: 0,
                            left: 0,
                            width: 1,
                            height: 1,
                            position: 'absolute',
                            backgroundSize: 'cover !important'
                        },
                        ...sx
                    }}
                >
                    {content}
                </Box>
            );
        }

        return (
            <Box
                ref={ref}
                component="span"
                sx={{
                    lineHeight: 1,
                    display: 'block',
                    overflow: 'hidden',
                    position: 'relative',
                    '& .wrapper': {
                        width: 1,
                        height: 1,
                        backgroundSize: 'cover !important'
                    },
                    ...sx
                }}
            >
                {content}
            </Box>
        );
    }
);

export default Image;
