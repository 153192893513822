import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as InfoWhite } from '../../../assets/icons/info-white.svg';
import { ReactComponent as InfoExclamation } from '../../../assets/icons/info-exclamation.svg';
import { ReactComponent as InfoExclamationGreen } from '../../../assets/icons/info-exclamation-green.svg';
import { ReactComponent as MobilLogo } from '../../../assets/icons/mobile-logo.svg';
import { ReactComponent as Youtube } from '../../../assets/icons/youtube.svg';
import { ReactComponent as Video } from '../../../assets/icons/video.svg';
import { ReactComponent as Tool } from '../../../assets/icons/tool.svg';
import { ReactComponent as Tiktok } from '../../../assets/icons/tiktok.svg';
import { ReactComponent as Star } from '../../../assets/icons/star.svg';
import { ReactComponent as StarWhite } from '../../../assets/icons/star-white.svg';
import { ReactComponent as Twitter } from '../../../assets/icons/twitter.svg';
import { ReactComponent as MainLogo } from '../../../assets/icons/main-logo.svg';
import { ReactComponent as Instagram } from '../../../assets/icons/instagram.svg';
import { ReactComponent as Lightning } from '../../../assets/icons/lightning.svg';
import { ReactComponent as LightningDisabled } from '../../../assets/icons/lightning-disabled.svg';
import { ReactComponent as Loading } from '../../../assets/icons/loading.svg';
import { ReactComponent as DoubleChecked } from '../../../assets/icons/double-checked.svg';
import { ReactComponent as Checked } from '../../../assets/icons/checked.svg';
import { ReactComponent as CheckedGreen } from '../../../assets/icons/checked-green.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import { ReactComponent as ArrowCircle } from '../../../assets/icons/arrow-circle.svg';
import { ReactComponent as Mint } from '../../../assets/icons/mint.svg';
import { ReactComponent as Share } from '../../../assets/icons/share.svg';
import { ReactComponent as Download } from '../../../assets/icons/download.svg';
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';
import { ReactComponent as HideEye } from '../../../assets/icons/hide-eye.svg';
import { ReactComponent as Favorite } from '../../../assets/icons/favorite.svg';
import { ReactComponent as Success } from '../../../assets/icons/success.svg';
import { ReactComponent as Stripe } from '../../../assets/icons/stripe.svg';
import { ReactComponent as Copy } from '../../../assets/icons/copy.svg';
import { ReactComponent as GIF } from '../../../assets/icons/gif.svg';
import { ReactComponent as MoreForizontal } from '../../../assets/icons/more-horizontal.svg';
import { ReactComponent as Snipper } from '../../../assets/icons/snipper.svg';
import { ReactComponent as Error } from '../../../assets/icons/error.svg';
import { ReactComponent as Camera } from '../../../assets/icons/camera.svg';
import { ReactComponent as CheckedOutline } from '../../../assets/icons/checked-outline.svg';
import { ReactComponent as CheckOnBoard } from '../../../assets/icons/check-on-board.svg';
import { ReactComponent as CheckedOnBoard } from '../../../assets/icons/checked-on-board.svg';
import { ReactComponent as ArrowDownGrey } from '../../../assets/icons/arrow-down-grey.svg';
import { ReactComponent as ArrowDownSelected } from '../../../assets/icons/arrow-down-selected.svg';
import { ReactComponent as Sui } from '../../../assets/icons/sui.svg';
import { ReactComponent as Polygon } from '../../../assets/icons/polygon.svg';
import { ReactComponent as Binance } from '../../../assets/icons/binance.svg';
import { ReactComponent as NFT } from '../../../assets/icons/nft.svg';
import { ReactComponent as DApploy } from '../../../assets/icons/dapploy.svg';
import { ReactComponent as MintClose } from '../../../assets/icons/mint-close.svg';
import { ReactComponent as TooltipArrow } from '../../../assets/icons/tooltip-arrow.svg';
import { ReactComponent as MintHelpCircle } from '../../../assets/icons/mint-help-circle.svg';
import { ReactComponent as HistoryClock } from '../../../assets/icons/history-clock.svg';
import { ReactComponent as Folder } from '../../../assets/icons/folder.svg';
import { ReactComponent as FullScreen } from '../../../assets/icons/full-screen.svg';
import { ReactComponent as FolderPlus } from '../../../assets/icons/folder-plus.svg';
import { ReactComponent as FolderDouble } from '../../../assets/icons/folder-double.svg';
import { ReactComponent as SquareGrid } from '../../../assets/icons/square_grid.svg';
import { ReactComponent as Circle } from '../../../assets/icons/circle.svg';
import { ReactComponent as MagicGenerate } from '../../../assets/icons/magic-generate.svg';
import { ReactComponent as Remix } from '../../../assets/icons/remix.svg';
import { ReactComponent as Google } from '../../../assets/icons/google.svg';
import { ReactComponent as Facebook } from '../../../assets/icons/facebook.svg';
import { ReactComponent as Discord } from '../../../assets/icons/discord.svg';
import { ReactComponent as Apple } from '../../../assets/icons/apple.svg';
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg';
import { ReactComponent as CheckGreen } from '../../../assets/icons/chcek-green.svg';
import { ReactComponent as ArrowDownLong } from '../../../assets/icons/arrow-down-long.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as Computer } from '../../../assets/icons/computer.svg';
import { ReactComponent as ActiveNow } from '../../../assets/icons/active-now.svg';
import { ReactComponent as CloudDownload } from '../../../assets/icons/cloud-downlaod.svg';
import { ReactComponent as UploadImage } from '../../../assets/icons/upload-image.svg';
import { ReactComponent as Alert } from '../../../assets/icons/alert.svg';
import { ReactComponent as Generate } from '../../../assets/icons/generate.svg';
import { ReactComponent as ClockRight } from '../../../assets/icons/clock-right.svg';
import { ReactComponent as NewGoogle } from '../../../assets/icons/new-google.svg';
import { ReactComponent as CharacterT } from '../../../assets/icons/character-t.svg';
import { ReactComponent as Lora } from '../../../assets/icons/lora.svg';
import { ReactComponent as Resolution } from '../../../assets/icons/resolution.svg';
import { ReactComponent as AdvancedSetting } from '../../../assets/icons/advanced-setting.svg';
import { ReactComponent as MagicBrush } from '../../../assets/icons/magic-brush.svg';
import { ReactComponent as PainterPanel } from '../../../assets/icons/painter-panel.svg';
import { ReactComponent as DotMenu } from '../../../assets/icons/dot-menu.svg';
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg';
import { ReactComponent as ImageDraw } from '../../../assets/icons/image-draw.svg';

const DEFAULT_SIZE = 20;

interface IconProps {
    name: string;
    style?: React.CSSProperties;
    width?: number;
    height?: number;
    className?: string;
    onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    onMouseEnter?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    onMouseLeave?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const GalleraiIcon: React.FC<IconProps> = ({ name, style, width = DEFAULT_SIZE, height = DEFAULT_SIZE, className, onClick, onMouseEnter, onMouseLeave }) => {
    const icons: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
        search: SearchIcon,
        menu: MenuIcon,
        close: CloseIcon,
        arrowUp: ArrowUp,
        arrowDown: ArrowDown,
        info: Info,
        infoExclamation: InfoExclamation,
        infoExclamationGreen: InfoExclamationGreen,
        mobileLogo: MobilLogo,
        youtubu: Youtube,
        video: Video,
        tool: Tool,
        tiktok: Tiktok,
        star: Star,
        starWhite: StarWhite,
        twitter: Twitter,
        mainLogo: MainLogo,
        instagram: Instagram,
        lightning: Lightning,
        lightningDisabled: LightningDisabled,
        loading: Loading,
        checked: Checked,
        checkedGreen: CheckedGreen,
        doubleChecked: DoubleChecked,
        delete: Delete,
        arrowCircle: ArrowCircle,
        mint: Mint,
        share: Share,
        download: Download,
        eye: Eye,
        hideEye: HideEye,
        favorite: Favorite,
        success: Success,
        stripe: Stripe,
        copy: Copy,
        gif: GIF,
        moreForizontal: MoreForizontal,
        snipper: Snipper,
        error: Error,
        camera: Camera,
        checkedOutline: CheckedOutline,
        checkOnBoard: CheckOnBoard,
        checkedOnBoard: CheckedOnBoard,
        arrowDownGrey: ArrowDownGrey,
        arrowDownSelected: ArrowDownSelected,
        sui: Sui,
        polygon: Polygon,
        binance: Binance,
        nft: NFT,
        dApploy: DApploy,
        mintClose: MintClose,
        tooltipArrow: TooltipArrow,
        mintHelpCircle: MintHelpCircle,
        historyClock: HistoryClock,
        folder: Folder,
        fullScreen: FullScreen,
        folderPlus: FolderPlus,
        folderDouble: FolderDouble,
        squareGrid: SquareGrid,
        circle: Circle,
        magicGenerate: MagicGenerate,
        remix: Remix,
        google: Google,
        newGoogle: NewGoogle,
        apple: Apple,
        facebook: Facebook,
        discord: Discord,
        upload: Upload,
        clock: Clock,
        checkGreen: CheckGreen,
        arrowDownLong: ArrowDownLong,
        computer: Computer,
        activeNow: ActiveNow,
        cloudDownload: CloudDownload,
        uploadImage: UploadImage,
        alert: Alert,
        generate: Generate,
        infoWhite: InfoWhite,
        clockRight: ClockRight,
        arrowLeft: ArrowLeft,
        characterT: CharacterT,
        lora: Lora,
        resolution: Resolution,
        advancedSetting: AdvancedSetting,
        magicBrush: MagicBrush,
        painterPanel: PainterPanel,
        dotMenu: DotMenu,
        logout: Logout,
        imageDraw: ImageDraw
    };

    const IconComponent = icons[name];

    if (!IconComponent) {
        console.error(`Icon '${name}' not found.`);

        return null;
    }

    return <IconComponent width={width} height={height} style={style} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className} />;
};

export default GalleraiIcon;