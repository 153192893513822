import styles from './gallerai-confirm-dialog.module.scss';
import { Dialog, DialogContent, Stack, Typography } from '@mui/material';

type ConfirmDialogProps = {
    title: React.ReactNode;
    content?: React.ReactNode;
    action: React.ReactNode;
    open: boolean;
    onClose: VoidFunction;
};

export default function GalleraiConfirmDialog({ title, content, action, open, onClose, ...other }: ConfirmDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            {...other}
            sx={{
                '& .MuiModal-backdrop': {
                    background: 'var(--secondary-background-color)'
                }
            }}
        >
            <DialogContent className={styles.container}>
                <Stack className={styles.title}>
                    <Typography className={styles.title_text_style}>{title}</Typography>
                </Stack>
                <Typography className={styles.content}>
                    {content}
                </Typography>
                <Stack className={styles.btn_group}>
                    {action}
                </Stack>
            </DialogContent>
        </Dialog >
    );
}
