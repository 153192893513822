import { calculateCreditsTime, getPlan, numberWithCommas } from 'utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import styles from './header-mobile-menu.module.scss';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { socialsLarge } from 'components/footer/config-footer';
import { AuthContext } from 'contexts/AuthContext';
import { useCurrentAccount, useDisconnectWallet } from '@mysten/dapp-kit';
import HeaderLogo from '../header-logo';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from 'utils/constants/plugin-config';
import useResponsive from 'hooks/useResponsive';

type MenuProps = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const itemsBeforeLogin = [
    {
        title: 'Generate',
        level: 0,
        path: '/tools',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Pricing',
        level: 0,
        path: '/pricing',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Docs',
        level: 0,
        path: '/forum/topic/list',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Our mission',
        level: 0,
        path: null,
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Legal',
        level: 0,
        path: null,
        icon: <GalleraiIcon name='arrowDown' width={12} height={12} />,
        active: true,
        childs: [
            {
                title: 'Terms of Use',
                level: 1,
                path: '/terms-of-service',
                icon: null,
                active: true
            },
            {
                title: 'Privacy Policy',
                level: 1,
                path: '/privacy',
                icon: null,
                active: true
            },
        ]
    }
];

const itemsAfterLogin = [
    {
        title: 'Generate',
        level: 0,
        path: '/tools',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Pricing',
        level: 0,
        path: '/pricing',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Docs',
        level: 0,
        path: '/forum/topic/list',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Legal',
        level: 0,
        path: null,
        icon: <GalleraiIcon name='arrowDown' width={12} height={12} />,
        active: true,
        childs: [
            {
                title: 'Terms of Use',
                level: 1,
                path: '/terms-of-service',
                icon: null,
                active: true
            },
            {
                title: 'Privacy Policy',
                level: 1,
                path: '/privacy',
                icon: null,
                active: true
            },
        ]
    }
]

const itemsForUser = [
    {
        title: 'View profile',
        level: 0,
        path: '/settings',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'Settings',
        level: 0,
        path: '/settings',
        icon: null,
        child: null,
        active: true
    },
    {
        title: 'My generations',
        level: 0,
        path: '/generations',
        icon: null,
        child: null,
        active: true
    },
]

const UserBalance = ({ user, show }) => {
    const maxBalance = user?.plan != 'free' ? getPlan(user?.plan).maxBalance : 3;
    const percent = user?.plan != 'free' ? parseInt(user?.credits) / maxBalance : parseInt(user?.dailyLimits) / 3;
    let color = '#FB2047';
    
    if(user?.plan == 'free') {
        color = percent >= 0.67 ? '#67FC84' : percent >= 0.34 ? '#FFC700' : percent >= 0.01 ? '#FB2047' : '#FB2047';        
    } else {
        color = percent >= 0.5 ? '#67FC84' : percent >= 0.2 ? '#FFC700' : percent >= 0.01 ? '#FB2047' : '#FB2047';
    }

    const sliderValue = {
        width: `${percent * 100}%`,
        maxWidth: '100%',
        background: color
    }

    const [openSlider, setOpenSlider] = useState(false);

    useEffect(() => {
        if(!show) setOpenSlider(false);
    }, [show])

    return (
        <Stack className={styles.user_credit_information}>
            <Stack className={styles.user_balancer} onClick={() => setOpenSlider(!openSlider)}>
                <Stack sx={{ gap: '4px' }}>
                    <Stack className={styles.credit_balance_title}>
                        <Typography className={styles.gpu_balance}>GPU Balance</Typography>
                        <GalleraiIcon name='clock' width={18} height={18} />
                    </Stack>
                    {user.plan == 'free' && (
                        <Typography className={styles.free_limit_description}>You receive {user.dailyLimits} free generations per day on the starter plan.</Typography>
                    )}
                </Stack>
                <Stack className={styles.credit_balance_container}>
                    <Stack className={styles.ms_container}>
                        {
                            user?.plan == 'free' ? (
                                <Typography className={styles.daily_limit_value}>{user?.dailyLimits} generations remaining</Typography>
                            ) : (
                                <Typography className={styles.credits_value}>{numberWithCommas(user?.credits)} <span className={styles.ms}>ms</span></Typography>
                            )
                        }
                        {(user?.plan != 'free' && percent < 0.5) && (
                            <GalleraiIcon name='alert' className={percent >= 0.2 ? styles.alert_yellow : percent >= 0.01 ? styles.alert_red : styles.alert_red}/>
                        )}
                        {(user?.plan == 'free' && percent <= 0.67) && (
                            <GalleraiIcon name='alert' className={percent >= 0.2 ? styles.alert_yellow : percent >= 0.01 ? styles.alert_red : styles.alert_red}/>
                        )}
                    </Stack>
                    <Stack className={openSlider ? styles.menu_item_level0_icon_rotate : styles.menu_item_level0_icon}>
                        <GalleraiIcon name='arrowDown' width={12} height={12} />
                    </Stack>
                </Stack>
            </Stack>
            {openSlider && (
                <Stack className={styles.balance_slider_container}>
                    {
                        user?.plan != 'free' && (
                            <Stack className={styles.balance_timer}>
                                <Typography className={styles.remain_time}>{calculateCreditsTime(user?.credits)}</Typography>
                                <Typography className={styles.remain_time_percentage}>{(percent * 100).toFixed(2)}%</Typography>
                            </Stack>
                        )
                    }
                    <Stack className={styles.slider_container}>
                        <Stack className={styles.background_slider} />
                        <Stack className={styles.value_slider} style={sliderValue} />
                    </Stack>
                    {
                        user?.plan == 'free' && (
                            <Stack className={styles.balance_timer}>
                                <Typography className={styles.upgrade_your_plan}>Upgrade your plan</Typography>
                                <Typography className={styles.remain_time_percentage}>{(percent * 100).toFixed(0)}%</Typography>
                            </Stack>
                        )
                    }
                </Stack>
            )}
        </Stack>
    );
}

const HeaderMobileMenu = ({ show, setShow }: MenuProps) => {
    // Hooks
    const navigate = useNavigate();
    const isMobile = useResponsive('down', 'sm');
    const currentAccount = useCurrentAccount();
    const { mutate: disconnect } = useDisconnectWallet();

    // Context
    const { user, signOut } = useContext(AuthContext);

    // Component state
    const [openMenuItem, setOpenMenuItem] = useState(null);
    const [prevWidth, setPrevWidth] = useState(window.innerWidth);
    const [isExistCookieConsent, setIsExistCookieConsent] = useState(false);
    const items = user == null ? itemsBeforeLogin : itemsAfterLogin;

    const handleLogout = async () => {
        try {
            if (currentAccount) disconnect();
            signOut('/login');
        } catch (error) {
            console.error(error);
        }
    };

    const handleMenuClick = (item: any) => {
        if (item.active == true && item.path) {
            setShow(false);
            navigate(item.path);
            setOpenMenuItem(null);
        } else if(item.active == true) {
            if(openMenuItem == item.title) {
                setOpenMenuItem(null);
            } else {
                setOpenMenuItem(item.title);
            }            
        }
    };

    const onClose = () => {
        setShow(false);
        setOpenMenuItem(null)
    }

    const handleResize = () => {
        const currentWidth = window.innerWidth;
        if (currentWidth !== prevWidth) {            
            setPrevWidth(currentWidth);
            onClose();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';

            const element = document.querySelector('.show--consent #cc-main .cc--anim .cm') as HTMLElement;
            if (element) {
                CookieConsent.hide();
                setIsExistCookieConsent(true);
            }
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            if (isExistCookieConsent) {
                setIsExistCookieConsent(false)
                CookieConsent.reset()
                CookieConsent.run(pluginConfig)
            }
        }
    }, [show, isExistCookieConsent, isMobile]);

    return (
        <>
            <Stack className={show ? styles.menu_overlay : styles.menu_overlay_hide} onClick={onClose} />
            <Stack className={show ? styles.menu_show : styles.menu_hide}>
                <Stack className={styles.menu_header}>
                    {isMobile && (<HeaderLogo />)}
                    <GalleraiIcon name='close' width={20} height={20} style={{ padding: '3px', marginLeft: 'auto' }} onClick={onClose} />
                </Stack>
                <Stack className={styles.menu_content}>
                    <Stack className={styles.main_container}>
                        <Stack className={styles.menu_items_container}>
                            {user != null && (
                                <>
                                    <UserBalance user={user} show={show} />
                                    <Stack className={styles.user_items}>
                                        <Typography className={styles.your_account}>Your account</Typography>
                                        <Stack className={styles.user_items_container}>
                                            {itemsForUser.map((userItem: any, key: number) => (
                                                <Stack
                                                    key={key}
                                                    onClick={() => handleMenuClick(userItem)}
                                                    className={styles.menu_item_level0}
                                                >
                                                    <Typography className={styles.menu_item_level0_text}>
                                                        {userItem.title}
                                                    </Typography>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    </Stack>
                                </>
                            )}
                            <Stack className={styles.general_items}>
                                {user != null && (
                                    <Typography className={styles.more_options}>More options</Typography>
                                )}
                                <Stack sx={{ marginLeft: user != null ? '16px' : 0, gap: '12px' }}>
                                    {items.map((item: any, key: number) => (
                                        <Stack key={key} sx={{ gap: '12px' }}>
                                            <Stack
                                                onClick={() => handleMenuClick(item)}
                                                className={item.active ? styles.menu_item_level0 : styles.menu_item_level0_disabled}
                                            >
                                                <Typography 
                                                    className={
                                                        item.active ?
                                                        styles.menu_item_level0_text : 
                                                        styles.menu_item_level0_text_disabled
                                                    }
                                                    style={{
                                                        color: item.title == 'Generate' ? '#67FC84' : '#FFF'
                                                    }}
                                                >
                                                    {item.title}
                                                </Typography>
                                                {item.icon !== null && (
                                                    <Stack className={(openMenuItem == item.title) ? styles.menu_item_level0_icon_rotate : styles.menu_item_level0_icon}>
                                                        {item.icon}
                                                    </Stack>
                                                )}
                                            </Stack>
                                            {(item?.childs != null && openMenuItem == item.title) && (
                                                <Stack sx={{ marginLeft: '16px', gap: '12px' }}>
                                                    {openMenuItem == item.title && (
                                                        <Fragment>
                                                            {item?.childs?.map((item: any, key: number) => (
                                                                <Stack
                                                                    key={key}
                                                                    onClick={() => handleMenuClick(item)}
                                                                    className={styles.menu_item_level1}
                                                                >
                                                                    <Typography className={styles.menu_item_level1_text}>
                                                                        {item.title}
                                                                    </Typography>
                                                                    {item.icon !== null && (
                                                                        <Stack className={styles.menu_item_level0_icon}>
                                                                            {item.icon}
                                                                        </Stack>
                                                                    )}
                                                                </Stack>
                                                            ))}
                                                        </Fragment>
                                                    )}
                                                </Stack>
                                            )}
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack className={styles.btn_social_group}>
                            {socialsLarge.map((list, index) => (
                                <Link
                                    key={index}
                                    to={list.path}
                                    className={styles.btn_social}
                                >
                                    {list.icon}
                                </Link>
                            ))}
                        </Stack>
                    </Stack>
                    <Stack className={styles.footer_container}>
                        {user == null && (
                            <Stack className={styles.join_the_beta_container}>
                                <Typography className={styles.join_the_beta}>Join the Beta</Typography>
                                <Typography className={styles.join_the_beta_desc}>Sign up to our newsletter to gain access to the Gallerai platform.</Typography>
                            </Stack>
                        )}
                        <Stack className={styles.footer_btn_group}>
                            {user == null && (
                                <Fragment>
                                    <Button disableRipple className={styles.btn_login} onClick={() => navigate('/login')}>
                                        <Typography className={styles.btn_login_text}>Login</Typography>
                                    </Button>
                                    <Button disableRipple className={styles.btn_sign_up} onClick={() => navigate('/signup')}>
                                        <Typography className={styles.btn_sign_up_text}>Sign up</Typography>
                                    </Button>
                                </Fragment>
                            )}
                            {user != null && (
                                <Fragment>
                                    <Button disableRipple className={styles.btn_login} onClick={() => handleLogout()}>
                                        <Typography className={styles.btn_login_text}>Logout</Typography>
                                    </Button>
                                </Fragment>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}

export default HeaderMobileMenu;