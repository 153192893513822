// form
// @mui
import { FormControlLabel, FormControlLabelProps, FormHelperText, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

// ----------------------------------------------------------------------

interface Props extends Omit<FormControlLabelProps, 'control'> {
    name: string;
    helperText?: React.ReactNode;
    switchProps?: any;
}

export default function RHFSwitch({ name, helperText, onChange, switchProps, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <FormControlLabel
                        control={<Switch {...field} checked={field.value} onChange={onChange} {...switchProps} />}
                        {...other}
                    />

                    {(!!error || helperText) && (
                        <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
                    )}
                </div>
            )}
        />
    );
}
