import { Button, Modal, Stack, Typography } from '@mui/material';
import styles from './image-info.module.scss';
import { Fragment, useContext, useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ChattingComponent from 'components/chatting';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { useSnackbar } from 'components/snackbar';
import useHooks from 'hooks/useHooks';
import SocialShareModal from '../social-share-modal';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { deletePost, getPosts, setFilter, updateNsfwList } from 'store/home';
import { useLocation, useNavigate } from 'react-router-dom';
import { initSearchResult, setIsSelectedSearchItem, setOpenSearchDropDown } from 'store/header';
import { fDateAgo } from 'utils/formatTime';
import { realTimeDB } from 'utils/firebase';
import { off, onValue, ref } from 'firebase/database';
import { setComments, updateLikes } from 'store/home/detail';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditDialog from 'components/home/detail/edit-dialog/edit-dialog';
import CommentInput from 'components/home/detail/comment-input/comment-input';
import { AuthContext } from 'contexts/AuthContext';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import GalleraiConfirmDialog from 'components/shared/gallerai-confirm-dialog';
import EmojiGiphyContainer from '../emoji-giphy-container';

const ImageInfo = ({ id, image, imageLoaded, isDownMD, user, isAdmin, isNSFW, nsfwList, handleNSFW, hideSeed, handleHideSeed }) => {
    // Hooks
    const navigate = useNavigate();
    const location = useLocation();
    const { downloadFile } = useHooks();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();
    const { setOpenAuthModal, setOpenNsfwModal } = useContext(AuthContext);

    const isPoster = user?.email === image?.user?.email;
    const [totalComments, setTotalComments] = useState(0);
    const [likes, setLikes] = useState([]);
    const [message, setMessage] = useState('');

    const [openEditModal, setEditModal] = useState(false);
    const [openMoreModal, setOpenMoreModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false); // Confirm delete    
    const [openGiphy, setOpenGiphy] = useState(false);
    const [openEmoji, setOpenEmoji] = useState(false);
    
    const [caption, setCaption] = useState('');
    const [showComments, setShowComments] = useState(false);
    const [expanded, setExpanded] = useState({
        'prompt': false,
        'negative_prompt': false
    });
    const [viewMore, setViewMore] = useState({
        'prompt': false,
        'negative_prompt': false
    });

    const handleShowComments = (state: boolean) => setShowComments(state);
    const handleExpanded = (key: string, state: boolean) => setExpanded({...expanded, [key]: state });
    const handleMoreModal = (state: boolean) => setOpenMoreModal(state);

    const handleDownload = () => {
        image &&
            downloadFile(JSON.parse(image.images)[0], image?.metadata?.seed || new Date().getTime());
        handleMoreModal(false);
    };

    const handleShareModal = (state: boolean) => {
        setOpenShareModal(state);
        setOpenMoreModal(!state);
    };

    const handleClickHashtag = (word: string) => {
        if (!word.startsWith('#')) return;
    
        dispatch(setFilter({ 'pageNumber': 1, 'pageSize': 30, 'searchQuery': word.substring(1), 'searchType': 'hashtag' }));
        dispatch(setIsSelectedSearchItem(true));
        dispatch(setOpenSearchDropDown(false));
        dispatch(initSearchResult());

        dispatch(getPosts());

        const homeContainer = document.getElementById('home-list');
        if (homeContainer) {
            const displayStyle = window.getComputedStyle(homeContainer).getPropertyValue('display');
            if (displayStyle === 'none') {
                homeContainer.style.display = 'block';
            }
        }

        // Clear scroll position if pageNumber == 1
        const customEvent = new CustomEvent('clearScrollPositoin', { detail: { status: true, message: 'clear scroll position' } });
        window.dispatchEvent(customEvent);
        
        navigate('/home');
    };

    const handleDelete = async () => {
        try {
            setIsDeleting(true);
            await dispatch(deletePost(id));

            const homeContainer = document.getElementById('home-list');
            if (homeContainer) {
                const displayStyle = window.getComputedStyle(homeContainer).getPropertyValue('display');
                if (displayStyle === 'none') {
                    homeContainer.style.display = 'block';
                }
            }
            navigate('/home');
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleHide = () => {
        if (!user) {
            handleMoreModal(false);

            if(isNSFW) {
                setOpenNsfwModal(true);
            } else {
                handleNSFW(!isNSFW);
            }                
            
            return;
        };
    
        if (!nsfwList) {
            console.error('NSFW list is not defined');

            return;
        }

        handleNSFW(!isNSFW);
    
        let hide_list = [...nsfwList.hide] || [];
        let unhide_list = [...nsfwList.unhide] || [];
    
        if (isNSFW) {
            unhide_list.push(id);
            hide_list = hide_list.filter((item) => item !== id);
        } else {
            hide_list.push(id);
            unhide_list = unhide_list.filter((item) => item !== id);
        }
    
        dispatch(updateNsfwList({
            email: user.email,
            hide: [...hide_list],
            unhide: [...unhide_list]
        }));
    };
    
    const handleImageLikes = () => {
        if (!user) {
            setOpenAuthModal(true);

            return;
        };
    
        dispatch(updateLikes(id)).then((res) => {
            const { likes } = res.payload;
            setLikes(likes);
        }).catch(error => {
            console.error('Error updating likes:', error.message);
        });
    };

    const createPromptContainer = (text: string, width: number) => {
        const container = document.createElement('p');
        container.style.visibility = 'hidden';
        container.style.position = 'absolute';
        container.style.top = '0';
        container.style.left = '0';
        container.style.color = 'rgba(255, 255, 255, 0.75)';
        container.style.fontFamily = 'SF Pro Text';
        container.style.fontSize = '14px';
        container.style.fontStyle = 'normal';
        container.style.fontWeight = '400';
        container.style.lineHeight = 'normal';
        container.style.whiteSpace = 'pre-wrap';
        container.style.width = `${width}px`; // Adjust as needed
        container.style.maxHeight = `${3 * 18}px`; // Assuming each line height is 1.2em
        container.innerHTML = text;
        
        return container;
    };

    const handleFollow = () => {
        if(!user) setOpenAuthModal(true);
    }

    const handleUpdate = (data: any) => {
        handleNSFW(data?.isNSFW);
        setCaption(data?.caption);
        handleHideSeed(data?.hideSeed);
    }

    const handleEdit = () => {
        setOpenMoreModal(false);
        setOpenShareModal(false);
        setEditModal(true);
    }

    const handleReport = () => {
        if(!user) setOpenAuthModal(true);
    }

    const handleClickSearchItem = (type: string, value: string) => {
        dispatch(setFilter({ 'pageNumber': 1, 'pageSize': 30, 'searchQuery': value, 'searchType': type }));
        dispatch(setIsSelectedSearchItem(true));
        dispatch(setOpenSearchDropDown(false));
        dispatch(initSearchResult());

        if(location.pathname === '/home') {
            dispatch(getPosts());
        } else {
            const homeContainer = document.getElementById('home-list');
            if (homeContainer) {
                const displayStyle = window.getComputedStyle(homeContainer).getPropertyValue('display');
                if (displayStyle === 'none') {
                    homeContainer.style.display = 'block';
                }
            }

            // Clear scroll position if pageNumber == 1
            const customEvent = new CustomEvent('clearScrollPositoin', { detail: { status: true, message: 'clear scroll position' } });
            window.dispatchEvent(customEvent);

            dispatch(getPosts());
            navigate('/home');
        }
    }

    const renderPrompt = (key: string, content: string) => {
        if (!content) return null;

        const promptWords = content.split(',').map((word: string) => word.trim());

        return promptWords.map((word: string, index: number) => (
            <Fragment key={index}>
                {word.trim() != '' && (
                    <span
                        className={styles.prompt_word}
                        onClick={() => handleClickSearchItem(key, word)}
                    >
                        {word + ', '}
                    </span>
                )}
            </Fragment>
        ));
    }

    // Handle view more of prompt when screen is resized
    useEffect(() => {
        const handleViewMore = () => {
            if (image == null || !imageLoaded) return;

            setLikes(image?.favorite);
            setCaption(image?.caption);
    
            const promptContainer = document.getElementById('prompt_container');
            if (!promptContainer) return;
        
            const newPromptContainer = createPromptContainer(image?.metadata?.prompt, promptContainer.clientWidth);
            document.body.appendChild(newPromptContainer);
            const temp = newPromptContainer.scrollHeight > newPromptContainer.clientHeight;
            setViewMore(prevState => ({
                ...prevState,
                prompt: temp
            }));
            document.body.removeChild(newPromptContainer);
        
            const newNegativePromptContainer = createPromptContainer(image?.metadata?.negative_prompt, promptContainer.clientWidth);
            document.body.appendChild(newNegativePromptContainer);
            const temp1 = newNegativePromptContainer.scrollHeight > newNegativePromptContainer.clientHeight;
            setViewMore(prevState => ({
                ...prevState,
                negative_prompt: temp1
            }));
            document.body.removeChild(newNegativePromptContainer);
        };

        handleViewMore();

        window.addEventListener('resize', handleViewMore);

        return () => window.removeEventListener('resize', handleViewMore);
        // eslint-disable-next-line
    }, [image, imageLoaded]);

    // Get all comments at the first
    useEffect(() => {
        if(!id) return;

        setShowComments(false);
        
        const dbRef = ref(realTimeDB, `comments/${id}`);

        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();

            if (data) {
                dispatch(setComments(data));
                setTotalComments(Object.keys(data).length);
            } else {
                dispatch(setComments({}));
                setTotalComments(0);

                setShowComments(false);
            }
        });

        return () => {
            off(dbRef);
        };

        // eslint-disable-next-line
    }, [id]);

    return (
        <>
            <Stack className={!isDownMD ? styles.image_info_container : styles.image_info_container_md}>
                <Stack>
                    <Stack className={styles.image_info_header}>
                        <Stack className={styles.user_info_container}>
                            <GalleraiAvatar user={image?.user} width={32} height={32} size={8} scale={4} />
                            <Stack>
                                <Typography className={styles.user_name}>
                                    {image?.user?.username}
                                </Typography>
                                <Typography className={styles.post_date}>
                                    {fDateAgo(new Date(image?.createdAt ?? 0))}
                                </Typography>
                            </Stack>
                        </Stack>
                        <div className={styles.flex_row_group}>
                            <a className={styles.btn_follow} onClick={handleFollow}>Follow</a>
                            <div className={styles.btn_more} onClick={() => handleMoreModal(true)}>
                                <MoreHorizIcon />
                            </div>
                            <Modal
                                open={openMoreModal}
                                onClose={() => handleMoreModal(false)}
                                aria-labelledby="more-menu-modal-title"
                                aria-describedby="more-menu-modal-description"
                                sx={{
                                    '& .MuiBackdrop-root': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Change background color here
                                    }
                                }}
                            >
                                <Stack className={styles.more_modal}>
                                    {
                                        (!isAdmin && !isPoster) && (
                                            <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={handleReport}>
                                                <Typography className={styles.more_report}>
                                                    Report
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    {
                                        (isAdmin || isPoster) && (
                                            <div
                                                className={`${styles.more_menu_item} ${styles.more_border}`}
                                                onClick={() => { 
                                                    setOpenConfirm(true)
                                                    handleMoreModal(false)
                                                }}>
                                                <Typography className={styles.more_report}>
                                                    Delete
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    {
                                        (isAdmin || isPoster) && (
                                            <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={handleEdit}>
                                                <Typography className={styles.more_item_text}>
                                                    Edit
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={handleDownload}>
                                        <Typography className={styles.more_item_text}>
                                            Download
                                        </Typography>
                                    </div>
                                    <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={() => handleShareModal(true) }>
                                        <Typography className={styles.more_item_text}>
                                            Share
                                        </Typography>
                                    </div>
                                    <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={handleHide}>
                                        <Typography className={styles.more_item_text}>
                                            {isNSFW ? 'UnHide' : 'Hide'}
                                        </Typography>
                                    </div>
                                    <div className={styles.more_menu_item} onClick={() => handleMoreModal(false)}>
                                        <Typography className={styles.more_item_text}>
                                            Cancel
                                        </Typography>
                                    </div>
                                </Stack>
                            </Modal>
                            <SocialShareModal open={openShareModal} onClose={() => handleShareModal(false)} data={image}/>
                            <EditDialog open={openEditModal} data={image} handleUpdate={handleUpdate} user={user} onClose={() => setEditModal(false)} />
                        </div>
                    </Stack>
                    {
                        showComments && (
                            <Stack className={styles.image_comment_content}>
                                <ChattingComponent roomID={id} />
                            </Stack>
                        )
                    }
                    {
                        !showComments && (
                            <Stack className={styles.image_info_content}>
                                <Typography className={styles.caption}>
                                    {caption?.split(' ').map((word: string, index: number) => (
                                        <span
                                            key={index}
                                            className={ word.startsWith('#') ? styles.hashtag : null }
                                            onClick={() => handleClickHashtag(word)}
                                        >
                                            {word + ' '}
                                        </span>
                                    ))}
                                </Typography>
                                {
                                    image?.metadata?.prompt && (
                                        <Stack className={styles.prompt_container}>
                                            <div className={styles.prompt_container_header}>
                                                <Typography className={styles.parameter_name}>
                                                    Prompt
                                                </Typography>
                                                <CopyToClipboard
                                                    text={image?.metadata?.prompt}
                                                    onCopy={() => image && enqueueSnackbar('Prompt copied')}
                                                >
                                                    <GalleraiIcon name='copy' width={14} height={14} style={{ cursor: 'pointer', marginLeft: '4px' }} />
                                                </CopyToClipboard>
                                            </div>
                                            <Typography id="prompt_container" className={expanded.prompt ? styles.long_text : styles.short_text}>
                                                {renderPrompt('prompt', image?.prompt)}
                                            </Typography>
                                            {
                                                viewMore.prompt && (
                                                    <Typography className={styles.view_more} onClick={() => { handleExpanded('prompt', !expanded.prompt) }}>
                                                        {`View ${ expanded.prompt ? 'less' : 'more' }`}
                                                    </Typography>
                                                )
                                            }
                                        </Stack>
                                    )
                                }
                                {
                                    image?.metadata?.negative_prompt && (
                                        <Stack className={styles.prompt_container}>
                                            <div className={styles.prompt_container_header}>
                                                <Typography className={styles.parameter_name}>
                                                    Negative Prompt
                                                </Typography>
                                                <CopyToClipboard
                                                    text={image?.metadata?.negative_prompt}
                                                    onCopy={() => image && enqueueSnackbar('Negative prompt copied')}
                                                >
                                                    <GalleraiIcon name='copy' width={14} height={14} style={{ cursor: 'pointer', marginLeft: '4px' }} />
                                                </CopyToClipboard>
                                            </div>
                                            <Typography className={expanded.negative_prompt ? styles.long_text : styles.short_text}>
                                                {renderPrompt('negative', image?.metadata?.negative_prompt)}
                                            </Typography>
                                            {
                                                viewMore.negative_prompt && (
                                                    <Typography className={styles.view_more} onClick={() => { handleExpanded('negative_prompt', !expanded.negative_prompt) }}>
                                                        {`View ${ expanded.negative_prompt ? 'less' : 'more' }`}
                                                    </Typography>
                                                )
                                            }
                                        </Stack>
                                    )
                                }
                                <Stack className={styles.image_parameter_container}>
                                    <div className={styles.image_paramter_item_container}>
                                        <Stack className={styles.image_paramter_item}>
                                            <Typography className={styles.parameter_name}>
                                                Model
                                            </Typography>
                                            <Typography className={styles.parameter_value}>
                                                { image?.modelName ?? 'Unknown' }
                                            </Typography>
                                        </Stack>
                                        <Stack className={styles.image_paramter_item}>
                                            <Typography className={styles.parameter_name}>
                                                Resolution
                                            </Typography>
                                            <Typography className={styles.parameter_value}>
                                                { image?.metadata?.width }x{ image?.metadata?.height }
                                            </Typography>
                                        </Stack>
                                    </div>
                                    <div className={styles.image_paramter_item_container}>
                                        <Stack className={styles.image_paramter_item}>
                                            <Typography className={styles.parameter_name}>
                                                Steps
                                            </Typography>
                                            <Typography className={styles.parameter_value}>
                                                { image?.metadata?.steps ?? 'Unknown' }
                                            </Typography>
                                        </Stack>
                                        <Stack className={styles.image_paramter_item}>
                                            <Typography className={styles.parameter_name}>
                                                CFG Scale
                                            </Typography>
                                            <Typography className={styles.parameter_value}>
                                                { image?.metadata?.cfg_scale ?? 'Unknown' }
                                            </Typography>
                                        </Stack>
                                    </div>
                                    <div className={styles.image_paramter_item_container}>
                                        <Stack className={styles.image_paramter_item}>
                                            <Typography className={styles.parameter_name}>
                                                Denoising
                                            </Typography>
                                            <Typography className={styles.parameter_value}>
                                                { image?.metadata?.denoising_strength ?? 'Unknown' }
                                            </Typography>
                                        </Stack>
                                        <Stack className={styles.image_paramter_item}>
                                            <Typography className={styles.parameter_name}>
                                                Scheduler
                                            </Typography>
                                            <Typography className={styles.parameter_value}>
                                                { image?.metadata?.sampler_name ?? 'Unknown' }
                                            </Typography>
                                        </Stack>
                                    </div>
                                    {!hideSeed && (
                                        <div className={styles.image_paramter_item_container}>
                                            <Stack className={styles.image_paramter_item} key={'hideSeed'}>
                                                <div className={styles.prompt_container_header}>
                                                    <Typography className={styles.parameter_name}>
                                                        Seed
                                                    </Typography>
                                                    <CopyToClipboard
                                                        text={image?.metadata?.seed}
                                                        onCopy={() => image && enqueueSnackbar('Seed copied')}
                                                    >
                                                        <GalleraiIcon name='copy' width={14} height={14} style={{ cursor: 'pointer', marginLeft: '4px' }} />
                                                    </CopyToClipboard>
                                                </div>
                                                <Typography className={styles.parameter_value}>
                                                    { image?.metadata?.seed ?? 'Unknown' }
                                                </Typography>
                                            </Stack>
                                        </div>
                                    )}
                                </Stack>
                            </Stack>
                        )
                    }
                </Stack>
                { (openEmoji || openGiphy) && (
                    <EmojiGiphyContainer
                        id={id}
                        openEmoji={openEmoji}
                        openGiphy={openGiphy}
                        message={message}
                        totalComments={totalComments}
                        setMessage={setMessage}
                        setOpenEmoji={setOpenEmoji}
                        setOpenGiphy={setOpenGiphy}
                        setShowComments={setShowComments}
                    />
                )}
                <Stack className={styles.image_info_footer}>
                    <div className={styles.comments_counter}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={handleImageLikes}>
                            {
                                likes.includes(user?.email) ?
                                <FavoriteIcon style={{ width: '20px', height: '20px', color: '#FF3040' }} />
                                :
                                <FavoriteBorderIcon style={{ width: '20px', height: '20px', color: 'rgba(255, 255, 255, 0.75)' }} />    
                            }
                            <Typography className={styles.comments_counter_text}>
                                {likes.length > 0 && `${likes.length} ${likes.length === 1 ? 'Like' : 'Likes'}`}
                                {likes.length === 0 && 'Like this first'}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {totalComments > 0 && (
                                <Typography className={styles.comments_counter_btn} onClick={() => handleShowComments(!showComments)}>
                                    {showComments ? 'Close comments' : `View ${totalComments} comment${totalComments !== 1 ? 's' : ''}`}
                                </Typography>
                            )}
                        </div>
                    </div>
                    <CommentInput
                        roomID={id}
                        totalComments={totalComments}
                        message={message}
                        openEmoji={openEmoji}
                        openGiphy={openGiphy}
                        setMessage={setMessage}
                        setShowComments={setShowComments}
                        setOpenGiphy={setOpenGiphy}
                        setOpenEmoji={setOpenEmoji}
                    />
                </Stack>
            </Stack>
            <GalleraiConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title="Delete Post"
                content="This action cannot be undone and will permanently delete this post from your profile."
                action={
                    <>
                        <Button className={styles.btn_action_back} disableRipple onClick={() => setOpenConfirm(false)}>
                            <span className={styles.btn_action_back_text}>Back</span>
                        </Button>
                        <LoadingButton
                            className={styles.btn_action_delete}
                            onClick={handleDelete}
                            loading={isDeleting}
                            sx={{
                                '& .MuiCircularProgress-root': {
                                    color: '#000000', // Change the color of the circular progress indicator
                                },
                            }}
                        >
                            {!isDeleting && (<span className={styles.btn_action_delete_text}>Delete</span>)}
                        </LoadingButton>
                    </>                    
                }
            />
        </>
        
    );
}

export default ImageInfo;