import { useEffect, useRef } from 'react';
import { createIcon } from '@download/blockies';
import styles from './gallerai-avatar.module.scss';

const GalleraiAvatar = ({ user, width, height, size, scale }) => {
    const blocker = useRef(null);
    
    useEffect(() => {
        if (!user) return;

        if (!user?.avatar) {
            const icon = createIcon({
                seed: user.blocky,
                size: size,
                scale: scale,
            });

            if (blocker.current) {
                // Remove existing children from the blocker element
                while (blocker.current.firstChild) {
                    blocker.current.removeChild(blocker.current.firstChild);
                }
                
                blocker.current.appendChild(icon);
            }
        }
    }, [user, size, scale, blocker]);

    return (
        <>
            {user?.avatar ? (
                <img 
                    src={user.avatar} 
                    width={width} 
                    height={height} 
                    style={{ objectFit: 'cover', borderRadius: '50%', minWidth: width, minHeight: height }} 
                    alt='gallerai_user_avatar' 
                />
            ) : (
                <div 
                    ref={blocker} 
                    className={styles.user_blocky} 
                    style={{ minWidth: width, minHeight: height }}
                />
            )}
        </>
    );
}

export default GalleraiAvatar;