import { HOST } from 'config';
import Image from 'components/image/Image';
import { useSnackbar } from 'components/snackbar';
import useSocialShare from 'hooks/useSocialShare';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './social-share-modal.module.scss';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    EmailShareButton,
    FacebookMessengerShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WeiboShareButton,
    WhatsappShareButton
} from 'react-share';

const SocialShareModal = ({ open, onClose, data }) => {
    const shareLink = window.location.href;
    const { enqueueSnackbar } = useSnackbar();

    const { shareToInstagram } = useSocialShare();

    const handleShare = (label: string) => {
        console.log(label);
        shareToInstagram({
            image: shareLink
        });
    };

    const InstargramShareButton = ({ url }) => {
        console.log(url);

        return (
            <Button
                disableRipple
                onClick={() => handleShare('Instagram')}
                className={styles.btn_social}
            >
                <Stack className={styles.social_icon_container}>
                    <Image
                        src={`${HOST}/images/post-social/instagram.png`}
                        alt="Instagram"
                        sx={{ width: 48, height: 48 }}
                    />
                    <Typography className={styles.social_name}>
                        Instagram
                    </Typography>
                </Stack>
            </Button>
        );
    };

    const Socials1 = [
        { link: 'https://facebook.com', label: 'Facebook', image: 'facebook.svg', Provider: FacebookShareButton },
        {
            link: 'https://www.instagram.com',
            label: 'Instagram',
            image: 'instagram.png',
            Provider: InstargramShareButton
        },
        {
            link: 'https://www.messenger.com',
            label: 'Messenger',
            image: 'messenger.svg',
            Provider: FacebookMessengerShareButton
        },
        { link: 'https://twitter.com', label: 'X', image: 'x.svg', Provider: TwitterShareButton },
        { link: 'https://www.reddit.com', label: 'Reddit', image: 'reddit.svg', Provider: RedditShareButton }
    ];
    
    const Socials2 = [
        { label: 'Weibo', image: 'weibo.svg', Provider: WeiboShareButton },
        { label: 'Telegram', image: 'telegram.svg', Provider: TelegramShareButton },
        { label: 'WhatsApp', image: 'whatsapp.svg', Provider: WhatsappShareButton }
    ];
    
    const Socials3 = [
        { link: '#', label: 'Copy Link', image: 'link.svg' },
        { link: '#', label: 'Email', image: 'email.svg', Provider: EmailShareButton }
    ];

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="share-modal-title"
            aria-describedby="share-modal-description"
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)', // Change background color here
                }
            }}
        >
            <Stack className={styles.share_modal}>
                <div className={styles.share_modal_header}>
                    <KeyboardBackspaceIcon className={styles.btn_back} onClick={onClose}/>
                    <Typography className={styles.share_modal_title}>Share</Typography>
                </div>
                <div className={styles.primary_socials}>
                    {Socials1.map((item, index) => (
                        <Box
                            component={item.Provider}
                            url={shareLink}
                            key={index}
                            appId="1108307986794036"
                            className={styles.btn_social}
                        >
                            <Stack className={styles.social_icon_container}>
                                <Image
                                    src={`${HOST}/images/post-social/${item.image}`}
                                    alt={item.label}
                                    sx={{ width: 48, height: 48 }}
                                />
                                <Typography className={styles.social_name}>
                                    {item.label}
                                </Typography>
                            </Stack>
                        </Box>
                    ))}
                </div>
                <div className={styles.secondary_socials}>
                    {Socials2.map((item, index) => (
                        <Box
                            component={item.Provider}
                            url={shareLink}
                            key={index}
                            title={`Galler AI, ${data?.caption}`}
                            className={styles.btn_social}
                        >
                            <Stack className={styles.social_icon_container}>
                                <Image
                                    src={`${HOST}/images/post-social/${item.image}`}
                                    alt={item.label}
                                    sx={{ width: 48, height: 48 }}
                                />
                                <Typography className={styles.social_name}>
                                    {item.label}
                                </Typography>
                            </Stack>
                        </Box>
                    ))}
                </div>

                <Divider sx={{ borderColor: '#FFFFFF20' }} />

                <div className={styles.tertiary_socials}>
                    {Socials3.map((item, index) => {
                        if (item.label === 'Copy Link') {
                            return (
                                <CopyToClipboard
                                    key={index}
                                    text={shareLink}
                                    onCopy={() => enqueueSnackbar('Image link copied')}
                                >
                                    <Button
                                        disableRipple
                                        className={styles.btn_last_social}
                                    >
                                        <Stack className={styles.social_icon_container}>
                                            <Image
                                                src={`${HOST}/images/post-social/${item.image}`}
                                                alt={item.label}
                                                sx={{ width: 48, height: 48 }}
                                            />
                                            <Typography className={styles.social_name}>
                                                {item.label}
                                            </Typography>
                                        </Stack>
                                    </Button>
                                </CopyToClipboard>
                            );
                        } else {
                            return (
                                <Box
                                    component={item.Provider}
                                    url={shareLink}
                                    subject="Galler AI"
                                    body={`Galler AI, ${data?.caption}`}
                                    key={index}
                                    className={styles.btn_last_social}
                                >
                                    <Stack className={styles.social_icon_container}>
                                        <Image
                                            src={`${HOST}/images/post-social/${item.image}`}
                                            alt={item.label}
                                            sx={{ width: 48, height: 48 }}
                                        />
                                        <Typography className={styles.social_name}>
                                            {item.label}
                                        </Typography>
                                    </Stack>
                                </Box>
                            );
                        }
                    })}
                </div>
                
                <div className={styles.share_modal_footer}>
                    <Typography className={styles.share_modal_footer_text} onClick={onClose}>
                        Cancel
                    </Typography>
                </div>
            </Stack>
        </Modal>
    );
}

export default SocialShareModal;