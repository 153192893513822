import { useEffect, useState } from 'react';
import styles from './header-animation.module.scss'

const HeaderAnimation = ({ color = '#67FC84', width = 21, height = 22 }) => {
    const [showTop, setShowTop] = useState(true);
    
    useEffect(() => {
        const interval = setInterval(() => {
            setShowTop((prev) => !prev);
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}s}`} fill="none">
            <path d="M12.4924 15.1421C12.6893 15.2811 12.9239 15.3551 13.164 15.3543V15.3511C13.3908 15.3516 13.6128 15.2854 13.8023 15.1608C13.9918 15.0362 14.1406 14.8586 14.2301 14.6501L14.7226 13.1519C14.8363 12.8124 15.0274 12.5041 15.2807 12.2513C15.5341 11.9985 15.8427 11.8083 16.1823 11.6954L17.6107 11.2308C17.8412 11.1504 18.0404 10.9991 18.1799 10.7986C18.3193 10.5982 18.3919 10.3587 18.3873 10.1145C18.3826 9.87031 18.301 9.6338 18.1541 9.43873C18.0071 9.24371 17.8023 9.10011 17.569 9.02844L16.159 8.57027C15.818 8.45673 15.5081 8.26516 15.2539 8.01079C14.9998 7.75642 14.8086 7.44626 14.6953 7.10496L14.2309 5.67662C14.1499 5.44913 14.0001 5.25252 13.8024 5.11412C13.6046 4.97572 13.3686 4.90239 13.1272 4.90433C12.8859 4.90629 12.6511 4.9834 12.4556 5.12497C12.26 5.26654 12.1135 5.46553 12.0361 5.6943L11.5678 7.1347C11.4548 7.46607 11.2681 7.76747 11.0218 8.01614C10.7755 8.26483 10.4759 8.4543 10.1458 8.57027L8.71819 9.02844C8.48907 9.10776 8.29055 9.25693 8.15051 9.45493C8.01048 9.65295 7.93599 9.88991 7.93749 10.1325C7.939 10.3751 8.01643 10.6111 8.15891 10.8074C8.3014 11.0037 8.50176 11.1504 8.73186 11.2268L10.1409 11.6842C10.482 11.7985 10.7917 11.9906 11.0458 12.2453C11.2998 12.5001 11.4912 12.8104 11.6047 13.1519L12.069 14.5786C12.1486 14.8061 12.2964 15.003 12.4924 15.1421Z" fill={`${color}`} />
            <path id="star_top" className={`${styles.star_animation_top} ${!showTop && styles.hide}`} d="M5.74911 19.5664C5.88033 19.6591 6.03671 19.7084 6.19685 19.7079V19.7057C6.34804 19.7061 6.49599 19.662 6.62234 19.5789C6.74869 19.4959 6.84787 19.3774 6.90757 19.2385L7.23587 18.2397C7.31166 18.0133 7.43905 17.8078 7.60795 17.6392C7.77685 17.4707 7.98264 17.3439 8.20903 17.2686L9.16133 16.9589C9.31495 16.9053 9.44775 16.8045 9.54075 16.6708C9.63367 16.5371 9.68209 16.3775 9.67895 16.2147C9.67591 16.0519 9.62148 15.8943 9.52351 15.7642C9.42554 15.6341 9.28899 15.5384 9.13346 15.4906L8.1935 15.1852C7.96612 15.1096 7.75952 14.9818 7.59013 14.8123C7.42073 14.6426 7.2932 14.4359 7.21767 14.2083L6.9081 13.2561C6.85412 13.1045 6.75425 12.9734 6.62239 12.8811C6.49053 12.7889 6.33321 12.7399 6.17231 12.7412C6.0114 12.7425 5.8549 12.794 5.72453 12.8883C5.59418 12.9827 5.49644 13.1154 5.44489 13.2679L5.13266 14.2282C5.05736 14.4491 4.93291 14.65 4.7687 14.8158C4.60449 14.9816 4.40479 15.1079 4.18467 15.1852L3.23295 15.4906C3.0802 15.5436 2.94785 15.643 2.85449 15.775C2.76113 15.9071 2.71147 16.065 2.71248 16.2267C2.71348 16.3885 2.7651 16.5458 2.86009 16.6766C2.95508 16.8075 3.08865 16.9053 3.24205 16.9562L4.18146 17.2611C4.40881 17.3374 4.61532 17.4655 4.78468 17.6353C4.95403 17.8051 5.0816 18.012 5.15729 18.2397L5.46685 19.1908C5.51988 19.3424 5.61842 19.4737 5.74911 19.5664Z" fill={`${color}`} />
            <path id="star_bottom" className={`${styles.star_animation_bottom} ${showTop && styles.hide}`} d="M7.22289 6.55677C7.3049 6.6147 7.40264 6.64551 7.50273 6.64518V6.64384C7.59722 6.64404 7.68969 6.61647 7.76865 6.56455C7.84762 6.51263 7.90962 6.43865 7.94692 6.3518L8.15212 5.72751C8.19948 5.58607 8.2791 5.45761 8.38466 5.35228C8.49023 5.24695 8.61885 5.16765 8.76033 5.12065L9.35546 4.92707C9.45151 4.89353 9.5345 4.8305 9.59268 4.74697C9.65076 4.66344 9.68098 4.56368 9.67906 4.46193C9.67715 4.36017 9.6431 4.26163 9.58188 4.18037C9.52066 4.0991 9.43532 4.03926 9.33813 4.0094L8.75063 3.81851C8.60852 3.7712 8.4794 3.69137 8.37353 3.58539C8.26765 3.4794 8.18794 3.35016 8.14074 3.20796L7.94726 2.61281C7.91352 2.51802 7.8511 2.4361 7.76869 2.37844C7.68627 2.32077 7.58796 2.29022 7.48738 2.29103C7.38682 2.29184 7.28901 2.32398 7.20753 2.38296C7.12606 2.44195 7.06497 2.52486 7.03276 2.62018L6.8376 3.22035C6.79054 3.35842 6.71277 3.484 6.61013 3.58762C6.5075 3.69124 6.38269 3.77018 6.24511 3.81851L5.65029 4.0094C5.55482 4.04246 5.4721 4.1046 5.41375 4.18712C5.3554 4.26963 5.32436 4.36837 5.32499 4.46944C5.32562 4.57052 5.35788 4.66885 5.41725 4.75064C5.47662 4.83242 5.56011 4.89353 5.65598 4.9254L6.24311 5.11596C6.3852 5.16361 6.51427 5.24366 6.62012 5.3498C6.72596 5.45594 6.8057 5.58525 6.853 5.72751L7.04648 6.32199C7.07962 6.41677 7.14121 6.49882 7.22289 6.55677Z" fill={`${color}`} />
        </svg>
    );
}

export default HeaderAnimation;