import { Dispatch, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'store';
import { GenerationImage, PostImageType } from 'types/gallerai/generateTypes';
import { GenerationGridDefault, GenerationViewMethod } from 'utils/constants';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const getGenerations = createAsyncThunk(
    'generations/getGenerations',
    async (_, { getState, dispatch, rejectWithValue }: Redux) => {
        try {
            // dispatch(initGenerationsAdapter());
            const isLoading = getState().generations.isLoading;
            if (isLoading) {
                return null
            } else {
                dispatch(setIsLoading(true));
            }

            const filter = getState().generations.filter;
            if (filter.pageNumber == 1) dispatch(initGenerationsAdapter());

            const response = await api.get('/api/generations', { params: filter });

            return { data: response.data, params: filter };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteGenerations = createAsyncThunk('generations/deleteGenerations', async (_, { getState, rejectWithValue }: Redux) => {
    try {
        const ids = getState().generations.multiDeleteList;
        const response = await api.delete('/api/generations', { data: ids });

        return response.data;
    } catch(error) {
        return rejectWithValue(error);
    }
});

export const deleteGeneration = createAsyncThunk('generations/deleteGeneration', async (id: string, { rejectWithValue }: Redux) => {
    try {
        const response = await api.delete(`/api/generation/${id}`);

        return response.data;
    } catch(error) {
        return rejectWithValue(error);
    }
});

export const updatePost = createAsyncThunk('generations/updatePost', async (params: PostImageType, { rejectWithValue }: Redux) => {
    try {
        const response = await api.put(`/api/post/${params.id}`, { data: params });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createPost = createAsyncThunk('generations/createPost', async (params: PostImageType, { rejectWithValue }: Redux) => {
    try {
        const response = await api.post('/api/post', { data: params });

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getSuggestedHashtags = createAsyncThunk('generations/getSuggestedHashtags', async (hashtag: string, { rejectWithValue }: Redux) => {
    try {
        const word = hashtag.substring(1);
        const response = await api.get(`/api/hashtags?suggestion=${word}`);

        return response.data;
    } catch (error) {
        return rejectWithValue(error);
    }

});

const generationsAdapter = createEntityAdapter<GenerationImage>({});

export const { selectById: selectImageById, selectAll: selectAllImages } = generationsAdapter.getSelectors(
    (state: RootState) => state.generations
);

export const generationsSlice = createSlice({
    name: 'generations',
    initialState: generationsAdapter.getInitialState({
        isLoading: false,
        isPosting: false,
        isGettingSuggestedHashtags: false,
        suggestedHashtags: [],
        filter: {
            pageNumber: 1,
            pageSize: 25,
            searchQuery: ''
        },
        viewMethod: GenerationViewMethod[0],
        gridCols: parseInt(sessionStorage.getItem('gridCols')) ?? GenerationGridDefault,
        isMultiDelete: false,
        multiDeleteList: []
    }),
    reducers: {
        setMultiDeleteList: (state, action) => {
            const payloadIndex = state.multiDeleteList.indexOf(action.payload);

            if (payloadIndex !== -1) {
                state.multiDeleteList.splice(payloadIndex, 1);
            } else {
                state.multiDeleteList.push(action.payload);
            }
        },
        setIsMultiDelete: (state, action) => {
            state.isMultiDelete = action.payload;
            state.multiDeleteList = [];
        },
        setViewMethod: (state, action) => {
            state.viewMethod = action.payload;
        },
        setGridCols: (state, action) => {
            state.gridCols = action.payload?.value;
            if(action.payload?.isMobile == false) {
                sessionStorage.setItem('gridCols', `${state.gridCols}`);
            }            
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setFilterForGenerations: (state, action) => {
            state.filter = { ...state.filter, ...action.payload };
        },
        setIsPosting: (state, action) => {
            state.isPosting = action.payload;
        },
        initSuggestedHashtags: (state) => {
            state.suggestedHashtags = [];
        },
        initGenerationsAdapter: (state) => {
            generationsAdapter.removeAll(state);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteGeneration.fulfilled, (state, action) => {
                if(action.payload.status != true) return;

                generationsAdapter.removeOne(state, action.meta.arg);
            })
            .addCase(deleteGenerations.fulfilled, (state, action) => {
                const deletedIds = action.payload;

                if(deletedIds?.length == 0) return;

                generationsAdapter.removeMany(state, deletedIds);
                state.isMultiDelete = false;
                state.multiDeleteList = [];
            })
            .addCase(getGenerations.fulfilled, (state, action) => {
                if(action.payload == null) return;

                state.isLoading = false;
                const { data, params } = action.payload;

                // Set total count of generations
                // state.filter.total = data?.totalCount ?? 0;

                // Handle generations
                const generations = data?.generations ?? [];
                generations.map((item: any) => {
                    const newImage = {
                        id: item.id,
                        type: item.type,
                        tool: item.tool,
                        status: item.status,
                        thumbnail: item.thumbnail,
                        original: item.original,
                        width: item.width,
                        height: item.height,
                        metadata: item.metadata,
                        modelName: item.modelName,
                        modelId: item.modelId,
                        createdAt: item.createdAt
                    };

                    generationsAdapter.addOne(state, newImage);
                });

                if (generations.length > 0 && params.pageNumber == state.filter.pageNumber) {
                    state.filter.pageNumber += 1;
                }
            })
            .addCase(getGenerations.rejected, (state, action) => {
                state.isLoading = false;
                console.log(action);
            })
            .addCase(getSuggestedHashtags.pending, (state) => {
                state.isGettingSuggestedHashtags = true;
            })
            .addCase(getSuggestedHashtags.fulfilled, (state, action) => {
                state.isGettingSuggestedHashtags = false;
                state.suggestedHashtags = action.payload.hashtags ?? [];
            })
            .addCase(getSuggestedHashtags.rejected, (state, action) => {
                state.isGettingSuggestedHashtags = false;
                state.suggestedHashtags = [];
                enqueueSnackbar(
                    action.payload['error'] ?? 'Unexpected error occurred.',
                    { variant: 'error' }
                );
            })
            .addCase(updatePost.pending, (state) => {
                state.isPosting = true;
            })
            .addCase(updatePost.fulfilled, (state, action) => {
                state.isPosting = false;
                enqueueSnackbar(action.payload.message ?? 'Image updated successfully.');
            })
            .addCase(updatePost.rejected, (state, action) => {
                state.isPosting = false;
                enqueueSnackbar(
                    action.payload['error'] ?? 'Unexpected error occurred.',
                    { variant: 'error' }
                );
            })
            .addCase(createPost.pending, (state) => {
                state.isPosting = true;
            })
            .addCase(createPost.fulfilled, (state, action) => {
                state.isPosting = false;
                enqueueSnackbar(action.payload.message ?? 'Image posted successfully.');
            })
            .addCase(createPost.rejected, (state, action) => {
                state.isPosting = false;
                enqueueSnackbar(
                    action.payload['error'] ?? 'Unexpected error occurred.',
                    { variant: 'error' }
                );
            });
    }
});

export const {
    setIsLoading,
    setIsPosting,
    initSuggestedHashtags,
    setFilterForGenerations,
    initGenerationsAdapter,
    setGridCols,
    setViewMethod,
    setIsMultiDelete,
    setMultiDeleteList
} = generationsSlice.actions;

export default generationsSlice.reducer;
