const getUTCtime = () => {
    const utcTimeValue = Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
        new Date().getUTCMilliseconds()
    );

    return utcTimeValue;
};

export const getUTCDate = () => {
    const utcDateValue = Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate());

    return utcDateValue;
};

export default getUTCtime;
