import { configureStore } from '@reduxjs/toolkit';

import header from './header';
import home from './home/index';
import generate from './generate';
import generations from './generations';
import payment from './payment';
import homeDetail from './home/detail';
import category from './forum/category';
import topic from './forum/topic';
import userSlice from './user';
import adminSlice from './admin';

export const store = configureStore({
    reducer: {
        header,
        home,
        userSlice,
        adminSlice,
        generate,
        generations,
        payment,
        homeDetail,
        category,
        topic
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
