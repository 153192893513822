import { Stack } from '@mui/material';

// import ChattingInput from './ChattingInput';
import ChattingList from './ChattingList';

type ChattingProps = {
    roomID?: string;
};

const ChattingComponent = ({ roomID }: ChattingProps) => {
    return (
        <Stack style={{ width: '100%', height: '100%', padding: '0' }}>
            <ChattingList roomID={roomID} />
            {/* <ChattingInput roomID={roomID} /> */}
        </Stack>
    );
};

export default ChattingComponent;
