import { init } from 'emoji-mart'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Stack } from '@mui/material';
import { setReply } from 'store/home/detail';
import styles from './emoji-panel.module.scss';
import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';

init({ data })

const EmojiPanel = ({ message, setMessage, setShowComments }) => {
    // Context
    const dispatch = useDispatch<AppDispatch>();

    const reply = useSelector((state: RootState) => state.homeDetail.reply);

    const handleEmojiSelect = async (emoji: any) => {
        // setOpenEmoji(false);
        setShowComments(true);

        // Check if content length exceeds 200 characters
        if (message.length > 200) return;

        // Reset reply if both replyID and receiver are not null and content doesn't start with reply prefix
        if (reply.replayID != null && reply.receiver != null) {
            const prefix = '@' + reply.receiver?.username.replace(/\s/g, '') + ' ';
            if (!message.startsWith(prefix)) {
                dispatch(setReply({ replayID: null, receiver: null }));
            }
        }

        const content = message + emoji.native + ' ';

        // Set message content
        setMessage(content);
    }

    return (
        <Stack className={styles.emoji_container}>
            <Picker
                set='native'
                theme='dark'
                icons='outline'
                emojiVersion='15.0'
                locale='en'
                autoFocus='false'
                navPosition='top'
                previewPosition='none'
                searchPosition='sticky'
                skinTonePosition='none'
                data={data}
                dynamicWidth={true}
                style={{ width: '100%', height: '100%' }}
                onEmojiSelect={(emoji: any) => handleEmojiSelect(emoji)}
            />
        </Stack>        
    );
}

export default EmojiPanel;