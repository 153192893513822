import { ConfigProps, EnvProps } from 'types/config';
import { getFullnodeUrl } from '@mysten/sui.js/client';

export const IPFSURL = 'https://nftstorage.link/ipfs/';

const NODE_ENV = process.env.NODE_ENV;

export const HOST = window.location.origin;

// ** Secret config variables should be located in .env. [Todo]
const ENV: EnvProps = {
    development: {},
    production: {},
    test: {}
};

const Config: ConfigProps = {
    env: ENV[NODE_ENV],
    themeMode: 'dark',
    themeDirection: 'ltr'
};

export const CONTRACTS = {
    pfpnft: {
        56: '0x9836613B97871A5b615FF2E331c9afC8549CBbD6',
        97: '0x7155D24f0f305DB10181d2Df641bcCe59A7Da1aE',
        5: '0x50DcC69067F298336024A55F69D89d5D3406068f',
        80001: '0xf66108a78d205887375cCD74A075a69d4D44a5E4',
        137: '0x64f12377d50938D489F761c95Dce015DC068e3aC',
        1: '0x3e37556F2C16f4A0a2eaD74398A3AbC619969122',
        101: '0xa3e2a5f9e4f5e48976f879fb8496b92e64406a43b3fe285a025708d2d60b27cc'
    }
};

export const CHAINS = {
    bsctest: {
        chainId: '0x61',
        token: 'tBNB',
        label: 'Binance Smart Chain Test Net',
        rpcUrl: 'https://bsc-testnet.publicnode.com'
    },
    binance: {
        chainId: '0x38',
        token: 'BNB',
        label: 'Binance Smart Chain',
        rpcUrl: 'https://koge-rpc-bsc.48.club'
    },
    ethereum: {
        chainId: '0x1',
        token: 'ETH',
        label: 'Ethereum',
        rpcUrl: 'https://ethereum.publicnode.com'
    },
    goerlitest: {
        chainId: '0x61',
        token: 'ETH',
        label: 'Goerli Ethereum Test Net',
        rpcUrl: 'https://ethereum-goerli.publicnode.com'
    },
    mumbai: {
        chainId: '0x13881',
        token: 'MATIC',
        label: 'Mumbai Polygon Test Net',
        rpcUrl: 'https://polygon-mumbai-bor.publicnode.com'
    },
    polygon: {
        chainId: '0x89',
        token: 'MATIC',
        label: 'Polygon',
        rpcUrl: 'https://polygon.llamarpc.com'
    }
};

export const SuiConfig = {
    testnet: {
		url: getFullnodeUrl('testnet'),
		variables: {
			packageId: '0xe9ac1370df3549c14f1db9491e2581b83ab0324fdc05d3e3368089afd625a26e',
            settingId: '0xcff437878f1eb5032ad847fe1927114c02825e7fae1fca5a29472a7ccba1a0f0',
		}
	},
	mainnet: {
		url: getFullnodeUrl('mainnet'),
		variables: {
			packageId: '0x2b81fe886cc3a1bb0de5c25fa18020013159120e43fa9bb45ce4a30f0d196a90',
            settingId: '0x83b32818101ea2d80e171bafa9db7394d05aebda66c3ccf3e6296edce03a51af',
		}
	},
};

export default Config;
