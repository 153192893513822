import { format, formatDistanceToNow, getTime } from 'date-fns';

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fMDate(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
    return date ? getTime(new Date(date)) : '';
}

export function fDateAgo(date: Date | number) {
    try {
        const distance = formatDistanceToNow(date, { addSuffix: true });
    
        return distance.replace('about ', ''); // Remove the "about" prefix
    } catch(error) {
        console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD', date);

        return ''; // Remove the "about" prefix
    }
}

export function millisecondsAgo(date: Date) {
    // Current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate.getTime() - date.getTime();

    // Convert milliseconds to seconds
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    // Define time units in seconds
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30; // Assuming 30 days in a month
    const year = day * 365; // Assuming 365 days in a year

    // Calculate time differences
    const years = Math.floor(differenceInSeconds / year);
    const months = Math.floor(differenceInSeconds / month);
    const weeks = Math.floor(differenceInSeconds / week);
    const days = Math.floor(differenceInSeconds / day);
    const hours = Math.floor(differenceInSeconds / hour) % 24;
    const minutes = Math.floor(differenceInSeconds / minute) % 60;
    const seconds = differenceInSeconds % 60;

    // Determine the last value
    let lastValue = 'now';
    if (years > 0) {
        lastValue = years + 'y';
    } else if (months > 0) {
        lastValue = months + 'mo';
    } else if (weeks > 0) {
        lastValue = weeks + 'w';
    } else if (days > 0) {
        lastValue = days + 'd';
    } else if (hours > 0) {
        lastValue = hours + 'h';
    } else if (minutes > 0) {
        lastValue = minutes + 'm';
    } else if (seconds > 0) {
        lastValue = seconds + 's';
    } else {
        lastValue = 'now';
    }

    return lastValue;
}

export function formatDate(milliseconds: number) {
    // Create a new Date object with the provided milliseconds
    const date = new Date(milliseconds);

    // Array of month names
    const monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
    ];

    // Extract the month, day, and year from the date object
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${month} ${day}, ${year}`;

    return formattedDate;
}

export function formatDDTHMM(milliseconds: number) {
    // Create a new Date object with the provided milliseconds
    const date = new Date(milliseconds * 1000);

    // Array of month names
    const monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June', 'July',
        'August', 'September', 'October',
        'November', 'December'
    ];

    // Extract the month, day, and year from the date object
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    // const year = date.getFullYear();

    // Construct the formatted date string
    const formattedDate = `${day}th ${month}`;

    return formattedDate;
}

export function formatPrice(price: number) {
    // Divide the price by 100 to get the dollar amount
    const dollarAmount = price / 100;

    // Format the dollar amount to 2 decimal places
    const formattedPrice = dollarAmount.toFixed(2);

    // Return the formatted price with a dollar sign
    return `$${formattedPrice}`;
}