import { lazy } from 'react';
import Loadable from 'components/Loadable';

// Layouts
const MainLayout = Loadable(lazy(() => import('layouts/main')));
const ToolsLayout = Loadable(lazy(() => import('layouts/tools')));
// const AuthLayout = Loadable(lazy(() => import('layouts/auth')));
const SettingsLayout = Loadable(lazy(() => import('layouts/settings')));
const GenerationsLayout = Loadable(lazy(() => import('layouts/generations')));

// Pages
const WelcomePage = Loadable(lazy(() => import('pages/welcome')));
const ToolsPage = Loadable(lazy(() => import('pages/tools')));
const TermsOfServicePage = Loadable(lazy(() => import('pages/terms-of-service')));
const PrivacyPage = Loadable(lazy(() => import('pages/privacy')));
const LegalPage = Loadable(lazy(() => import('pages/legal')));
const MissionPage = Loadable(lazy(() => import('pages/mission')));
const GenerateImagePage = Loadable(lazy(() => import('pages/auth/GenerateImagePage')));
const PricingPage = Loadable(lazy(() => import('pages/pricing')));
const PaymentPage = Loadable(lazy(() => import('pages/payment')));
const VisionPage = Loadable(lazy(() => import('pages/vision')));
const HomeMainPage = Loadable(lazy(() => import('pages/home/main')));
const HomeDetailPage = Loadable(lazy(() => import('pages/home/detail')));
const SettingsPage = Loadable(lazy(() => import('pages/settings')));
const GenerationsPage = Loadable(lazy(() => import('pages/generations')));
const TopicListPage = Loadable(lazy(() => import('pages/forum/topic/list')));
const TopicShowPage = Loadable(lazy(() => import('pages/forum/topic/show')));
const SitemapPage = Loadable(lazy(() => import('pages/sitemap')));
const GenerateLayout = Loadable(lazy(() => import('layouts/generate')));
const GeneratePage = Loadable(lazy(() => import('pages/generate')));

const Main = [
    {
        path: '/',
        children: [{ element: <WelcomePage />, index: true }]
    },
    {
        path: '/tools',
        element: <ToolsLayout />,
        children: [{ element: <ToolsPage />, index: true }]
    },
    {
        path: '/home',
        element: <HomeMainPage />
    },
    {
        path: '/generate/:type',
        element: <GenerateLayout />,
        children: [{ element: <GeneratePage />, index: true }]
    },
    // {
    //     path: '/home/:id',
    //     element: <HomeLayout />,
    //     children: [{ element: <HomeDetailPage />, index: true }]
    // },
    {
        path: '/home/:id',
        element: <HomeDetailPage />
        // children: [{ element: <HomeDetailPage />, index: true }]
    },
    {
        path: '/pricing',
        element: <MainLayout />,
        children: [{ element: <PricingPage />, index: true }]
    },
    {
        path: '/blog',
        element: <MainLayout />,
        children: [{ element: <GenerateImagePage />, index: true }]
    },
    {
        path: '/docs',
        element: <MainLayout />,
        children: [{ element: <></>, index: true }]
    },
    {
        path: '/terms-of-service',
        element: <MainLayout />,
        children: [{ element: <TermsOfServicePage />, index: true }]
    },
    {
        path: '/privacy',
        element: <MainLayout />,
        children: [{ element: <PrivacyPage />, index: true }]
    },
    {
        path: '/legal',
        element: <MainLayout />,
        children: [{ element: <LegalPage />, index: true }]
    },
    {
        path: '/mission',
        element: <MainLayout />,
        children: [{ element: <MissionPage />, index: true }]
    },
    {
        path: '/legal',
        element: <MainLayout />,
        children: [{ element: <GenerateImagePage />, index: true }]
    },
    {
        path: '/site_map',
        element: <MainLayout />,
        children: [{ element: <GenerateImagePage />, index: true }]
    },
    {
        path: '/vision',
        element: <MainLayout />,
        children: [{ element: <VisionPage />, index: true }]
    },
    {
        path: '/settings',
        element: <SettingsLayout />,
        children: [{ element: <SettingsPage />, index: true }]
    },
    {
        path: '/generations',
        element: <GenerationsLayout />,
        children: [{ element: <GenerationsPage />, index: true }]
    },
    {
        path: '/payment/:type',
        element: <MainLayout />,
        children: [{ element: <PaymentPage />, index: true }]
    },
    {
        path: '/forum/topic/list',
        element: <MainLayout />,
        children: [{ element: <TopicListPage />, index: true }]
    },
    {
        path: '/forum/topic/:id/show',
        element: <MainLayout />,
        children: [{ element: <TopicShowPage />, index: true }]
    },
    {
        path: '/sitemap',
        element: <MainLayout />,
        children: [{ element: <SitemapPage />, index: true }]
    }
];

export default Main;
