import axios from 'axios';
import { AuthContext } from 'contexts/AuthContext';
import FileSaver from 'file-saver';
import { useCallback } from 'react';
import { useContext, useEffect, useState } from 'react';
import { database } from 'utils/firebase';
import { getUTCDate } from 'utils/getUTCtime';

const useHooks = () => {
    const { user } = useContext(AuthContext);

    const downloadFile = async (link: string, name: string) => {
        axios
            .get(link, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'image/png;charset=utf-8' });
                FileSaver.saveAs(blob, name);
            })
            .catch((err) => console.log(err));
    };

    const [leftNum, setNum] = useState(0);

    const updateLeftNum = useCallback(async () => {
        try {
            const utcDate = getUTCDate();

            const ActsRef = await database
                .collection('activities')
                .where('user_email', '==', user?.email || '')
                .where('created_at', '>=', utcDate)
                .get();

            setNum(5 - (ActsRef.size || ActsRef.size === 0 ? ActsRef.size : 5));
        } catch (error) {
            console.log(error);
            setNum(0);
        }
    }, [user]);

    useEffect(() => {
        updateLeftNum();
    }, [updateLeftNum]);

    return { downloadFile, leftNum, updateLeftNum };
};

export default useHooks;
