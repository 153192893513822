import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../utils/api';
import { GetStatusType, TextToImageMetadataType } from 'types/gallerai/generateTypes';
import { InitResolutionForPaidUser, defaultZoomPercent } from 'utils/constants/generate';
import { enqueueSnackbar } from 'notistack';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

const initGenerateData = {
    id: null,
    modelId: null,
    width: 512,
    height: 512,
    thumbnail: null,
    original: null,
    metadata: null,
};

export const getTextToImageModels = createAsyncThunk('header/getModels', async () => {
    const response = await api.get('/api/text-to-image/models');

    return response.data;
})

export const generateTextToImage = createAsyncThunk(
    'generate/generateTextToImage',
    async (metadata: TextToImageMetadataType, { dispatch, getState, rejectWithValue }: Redux,) => {
        dispatch(setGeneratedData(initGenerateData));

        const selectedModel = getState().generate.selectedModel;
        const overrideSettings = getState().generate.overrideSettings;

        try {
            const response = await api.post('/api/runpod/text-to-image', { modelId: selectedModel?._id, overrideSettings, metadata });
            if (response.status === 200 && response.data.status == 'IN_QUEUE') {
                dispatch(getStatus({ id: response.data.id, passedTime: 0 }));
            } else {
                rejectWithValue({ error: 'Unexpected error occurred.' });
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getStatus = createAsyncThunk('generate/getStatus', async (params: GetStatusType, { dispatch }: Redux) => {
    const response = await api.get(`/api/runpod/status?id=${params.id}`);
    const { generation } = response.data;

    if (response.status === 200 && generation.status == 'IN_QUEUE') {
        const limitation_time = 120;
        if (params.passedTime > limitation_time) {
            // return rejectWithValue({ error: 'Long time error!' });
            return { status: 'pending' };
        }
        const temp = { ...params };
        temp.passedTime += 10;
        setTimeout(() => dispatch(getStatus(temp)), 10000);
    }

    return response.data;
});

export const generateSlice = createSlice({
    name: 'generate',
    initialState: {
        selectedModel: null,
        selectedLoras: [],
        overrideSettings: null,
        textToImageModels: [],
        metadata: {
            override_settings_restore_afterwards: false,
            denoising_strength: 0,
            refiner_checkpoint: '',
            refiner_switch_at: 0,
            prompt: '',
            negative_prompt: '',
            seed: -1,
            batch_size: 1,
            steps: 1,
            cfg_scale: 1,
            width: 512,
            height: 512,
            sampler_name: '',
            sampler_index: '',
            scheduler: '',
            restore_faces: false
        },
        generatedData: initGenerateData,
        updatedUser: null,
        isDone: false,
        isResolutionChangeEvent: false, 
        isGenerating: false,
        currentTab: 'models',
        isDrawer: false,
        zoomPercent: defaultZoomPercent,
        message: ''
    },
    reducers: {
        setZoomPercent: (state, action) => {
            state.zoomPercent = action.payload;
        },
        initResolutionForPaidUserAfterLogin: (state) => {
            state.metadata = {
                ...state.metadata,
                width: InitResolutionForPaidUser.width,
                height: InitResolutionForPaidUser.height
            };
        },
        setSelectedLora: (state, action) => {
            const currentLoras = state.selectedLoras;
            const newLora = action.payload;

            const updatedLoras = [...currentLoras, newLora];
            const updatedPrompt = `${state.metadata.prompt} ${newLora?.value}`.trim();
            enqueueSnackbar('LoRA has been added to your prompt');

            return { ...state, selectedLoras: updatedLoras, metadata: { ...state.metadata, prompt: updatedPrompt } };
        
            // const isLoraSelected = currentLoras.some(lora => lora?.key === newLora?.key);

            // if (isLoraSelected) {
            //     const updatedLoras = currentLoras.filter(lora => lora?.key !== newLora?.key);

            //     enqueueSnackbar(`The ${newLora?.value} Lora has been removed from your prompt.`);

            //     return { ...state, selectedLoras: updatedLoras };
            // } else {
            //     const updatedLoras = [...currentLoras, newLora];

            //     const updatedPrompt = `${state.metadata.prompt} ${newLora?.value}`.trim();
                
            //     enqueueSnackbar(`The ${newLora?.value} Lora has been pasted to your prompt.`);

            //     return { ...state, selectedLoras: updatedLoras, metadata: { ...state.metadata, prompt: updatedPrompt } };
            // }
        },
        setOverrideSettings: (state, action) => {
            state.overrideSettings = action.payload;
        },
        setSelectedModel: (state, action) => {
            state.selectedModel = action.payload;
            state.overrideSettings = action.payload?.overrideSettings;
            
            const currentGenerationStatus = state.isGenerating;

            if (currentGenerationStatus) {
                state.metadata = {
                    ...state.metadata,
                    ...action.payload?.metadata,
                    width: state.metadata.width,
                    height: state.metadata.height,
                    prompt: state.metadata.prompt,
                    negative_prompt: state.metadata.negative_prompt,
                };
            } else {
                state.metadata = {
                    ...state.metadata,
                    ...action.payload?.metadata,
                    prompt: state.metadata.prompt,
                    negative_prompt: state.metadata.negative_prompt,
                };
            }
        },
        setMetadata: (state, action) => {
            state.metadata = { ...state.metadata, ...action.payload };
        },
        setIsResolutionChangeEvent: (state, action) => {
            state.isResolutionChangeEvent = action.payload;
        },
        setIsDrawer: (state, action) => {
            state.isDrawer = action.payload;
        },
        setResolution: (state, action) => {
            state.isResolutionChangeEvent = true;
            state.metadata = { ...state.metadata, ...action.payload };
        },
        setIsGenerating: (state, action) => {
            state.isGenerating = action.payload;
        },
        setGeneratedData: (state, action) => {
            state.generatedData = { ...state.generatedData, ...action.payload };
        },
        setIsDone: (state, action) => {
            state.isDone = action.payload;
        },
        cleanGenerationResult: (state) => {
            state.isGenerating = false;
            state.isDone = false;
            state.generatedData = initGenerateData;

            if(state.currentTab === 'share') {
                state.currentTab = 'prompt'
            }
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTextToImageModels.fulfilled, (state, action) => {
                state.textToImageModels = action.payload;
                // state.selectedModel = action.payload[0];

                // state.metadata = {
                //     ...action.payload[0]?.metadata,
                //     prompt: state.metadata.prompt,
                //     negative_prompt: state.metadata.negative_prompt,
                // };
            })
            .addCase(getStatus.fulfilled, (state, action) => {
                const { generation, updatedUser } = action.payload;
                console.log(action.payload);

                if (generation?.status == 'IN_QUEUE') return;                

                state.isGenerating = false;
                state.isDone = true;

                if (generation?.status === 'COMPLETED') {
                    const generatedData = {
                        id: generation?._id,
                        modelId: generation?.modelId,
                        width: generation?.metadata.width,
                        height: generation?.metadata.height,
                        thumbnail: generation?.thumbnail,
                        original: generation?.original,
                        metadata: generation?.metadata,
                    };
                    state.generatedData = generatedData;
                    state.currentTab = 'share';

                    state.updatedUser = updatedUser;
                } else if (generation?.status === 'FAILED'){
                    state.message = 'Your generation is failed. Please try again';
                } else {
                    state.message = 'This generation may take longer than expected, you can continue to use the app while your request is being processed. To view your images anytime you can visit your history when completed';
                }
            })
            .addCase(getStatus.rejected, (state, action) => {
                state.isGenerating = false;
                state.message = 'Something went wrong. Please check your data again!';
                if (action.payload) {
                    state.message = action.payload['error'] as string;
                }
                console.log(action.error);
            })
            .addCase(generateTextToImage.pending, (state) => {
                if (state.currentTab === 'share')
                    state.currentTab = 'prompt';

                state.isGenerating = true;
                state.isDone = false;
            })
            .addCase(generateTextToImage.fulfilled, (state, action) => {
                if (action.payload.status == 'IN_QUEUE') return;

                state.isGenerating = false;
            })
            .addCase(generateTextToImage.rejected, (state, action) => {
                state.isGenerating = false;
                state.message = 'Something went wrong. Please check your data again!';
                if (action.payload) {
                    state.message = action.payload['error'] as string;
                }
                console.log(action.error);
            });
    }
});

export const {
    setIsDone,
    setZoomPercent,
    cleanGenerationResult,
    setIsGenerating,
    setMetadata,
    setGeneratedData,
    setCurrentTab,
    setMessage,
    setSelectedModel,
    setSelectedLora,
    setResolution,
    setIsDrawer,
    setIsResolutionChangeEvent,
    initResolutionForPaidUserAfterLogin
} = generateSlice.actions;

export default generateSlice.reducer;
