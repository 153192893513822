import { createContext, useEffect, useState } from 'react';
import { AppContextType, SocialProcessType } from 'types/gallerai/appTypes';

const defaultProvider: AppContextType = {
    socialProcess: null,
    setSocialProcess: () => null,
};

const AppContext = createContext(defaultProvider);

const AppProvider = ({ children }) => {
    const [socialProcess, setSocialProcess] = useState<SocialProcessType>(defaultProvider.socialProcess);

    const handleCustomEvent = () => {
        setSocialProcess({
            isProcessing: false,
            socialType: null
        })
    };

    useEffect(() => {
        window.addEventListener('stopSocialProcess', handleCustomEvent);

        return () => {
            window.removeEventListener('stopSocialProcess', handleCustomEvent);
        }
        // eslint-disable-next-line
    }, []);

    const values = {
        socialProcess,
        setSocialProcess
    }

    return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
