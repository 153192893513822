import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { fDateAgo } from 'utils/formatTime';
import { CloseIcon } from 'theme/overrides/CustomIcons';
import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { deletePost, initDeleteResult, setSavedImageId } from 'store/home';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { ImageCardPropType } from 'types/gallerai/homeTypes';
import { HomeBreakPointCols } from 'utils/constants';
import { useSnackbar } from 'components/snackbar';
import styles from './home-image-card.module.scss';
import GalleraiIcon from 'components/shared/gallerai-icon';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { updateLikes } from 'store/home/detail';
import { AuthContext } from 'contexts/AuthContext';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import { setMetadata, setSelectedModel } from 'store/generate';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#131313',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: '300px',
        fontSize: theme.typography.pxToRem(12),
    },
}));

const ImageInfoContainer = ({caption}) => {
    return (
        <Stack className={`post-details ${styles.image_info_container}`}>
            <Typography className={`home-line ${styles.caption}`}>
                {caption.map((word: string, index: number) => (
                    <span key={index} className={word.startsWith('#') ? styles.caption_hash : undefined}>
                        {word + ' '}
                    </span>
                ))}
            </Typography>
        </Stack>
    );
}

const HomeImageCard = ({ user, isAdmin, isMobile, nsfwList, item }: ImageCardPropType) => {
    // Hooks
    const navigate = useNavigate();
    // const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { setOpenAuthModal, setOpenBirthdayModal, setOpenNsfwModal } = useContext(AuthContext);

    // Redux state
    const isDeleting = useSelector((state: RootState) => state.home.isDeleting);
    const deleteResult = useSelector((state: RootState) => state.home.deleteResult);
    const textToImageModels = useSelector((state: RootState) => state.generate.textToImageModels);

    // Component state
    const [imageHeight, setImageHeight] = useState(
        (window.innerWidth / HomeBreakPointCols.default / (item.width || 1)) * (item.height || 1));
    const [fav, setFav] = useState(false);
    const [nsfw, setNSFW] = useState(false);
    const [hide, setHide] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [visibility, setVisibility] = useState<any>('hidden');
    const [caption, setCaption] = useState([]);
    const [favorites, setFavorites] = useState<any>([]);

    const handleRemix = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if (!item || user?.plan == 'free') return;

        if (item?.modelId !== null && textToImageModels.find((modelItem) => modelItem?._id === item?.modelId) != null) {
            dispatch(setSelectedModel(textToImageModels.find((modelItem) => modelItem?._id === item?.modelId)));
            const initMetadata = textToImageModels.find((modelItem) => modelItem?._id === item?.modelId)?.metadata;
            const remixMetadata = item?.metadata;

            const metadata = {
                override_settings_restore_afterwards: remixMetadata.override_settings_restore_afterwards ?? initMetadata.override_settings_restore_afterwards,
                denoising_strength: remixMetadata.denoising_strength ?? initMetadata.denoising_strength,
                refiner_checkpoint: remixMetadata.refiner_checkpoint ?? initMetadata.refiner_checkpoint,
                refiner_switch_at: remixMetadata.refiner_switch_at ?? initMetadata.refiner_switch_at,
                prompt: remixMetadata.prompt ?? initMetadata.prompt,
                negative_prompt: remixMetadata.negative_prompt ?? initMetadata.negative_prompt,
                seed: (!item?.hideSeed) ? (remixMetadata.seed ?? initMetadata.seed) : null,
                batch_size: remixMetadata.batch_size ?? initMetadata.batch_size,
                steps: remixMetadata.steps ?? initMetadata.steps,
                cfg_scale: remixMetadata.cfg_scale ?? initMetadata.cfg_scale,
                width: remixMetadata.width ?? initMetadata.width,
                height: remixMetadata.height ?? initMetadata.height,
                sampler_name: remixMetadata.sampler_name ?? initMetadata.sampler_name,
                sampler_index: remixMetadata.sample_index ?? initMetadata.sampler_index,
                restore_faces: remixMetadata.restore_faces ?? initMetadata.restore_faces
            }

            dispatch(setMetadata(metadata));
            navigate('/generate/text-to-image');
        } else {
            enqueueSnackbar('Can not find model for the image.', { variant: 'error' });

            return;
        }
    };

    const handleEyeIcon = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if (!user || !item) {
            setOpenNsfwModal(true);

            return;
        };

        if(user.adult == true) {
            setHide(false);
        } else {
            setOpenBirthdayModal(true);
        }        
    };

    const handleFavorite = (event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if (!user || !item) {
            setOpenAuthModal(true);

            return;
        };
    
        dispatch(updateLikes(item.id)).then((res) => {
            setFav(!fav);
            setFavorites(res.payload?.likes);
        }).catch(error => {
            console.error('Error updating likes:', error.message);
        });
    };

    const handleImageClick = (id: string) => {
        const homePage = document.getElementById('home-list');
        if(homePage) {
            homePage.style.display = 'none';
        }

        // Init scroll to 0 when go to image detail modal
        window.scrollTo(0, 0);

        // const state = { previousLocation: { pathname: location.pathname } };
        dispatch(setSavedImageId(id));
        const state = { previousLocation: { pathname: '/home' } };
        navigate(`/home/${id}`, { state });
    }

    const handleDelete = () => dispatch(deletePost(item.id));

    useEffect(() => {
        setFavorites(item?.favorite);

        if (!user) return setFav(false);
        if (!item?.favorite) return setFav(false);

        const _is = item?.favorite?.indexOf(user?.email);

        return setFav(_is !== -1);
    }, [item.favorite, user]);

    useEffect(() => {
        if (item?.isNSFW) {
            setNSFW(true);
            setHide(true);
            if (nsfwList.unhide.indexOf(item.id) !== -1) {
                setNSFW(false);
                setHide(false);
            }
        }

        if (nsfwList.hide.indexOf(item.id) !== -1) {
            setNSFW(true);
            setHide(true);
        }

        setCaption(item.caption.split(' '));
    }, [item, nsfwList]);

    useEffect(() => {
        if(deleteResult.status == true) {
            setOpenConfirm(false);
            dispatch(initDeleteResult());
        } else {
            if(deleteResult.message != '') {
                enqueueSnackbar(deleteResult.message, { variant: 'error' });
            }
        }
        // eslint-disable-next-line
    }, [deleteResult]);

    const handleResize = useCallback(() => {
        const currentWidth = window.innerWidth;
        const nearestBiggerBreakpoint = Object.keys(HomeBreakPointCols).find((breakpoint) => currentWidth < parseInt(breakpoint));
        const columns = nearestBiggerBreakpoint ? HomeBreakPointCols[nearestBiggerBreakpoint] : HomeBreakPointCols.default;

        const height = (currentWidth / columns / (item.width || 1)) * (item.height || 1)
        setImageHeight(height);
    }, [item]);

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, [])

    return (
        <Stack>
            <Stack className={styles.container} sx={{ borderRadius: isMobile ? 0 : '6px' }}>
                <Stack position="relative">
                    <Box
                        component={LazyLoadImage}
                        onLoad={() => setVisibility('visible')}
                        visibility={visibility}
                        alt={item.caption}
                        wrapperClassName="wrapper"
                        effect="blur"
                        src={item.thumbnail}
                        width={'100%'}
                        height={imageHeight}
                        sx={{
                            display: visibility === 'hidden' ? 'none' : 'flex',
                            objectFit: 'cover',
                            borderRadius: 0,
                            filter: (nsfw && hide) ? 'blur(15px)' : 'none',
                            '& img': {
                                borderRadius: 0,
                                minHeight: imageHeight
                            }
                        }}
                    />
                    {hide && visibility !== 'hidden' && (
                        <Stack
                            className={styles.image_container}
                            width={'100%'}
                            height={imageHeight}
                            sx={{ backgroundImage: `url(${item.thumbnail})` }}
                        />
                    )}
                </Stack>
                {visibility === 'hidden' && <Skeleton variant="rectangular" animation="pulse" width="100%" height={imageHeight + 46} />}
                { visibility === 'visible' && (
                    <Stack
                        onClick={() => handleImageClick(item?.id)}
                        position="absolute"
                        height="100%"
                        width="100%"
                        justifyContent="end"
                        sx={{
                            borderRadius: 0,
                            cursor: 'pointer',
                            zIndex: 100,
                            transition: '0.3s',
                            ':hover': { background: 'rgba(0, 0, 0, 0.60)' },
                            ':hover .post-details': { opacity: 1 },
                            ':hover .post-setting': { opacity: 1 },
                            '& .post-details': { opacity: 0, transition: '0.3s' },
                            '& .post-setting': { opacity: 0, transition: '0.3s' }
                        }}
                    >
                        <ImageInfoContainer
                            caption={caption}
                            // item={item}
                        />
                        <Stack
                            className="post-setting"
                            direction="row"
                            spacing="6px"
                            sx={{
                                top: 10,
                                right: 10,
                                zIndex: 10011,
                                opacity: 0,
                                position: 'absolute',
                                display: hide && visibility === 'visible' ? 'none' : 'flex'
                            }}
                        >
                            {nsfw && (
                                <Button
                                    variant="contained"
                                    disableRipple
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setHide(true);
                                    }}
                                    className={styles.icon_btn}
                                >
                                    <GalleraiIcon name='hideEye' width={21} height={17}/>
                                </Button>
                            )}
                            <HtmlTooltip
                                title={
                                    user?.plan == 'free' && (
                                        <Stack style={{ color: '#FFFFFF' }}>
                                            <Typography style={{ color: '#FFFFFF', fontSize: '14px', fontFamily: 'SF Pro Text', fontWeight: '400' }}>
                                                Remix is available for all monthly subscribers, <a style={{ cursor: 'pointer' }} onClick={() => { navigate('/pricing') }}>sign up</a> now to enjoy this featrure.
                                            </Typography>
                                        </Stack>
                                    )
                                }
                            >
                                <Button
                                    variant="contained"
                                    disableRipple
                                    onClick={(e) => handleRemix(e)}
                                    className={styles.icon_btn}
                                    sx={{
                                        cursor: user?.plan == 'free' ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    <GalleraiIcon name='remix' width={26} height={22}/>
                                </Button>
                            </HtmlTooltip>

                            {isAdmin && (
                                <Button
                                    variant="contained"
                                    disableRipple
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setOpenConfirm(true);
                                    }}
                                    className={styles.icon_btn}
                                >
                                    <CloseIcon color="error" />
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                )}
                {hide && visibility === 'visible' && (
                    <Stack
                        onClick={() => handleImageClick(item?.id)}
                        position="absolute"
                        height="100%"
                        width="100%"
                        justifyContent="end"
                        sx={{
                            borderRadius: 0,
                            cursor: 'pointer',
                            zIndex: 100,
                            transition: '0.3s',
                            ':hover': { background: 'rgba(0, 0, 0, 0.60)' },
                            ':hover .post-details': { opacity: 1 },
                            ':hover .post-setting': { opacity: 1 },
                            '& .post-details': { opacity: 0, transition: '0.3s' },
                            '& .post-setting': { opacity: 0, transition: '0.3s' }
                        }}
                    >
                        <ImageInfoContainer
                            caption={caption}
                            // item={item}
                        />
                        <Stack height="100%" p={1} alignItems="center" justifyContent="center">
                            <IconButton onClick={handleEyeIcon}>
                                <GalleraiIcon name="eye" width={21} height={21}/>
                            </IconButton>
                        </Stack>
                    </Stack>
                )}
            </Stack>
            {visibility === 'visible' && (
                <Stack className={isMobile ? styles.user_info_container_mobile : styles.user_info_container}>
                    <GalleraiAvatar user={item?.user} width={28} height={28} size={7} scale={4} />
                    <Stack sx={{ width: '100%' }}>
                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <Typography className={styles.user_name}>
                                {item.user?.username}
                            </Typography>
                            <Box className={styles.favorite_container_sub}>
                                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    <GalleraiIcon
                                        name='favorite'
                                        width={16}
                                        height={16}
                                        onClick={(e) => handleFavorite(e)}
                                        className={fav ? styles.favorite_icon : styles.favorite_icon_empty}
                                    />
                                </div>                                
                                { favorites.length != 0 && (
                                    <span className={styles.favorite_count}>{favorites.length}</span>
                                )}
                            </Box>
                        </Box>
                        <Typography className={styles.post_date}>
                            {fDateAgo(new Date(item.createdAt))}
                        </Typography>
                    </Stack>
                </Stack>
            )}
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title="Delete"
                content="Are you sure want to delete it?"
                action={
                    <LoadingButton
                        sx={{ fontFamily: 'var(--primary-font-family)' }}
                        variant="contained"
                        color="error"
                        onClick={handleDelete}
                        loading={isDeleting}
                    >
                        Delete
                    </LoadingButton>
                }
            />
        </Stack>
    );
};

export default HomeImageCard;
