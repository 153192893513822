import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/material';

const Textarea = styled(BaseTextareaAutosize)(
    () => `
        box-sizing: border-box;
        width: 100%;
        font-family: 'SF Pro Text';
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        line-break: anywhere;
        padding: 0;
        border-radius: 0;
        resize: none;
        color: #FFF;
        background: transparent;
        border: none;
        box-shadow: none;
        overflow-x: hidden; /* or overflow-x: visible; */

        /* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
            width: 0;
        }

        /* Hide scrollbar for Firefox */
        scrollbar-width: none;

        &:hover {
            border-color: none;
        }

        &:focus {
            border-color: none;
            box-shadow: none;
        }

        // firefox
        &:focus-visible {
            outline: 0;
        }
    `,
);

export default Textarea;