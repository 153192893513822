import * as CookieConsent from 'vanilla-cookieconsent';
import type { CookieConsentConfig } from 'vanilla-cookieconsent';

const pluginConfig: CookieConsentConfig = {
  autoShow: true,

  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom left',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'bar',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: function ({ cookie }) {
    console.log('onFirstAction fired', cookie);
    logConsent();
  },

  onConsent: function ({ cookie }) {
    console.log('onConsent fired ...', cookie);
    logConsent();
  },

  onChange: function ({ changedCategories, cookie }) {
    console.log('onChange fired ...', changedCategories, cookie);
  },

  onModalShow: ({modalName}) => {
    // do something
    console.log('*modalName*', modalName)
    document.documentElement.classList.toggle('cc--dark');
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    functional: {
      autoClear: {
        cookies: [
          {
            name: 'gallerai-functional',
          },
        ],
      },
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    advertisement: {
      autoClear: {
        cookies: [
          {
            name: 'gallerai-advertisement',
          },
        ],
      },
    },
  },

  language: {
    default: 'en',

    translations: {
      en: {
        consentModal: {
          title: 'WE USE COOKIES',
          description:
            'GALLERAI USES COOKIES TO HELP US PROVIDE YOU WITH THE BEST POSSIBLE EXPERIENCE. BY CONTINUING TO VISIT THIS SITE, YOU AGREE TO OUR USE OF COOKIE. FOR More INFO, VIEW OUR <a href="/privacy">PRIVACY POLICY</a>',
          showPreferencesBtn: 'MANAGE PREFERENCES',
          acceptAllBtn: 'ACCEPT',
          acceptNecessaryBtn: 'DENY',
          closeIconLabel: 'Close',
          // footer: `
          //   <a href="#link">Privacy Policy</a>
          //   <a href="#link">Impressum</a>
          // `,
        },
        preferencesModal: {
          title: 'CONSENT PREFERENCES',
          acceptAllBtn: 'ALLOW ALL',
          acceptNecessaryBtn: 'NECESSARY ONLY',
          savePreferencesBtn: 'SAVE PERFERENCES',
          closeIconLabel: 'Close',
          sections: [
            {
              // title: 'Cookie Usage',
              description:
                'When you visit websites, they may store or retrieve data in your browser. This storage is often necessary for the basic functionality of the website. The storage may be used for marketing, analytics, and personalization of the site.',
            },
            {
              title: 'Strictly Necessary',
              description: 'These items are required to enable basic website functionality.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Functional Cookies',
              linkedCategory: 'functional',
              description: 'These items allow the website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced features personalized to you.',
              // cookieTable: {
              //   headers: {
              //     name: 'Name',
              //     domain: 'Domain',
              //     // description: 'Description',
              //     expiration: 'Expiration',
              //   },
              //   body: [
              //     {
              //       name: 'gallerai-functional',
              //       domain: window.location.hostname,
              //       // description:
              //       //   'Cookie set by <a href="#das">Google Analytics</a>.',
              //       expiration: 'Expires after 30 days',
              //     },
              //     // {
              //     //   name: '_gid',
              //     //   domain: 'Google Analytics',
              //     //   description:
              //     //     'Cookie set by <a href="#das">Google Analytics</a>',
              //     //   expiration: 'Session',
              //     // },
              //   ],
              // },
            },
            {
              title: 'Analytics Cookies',
              linkedCategory: 'analytics',
              description: 'These items help us understand how the website performs, how visitors interact with the site, and whether there may be technical issues.',
              // cookieTable: {
              //   headers: {
              //     name: 'Name',
              //     domain: 'Service',
              //     description: 'Description',
              //     expiration: 'Expiration',
              //   },
              //   body: [
              //     {
              //       name: '_ga',
              //       domain: 'Google Analytics',
              //       description:
              //         'Cookie set by <a href="#das">Google Analytics</a>.',
              //       expiration: 'Expires after 12 days',
              //     },
              //     {
              //       name: '_gid',
              //       domain: 'Google Analytics',
              //       description:
              //         'Cookie set by <a href="#das">Google Analytics</a>',
              //       expiration: 'Session',
              //     },
              //   ],
              // },
            },
            {
              title: 'Advertisement Cookies',
              linkedCategory: 'advertisement',
              description: 'These items are used to deliver advertising that is more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. ',
              // cookieTable: {
              //   headers: {
              //     name: 'Name',
              //     // domain: 'Service',
              //     // description: 'Description',
              //     expiration: 'Expiration',
              //   },
              //   body: [
              //     {
              //       name: 'gallerai-advertisement',
              //       domain: window.location.hostname,
              //       // description:
              //       //   'Cookie set by <a href="#das">Google Analytics</a>.',
              //       expiration: 'Expires after 30 days',
              //     },
              //     // {
              //     //   name: '_gid',
              //     //   domain: 'Google Analytics',
              //     //   description:
              //     //     'Cookie set by <a href="#das">Google Analytics</a>',
              //     //   expiration: 'Session',
              //     // },
              //   ],
              // },
            },
            // {
            //   title: 'More information',
            //   description:
            //     'For any queries in relation to my policy on cookies and your choices, please <a class="cc__link" href="#yourdomain.com">contact me</a>.',
            // },
          ],
        },
      },
    },
  },
};

const logConsent = async () => {

  // Retrieve all the fields
  const cookie = CookieConsent.getCookie();
  const preferences = CookieConsent.getUserPreferences();

  console.log('*currentCookie*', cookie)
  console.log('*currentPreferences*', preferences)

  // In this example we're saving only 4 fields
  const userConsent = {
      consentId: cookie.consentId,
      acceptType: preferences.acceptType,
      acceptedCategories: preferences.acceptedCategories,
      rejectedCategories: preferences.rejectedCategories
  };

  // Send the data to your backend
  // const res = await fetch('https://gallerai.cookiestroe.workers.dev/api/cookie', {
    try {
      fetch('https://cookies.galleraicompany.workers.dev/api/cookie', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_CLOUDFLARE_WORKER_TOKEN}`
        },
        body: JSON.stringify(userConsent)
      });
    } catch (error) {
      console.log(error)
    }
}

export default pluginConfig;
