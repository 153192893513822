import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ModelHistoryOptionType } from 'types/gallerai/adminTypes';
import api from 'utils/api';

export const uploadModelImage = createAsyncThunk('admin/uploadModelImage', async (file: any) => {
    const response = await api.put('/api/admin/model/image/upload', { image: file });

    return response.data;
})

export const getModelUsageHistory = createAsyncThunk('admin/getModelUsageHistory', async (data: ModelHistoryOptionType) => {
    const response = await api.get(`/api/admin/model/${data.id}/history`, { params: data });

    return response.data;
})

export const deleteUser = createAsyncThunk('admin/deleteUser', async (id: string) => {
    const response = await api.delete(`/api/admin/user/${id}`);

    return response.data;
})

export const updateUserPlan = createAsyncThunk('admin/updateUser', async (data: any) => {
    const response = await api.put(`/api/admin/user/${data.id}/plan`, data);

    return response.data;
})

export const updateUser = createAsyncThunk('admin/updateUser', async (data: any) => {
    const response = await api.put(`/api/admin/user/${data.id}`, data);

    return response.data;
})

export const getUser = createAsyncThunk('admin/getUser', async (id: string) => {
    const response = await api.get(`/api/admin/user/${id}`);

    return response.data;
})

export const getUsers = createAsyncThunk('admin/getUsers', async (data: any) => {
    const response = await api.get('/api/admin/user', { params: data });

    return response.data;
})

export const getLoras = createAsyncThunk('admin/getLoras', async (data: any) => {
    const response = await api.get('/api/admin/lora', { params: data });

    return response.data;
})

export const createLora = createAsyncThunk('admin/createLora', async (data: any) => {
    const response = await api.post('/api/admin/lora', data);

    return response.data;
})

export const updateLora = createAsyncThunk('admin/updateLora', async (data: any) => {
    const response = await api.put(`/api/admin/lora/${data.id}`, data);

    return response.data;
})

export const getLora = createAsyncThunk('admin/getLora', async (id: string) => {
    const response = await api.get(`/api/admin/lora/${id}`);

    return response.data;
})

export const deleteLora = createAsyncThunk('admin/deleteLora', async (id: string) => {
    const response = await api.delete(`/api/admin/lora/${id}`);

    return response.data;
})

export const getModel = createAsyncThunk('admin/getModel', async (id: string) => {
    const response = await api.get(`/api/admin/model/${id}`);

    return response.data;
})

export const getModels = createAsyncThunk('admin/getModels', async (data: any) => {
    const response = await api.get('/api/admin/model', { params: data });

    return response.data;
})

export const createModel = createAsyncThunk('admin/createModel', async (data: any) => {
    const response = await api.post('/api/admin/model', data);

    return response.data;
})

export const updateModel = createAsyncThunk('admin/updateModel', async (data: any) => {
    const response = await api.put(`/api/admin/model/${data.id}`, data);

    return response.data;
})

export const deleteModel = createAsyncThunk('admin/deleteModel', async (id: string) => {
    const response = await api.delete(`/api/admin/model/${id}`);

    return response.data;
})

export const getGenerations = createAsyncThunk('admin/getGenerations', async (data: any) => {
    const response = await api.get('/api/admin/generation', { params: data });

    return response.data;
})

export const getGeneration = createAsyncThunk('admin/getGeneration', async (id: string) => {
    const response = await api.get(`/api/admin/generation/${id}`);

    return response.data;
})

export const deleteGeneration = createAsyncThunk('admin/deleteGeneration', async (id: string) => {
    const response = await api.delete(`/api/admin/generation/${id}`);

    return response.data;
})

export const getPostsForAdmin = createAsyncThunk('admin/getPostsForAdmin', async (data: any) => {
    const response = await api.get('/api/admin/post', { params: data });

    return response.data;
})

export const getPostForAdmin = createAsyncThunk('admin/getPostForAdmin', async (id: string) => {
    const response = await api.get(`/api/admin/post/${id}`);

    return response.data;
})

export const deletePostForAdmin = createAsyncThunk('admin/deletePostForAdmin', async (id: string) => {
    const response = await api.delete(`/api/admin/post/${id}`);

    return response.data;
})

export const updatePostForAdmin = createAsyncThunk('admin/updatePostForAdmin', async (data: any) => {
    const response = await api.put(`/api/admin/post/${data.id}`, data);

    return response.data;
})

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        selectedTab: null
    },
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        }
    },
    extraReducers: (builder) => {
        console.log(builder)
    }
});

export const { setSelectedTab } = adminSlice.actions;

export default adminSlice.reducer;
