// ----------------------------------------------------------------------

export default function Link(theme: any) {
    console.log(theme);

    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            }
        }
    };
}
