import { useEffect, useState } from 'react';
import styles from './header-sub-home.module.scss';
import HeaderSearchBar from 'components/header/header-search-bar';
import { Box, ListSubheader, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import Iconify from 'components/iconify';
import GalleraiMenu from 'components/shared/gallerai-menu';

const Options = [
    { title: 'Images', active: true },
    { title: 'GIFs', active: false },
    { title: 'Videos', active: false },
    { title: 'Audio', active: false }
]

const HeaderSubHome = ({ trigger, isMobile, isSearch }) => {
    const theme = useTheme();
    const [tab, setTab] = useState('explore');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setTab('explore');
    }, []);

    return (
        <ListSubheader
            sx={{
                bgcolor: '#131313',
                position: 'fixed',
                top: trigger ? -56 : 0,
                width: '100%',
                height: 104,
                zIndex: theme.zIndex.appBar + 2,
                transition: '0.22s',
                alignItems: 'end',
                display: 'flex',
                px: isMobile ? 1.5 : 2
            }}
        >
            {isSearch ? (
                <HeaderSearchBar
                    className={styles.mobile_search_bar}
                    dropDownClassName={styles.mobile_search_bar_drop_down}
                />
            ) : (
                <Stack
                    sx={{
                        height: 48,
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box className={styles.left_group}>
                        <Typography 
                            className={tab === 'explore' ? styles.left_group_btn_selected : styles.left_group_btn} 
                            onClick={() => setTab('explore')}
                        >
                            Explore
                        </Typography>

                        <Typography 
                            className={tab === 'follow' ? styles.left_group_btn_selected : styles.left_group_btn} 
                            onClick={() => setTab('follow')}
                        >
                            Following
                        </Typography>

                        <Typography 
                            className={tab === 'trending' ? styles.left_group_btn_selected : styles.left_group_btn} 
                            onClick={() => setTab('trending')}
                        >
                            Trending
                        </Typography>
                    </Box>
                    <Box className={styles.right_group} onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <Typography className={styles.btn_sort_all}>
                            All
                        </Typography>
                        {
                            anchorEl == null ? (
                                <Iconify icon="mdi:arrow-down-drop" sx={{ color: 'white' }} />
                            ) : (
                                <Iconify icon="mdi:arrow-down-drop" sx={{ color: 'white', transform: 'rotate(180deg)' }} />        
                            )
                        }
                    </Box>
                    <GalleraiMenu
                        id="customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'topic-newest-option',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                    >
                        {
                            Options.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        // selected={selectedOption === item}
                                        // onClick={() => handleLatestOptionClick(item)}
                                        disableRipple
                                    >
                                        <Typography className={item.active == true ? styles.active_right_menu_item : styles.disable_right_menu_item}>{item.title}</Typography>
                                    </MenuItem>
                                );
                            })
                        }
                    </GalleraiMenu>
                </Stack>
            )}
        </ListSubheader>
    );
}

export default HeaderSubHome;