// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';
import { forwardRef } from 'react';

type IconifyProps = {
    sx?: any;
    width?: number | string;
    icon?: any;
};

const Iconify = forwardRef(({ icon, width = 20, sx, ...other }: IconifyProps, ref: any) => (
    <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, ...sx }} {...other} />
));

export default Iconify;
