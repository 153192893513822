import { useTheme } from '@mui/material/styles';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { bgBlur } from 'utils/cssStyles';
import {
    AppBar,
    Button,
    IconButton,
    Slide,
    Stack,
    Toolbar,
    useScrollTrigger
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import HeaderMobileMenu from './header-mobile-menu';
import HeaderCredit from './header-credit';
import HeaderLogo from './header-logo';
import HeaderMainMenu from './header-main-menu';
import HeaderUserAuth from './header-user-auth';
import HeaderUserMenu from './header-user-menu';
import styles from './header.module.scss';
import HeaderSearchBar from './header-search-bar/header-search-bar';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { HeaderSubGenerations, HeaderSubHome, HeaderSubMain } from './header-sub';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { getTextToImageModels, initResolutionForPaidUserAfterLogin } from 'store/generate';
import HeaderAnimation from './header-animation';

interface Props {
    window?: () => Window;
    children: React.ReactElement;
    tab?: any;
    updateTab?: any;
    isMobile?: boolean;
    closeSearch?: any;
    isSearch?: any;
    type?: string;
}

function HideOnScroll(props: Props) {
    const theme = useTheme();
    const { children, window, isMobile, isSearch, type } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        threshold: 0
    });

    return (
        <Stack>
            <Slide appear={false} direction="down" in={!trigger} style={{ zIndex: theme.zIndex.appBar + 3 }}>
                {children}
            </Slide>

            {
                type == 'home' ?
                <HeaderSubHome
                    trigger={trigger}
                    isMobile={isMobile}
                    isSearch={isSearch}
                />
                :
                type == 'main' ?
                <HeaderSubMain
                    trigger={trigger}
                    isSearch={isSearch}
                    isMobile={isMobile}
                />
                :
                type == 'generations' ?
                <HeaderSubGenerations
                    trigger={trigger}
                    isSearch={isSearch}
                    isMobile={isMobile}
                />
                :
                <></>
            }
        </Stack>
    );
}

const Header = (props: any) => {
    // States
    const [showMenu, setShowMenu] = useState(false);
    const [showSearch, setSearch] = useState(false);
    const textToImageModels = useSelector((state: RootState) => state.generate.textToImageModels);

    // Context
    const { user } = useContext(AuthContext);

    // Hooks
    const theme = useTheme();
    const navigate = useNavigate();
    const isDownLG = useResponsive('down', 1045);
    const isUpMD = useResponsive('up', 'md');
    // const isDownMD = useResponsive('down', 'md');
    const isDownSM = useResponsive('down', 'sm');
    const dispatch = useDispatch<AppDispatch>();

    const handleMenu = () => {
        setSearch(false);
        setShowMenu((prev) => !prev);
    }

    const handleGenerateClick = () => {
        navigate('/generate/text-to-image/');
    }

    const handleSearch = (event: any) => {
        event.preventDefault();

        setShowMenu(false);
        setSearch((prev) => !prev);
    }

    useEffect(() => {
        localStorage.setItem('galleraiLocation', window.location.pathname + window.location.search);

        if(textToImageModels.length == 0) {
            dispatch(getTextToImageModels())
                .then(() => {
                    if (user != null && user?.plan != 'free') {
                        dispatch(initResolutionForPaidUserAfterLogin())
                    }
                })
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <HideOnScroll {...props} closeSearch={handleSearch} isSearch={isUpMD ? false : showSearch} isMobile={isDownSM}>
                <AppBar
                    sx={{
                        zIndex: theme.zIndex.appBar + 1,
                        ...bgBlur({
                            color: theme.palette.background.default
                        }),
                        transition: theme.transitions.create(['height'], {
                            duration: theme.transitions.duration.shorter
                        })
                    }}
                    className={styles.appbar_container}
                >
                    <Toolbar className={styles.toolbar_container}>
                        <Stack direction="row" alignItems="center" sx={{ width: '100%', gap: '24px' }}>
                            <Stack direction="row" alignItems="center" spacing={3} width={'100%'}>
                                <HeaderLogo />
                                <HeaderSearchBar className={styles.search_bar} dropDownClassName={styles.search_bar_drop_down} />
                                {!isDownLG && <HeaderMainMenu />}
                            </Stack>
                            <Stack flex={1} spacing={3} direction="row" alignItems="center" justifyContent="flex-end" minWidth={'fit-content'}>
                                {/* {(isDownMD && !showMenu) && (
                                    <IconButton id="header_search_icon" onClick={handleSearch} sx={{ p: 0 }}>
                                        {showSearch ? <GalleraiIcon name='close' width={20} height={20} style={{ padding: '3px' }} /> : <GalleraiIcon name='search' width={20} height={20} />}
                                    </IconButton>
                                )} */}
                                {user ? (
                                    <>
                                        {!isDownSM && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    disableRipple
                                                    className={styles.generate_button}
                                                    onClick={handleGenerateClick}
                                                >
                                                    <HeaderAnimation />
                                                    Generate
                                                </Button>
                                                
                                            </>
                                        )}
                                        {(!showMenu && !isDownLG) && (
                                            <>
                                                <HeaderCredit />
                                                <HeaderUserMenu />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>{!isDownSM && (<HeaderUserAuth />)}</>
                                )}
                                {isDownLG && (
                                    <>
                                    {
                                        user != null ? (
                                            <div style={{ cursor: 'pointer' }} onClick={() => handleMenu()}>
                                                <GalleraiAvatar user={user} width={36} height={36} size={9} scale={4} />
                                            </div>
                                        ) : (
                                            <IconButton onClick={handleMenu} sx={{ p: 0 }}>
                                                {showMenu ? <GalleraiIcon name='close' width={20} height={20} style={{ padding: '3px' }} /> : <GalleraiIcon name='menu' width={20} height={20} />}
                                            </IconButton>
                                        )
                                    }
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <HeaderMobileMenu show={showMenu && isDownLG} setShow={setShowMenu} />
        </>
    );
};

export default Header;