import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './chatting.module.scss';
import { realTimeDB } from 'utils/firebase';
import { setReply } from 'store/home/detail';
import { Modal, Stack, Typography } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { millisecondsAgo } from 'utils/formatTime';
import { useContext, useEffect, useState } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { get, ref, remove, set } from 'firebase/database';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import GiphyItem from 'components/giphy/giphy-item';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { useSnackbar } from 'components/snackbar';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import useRole from 'hooks/useRole';

const MessageItem = ({ data, roomID, level1 = null, level2 = null }) =>  {
    const { isAdmin } = useRole();
    const { user, setOpenAuthModal } = useContext(AuthContext);    
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();    

    const [modal, setModal] = useState({
        state: false,
        role: ''
    });
    const [message, setMessage] = useState({
        sender: null,
        receiver: null,
        content: '',
        replies: [],
        favorites: [],
        createdAt: ''
    });

    const handleDelete = async () => {
        if(!roomID || !level1) return;

        const dbPath = level2 !== null ? `comments/${roomID}/${level1}/replies/${level2}` : `comments/${roomID}/${level1}`;
        const dbRef = ref(realTimeDB, dbPath);

        try {
            await remove(dbRef);
            enqueueSnackbar('Message deleted successfully');
        } catch (error) {
            enqueueSnackbar('Unexpected error occurred', { variant: 'error' });
        } finally {
            setModal({
                state: false,
                role: ''
            });
        }
    }

    const handleLikes = async () => {
        if(!user) {
            setOpenAuthModal(true);

            return;
        }

        if (!roomID || !level1) return;
    
        const dbPath = level2 !== null ? `comments/${roomID}/${level1}/replies/${level2}` : `comments/${roomID}/${level1}`;
        const dbRef = ref(realTimeDB, dbPath);
    
        try {
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const oldData = snapshot.val();
                const favoritesArray = [...(oldData?.favorites ?? [])];
    
                const index = favoritesArray.indexOf(user?.email);
                if (index !== -1) {
                    favoritesArray.splice(index, 1); // Remove the email address if it exists
                } else {
                    favoritesArray.push(user?.email); // Add the email address if it doesn't exist
                }
    
                const newData = {
                    ...oldData,
                    favorites: favoritesArray
                };
    
                await set(dbRef, newData);
            } else {
                console.log('No data found');
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleReply = () => {
        if(!user) {
            setOpenAuthModal(true);

            return;
        }
        
        const ref = document.getElementById('comment_input_base');
        if(ref) ref.focus();

        dispatch(setReply({
            replayID: level1,
            receiver: message?.sender
        }));
    };

    const extractGiphyId = (content: string) => {
        return content.split('giphy_')[1];
    };

    const extractEmoji = (content: string) => {
        return content.split('emoji_')[1];
    };

    const handleMoreHorizontal = () => {
        if(!user || message.sender == null) return;

        let role = '';
        if(isAdmin) {
            role = 'admin';
        } else if(user.email == message.sender?.email) {
            role = 'poster';
        } else {
            role = 'reporter';
        }

        setModal({
            state: true,
            role: role
        });
    };

    // Set message content if data is available
    useEffect(() => {
        if (!data) return;
    
        let createdAt = new Date(data?.created_at);
    
        if (isNaN(createdAt.getTime())) {
            createdAt = new Date();
        }

        setMessage({
            sender: data?.sender,
            receiver: data?.receiver,
            content: data?.message,
            replies: data?.replies,
            favorites: data?.favorites,
            createdAt: millisecondsAgo(createdAt)
        });
    }, [data]);

    return (
        <>
            <div className={styles.comment_item_main_container}>
                <Stack className={styles.sender_icon}>
                    <GalleraiAvatar user={message?.sender} width={32} height={32} size={8} scale={4} />
                </Stack>
                <Stack style={{ marginLeft: '44px' }}>
                    {
                        message?.content?.startsWith('giphy_') ? (
                            <Stack style={{ gap: '4px' }}>
                                <Typography className={styles.message}>
                                    <span className={styles.sender}>{message?.sender?.username.replace(/\s/g, '')} </span>
                                </Typography>
                                <GiphyItem gifId={extractGiphyId(message?.content)}/>
                            </Stack>
                        ) : message?.content?.startsWith('emoji_') ? (
                            <Stack style={{ gap: '4px' }}>
                                <Typography className={styles.message}>
                                    <span className={styles.sender}>{message?.sender?.username.replace(/\s/g, '')} </span>
                                </Typography>
                                <span style={{ fontSize: '28px' }}>{extractEmoji(message?.content)}</span>
                            </Stack>
                        ) : (
                            <Typography className={styles.message}>
                                <span className={styles.sender}>{message?.sender?.username.replace(/\s/g, '')} </span>
                                {message?.receiver && (<span className={styles.receiver}>{'@' + message?.receiver?.username.replace(/\s/g, '')} </span>)}
                                {message?.content}
                            </Typography>
                        )
                    }
                    <div className={styles.message_handler}>
                        <Typography className={styles.comment_date}>{message?.createdAt}</Typography>
                        {
                            message?.favorites?.length > 1 ?
                            <Typography className={styles.comment_likes}>
                                {message?.favorites?.length} likes
                            </Typography>
                            :
                            message?.favorites?.length == 1 ?
                            <Typography className={styles.comment_likes}>
                                {message?.favorites?.length} like
                            </Typography>
                            :
                            <></>
                        }
                        <Typography className={styles.reply_btn} onClick={handleReply}>Reply</Typography>
                        <GalleraiIcon
                            name='moreForizontal'
                            className={styles.more_horizontal_icon}
                            width={16}
                            height={16}
                            onClick={handleMoreHorizontal}
                        />
                    </div>
                </Stack>
            </div>
            {
                message?.favorites?.includes(user?.email) ?
                <FavoriteIcon className={styles.favourite_icon} onClick={handleLikes} style={{ color: '#FF3040' }}/>
                :
                <FavoriteBorderIcon className={styles.favourite_icon} onClick={handleLikes}/>
            }
            <Modal
                open={modal.state}
                onClose={() => setModal({ state: false, role: '' })}
                aria-labelledby="more-comments-modal-title"
                aria-describedby="more-comments-modal-description"
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)', // Change background color here
                    }
                }}
            >
                <Stack className={styles.more_modal}>
                    {
                        (modal.role == 'admin' || modal.role == 'poster') ? (
                            <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={handleDelete}>
                                <Typography className={styles.more_report}>
                                    Delete
                                </Typography>
                            </div>
                        ) : (
                            <div className={`${styles.more_menu_item} ${styles.more_border}`} onClick={() => setModal({ state: false, role: '' })}>
                                <Typography className={styles.more_report}>
                                    Report
                                </Typography>
                            </div>
                        )
                    }
                    <div className={styles.more_menu_item} onClick={() => setModal({ state: false, role: '' })}>
                        <Typography className={styles.more_item_text}>
                            Cancel
                        </Typography>
                    </div>
                </Stack>
            </Modal>
        </>
    );
}

const MessageContainer = ({ data, roomID, level1}) => {
    const [showReplies, setShowReplies] = useState(false);
    const [displayedReplies, setDisplayedReplies] = useState(0); 

    const getRandomIncrement = () => {
        return Math.floor(Math.random() * (9 - 3 + 1)) + 3;
    };
    
    const handleRplies = () => {
        if(showReplies) {
            if(displayedReplies < Object.keys(data?.replies).length) {
                if(displayedReplies + getRandomIncrement() < Object.keys(data?.replies).length) {
                    setDisplayedReplies(prev => prev + getRandomIncrement());    
                } else {
                    setDisplayedReplies(Object.keys(data?.replies).length);
                }
            } else {
                setShowReplies((prev) => !prev);
                setDisplayedReplies(0);            
            }            
        } else {
            setShowReplies((prev) => !prev);
            setDisplayedReplies(prev => prev + getRandomIncrement());
        }
    }

    return (
        <div>
            <div className={styles.message_level_1}>
                <MessageItem data={data} roomID={roomID} level1={level1} />
            </div>
            {data?.replies && (
                <div className={styles.view_replies_container}>
                    <div className={styles.view_replies_marker}/>
                    <Typography onClick={handleRplies} className={styles.view_replies_text}>
                        {
                            !showReplies || (showReplies && (Object.keys(data.replies).length - displayedReplies > 0)) ?
                            `View replies (${Object.keys(data.replies).length - displayedReplies})`
                            :
                            'Hide replies'
                        }
                    </Typography>
                </div>
            )}
            {
                showReplies && data?.replies && (
                    Object.keys(data.replies)
                        .reverse()
                        .slice(0, displayedReplies) // Only display the first 'displayedReplies' number of replies
                        .map((subKey: string) => (
                            <div className={styles.message_level_2} key={subKey}>
                                <MessageItem data={data?.replies[subKey]} roomID={roomID} level1={level1} level2={subKey} />
                            </div>
                        ))
                )
            }
        </div>
    );
}

const ChattingList = ({ roomID }) => {
    const comments = useSelector((state: RootState) => state.homeDetail.comments);

    // const [headerBar, setHeaderBar] = useState(false);

    // useEffect(() => {
    //     moveScroll(roomID);
    // }, [data, roomID]);

    // const handleScroll = (event) => {
    //     const scrollTop = event.srcElement.scrollTop;
    //     if (scrollTop === 0) {
    //         setHeaderBar(false);
    //     } else {
    //         setHeaderBar(true);
    //     }
    // };

    // useEffect(() => {
    //     const el: any = document.querySelector(`#chat-box-${roomID}`);

    //     if (el) {
    //         el.addEventListener('scroll', handleScroll);

    //         return () => {
    //             el.removeEventListener('scroll', handleScroll);
    //         };
    //     }
    // }, [roomID]);

    return (
        <Stack position="relative" flex={1} height="calc(100%)">
            <Stack
                spacing={1}
                overflow="auto"
                id={`chat-box-${roomID}`}
                sx={{
                    // pt: headerBar ? 0 : 1,
                    // pb: 1,
                    // px: 3,
                    flex: 1
                }}
            >
                {Object.keys(comments).reverse().map((key: string, index) => (
                    <MessageContainer key={index} data={comments[key]} roomID={roomID} level1={key} />
                ))}
            </Stack>
        </Stack>
    );
};

export default ChattingList;
