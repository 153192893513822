// @mui
import { Popover, PopoverOrigin } from '@mui/material';

import getPosition from './getPosition';
import { MenuPopoverProps } from './types';

// ----------------------------------------------------------------------

export default function MenuPopover({ open, children, arrow = 'top-right', sx, ...other }: MenuPopoverProps) {
    const { style, anchorOrigin, transformOrigin } = getPosition(arrow);

    return (
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            anchorOrigin={anchorOrigin as PopoverOrigin}
            transformOrigin={transformOrigin as PopoverOrigin}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 'auto',
                    overflow: 'inherit',
                    ...style,
                    '& .MuiMenuItem-root': {
                        borderRadius: 0.75,
                        '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 }
                    },
                    '& .MuiPaper-root': {
                        top: '56px !important',
                        left: '56px !important'
                    },
                    ...sx
                }
            }}
            {...other}
        >
            {children}
        </Popover>
    );
}
