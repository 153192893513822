import { Divider, MenuItem, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { HOST } from '../../../config';
import { AuthContext } from '../../../contexts/AuthContext';
import { IconButtonAnimate } from '../../animate';
import Iconify from '../../iconify';
import MenuPopover from '../../menu-popover';
import { PAY_PERSONAL } from 'utils/constants';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import { useCurrentAccount, useDisconnectWallet } from '@mysten/dapp-kit';
import GalleraiIcon from 'components/shared/gallerai-icon';

const HeaderUserMenu = () => {
    // States
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    // Context
    const { user, signOut } = useContext(AuthContext);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const currentAccount = useCurrentAccount();
    const { mutate: disconnect } = useDisconnectWallet();

    useEffect(() => {
        setSelectedPlan((PAY_PERSONAL.find((item) => item.plan === user?.plan)));
    }, [user]);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleLogout = async () => {
        try {
            if (currentAccount) disconnect();
            signOut('/login');
            handleClosePopover();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                sx={{
                    width: '230px',
                    p: 0,
                    mt: 1,
                    background: '#131313',
                    borderRadius: '6px !important',
                    border: 'none !important',
                    '& span': {
                        border: 'none !important'
                    },
                    '& p': {
                        letterSpacing: 0.3,
                        fontFamily: 'var(--primary-font-family) !important'
                    }
                }}
            >
                <Link to="/settings" onClick={handleClosePopover}>
                    <MenuItem
                        // disabled
                        sx={{
                            borderBottomRightRadius: '0px !important',
                            borderBottomLeftRadius: '0px !important',
                            px: '16px !important',
                            // opacity: '0.4 !important',
                            py: 1.5,
                            // '& .Mui-disabled': {
                            //     opacity: '0.4 !important',
                            // }
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={1.5} flex={1}>
                            <svg
                                width="14"
                                height="15"
                                style={{ margin: 0 }}
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.96336 14.8414H12.0366C13.3669 14.8414 14 14.4407 14 13.5592C14 11.4596 11.3475 8.42242 7.004 8.42242C2.65254 8.42242 0 11.4596 0 13.5592C0 14.4407 0.633084 14.8414 1.96336 14.8414ZM1.58671 13.6314C1.37836 13.6314 1.29021 13.5753 1.29021 13.407C1.29021 12.0847 3.32569 9.63247 7.004 9.63247C10.6743 9.63247 12.7097 12.0847 12.7097 13.407C12.7097 13.5753 12.6297 13.6314 12.4213 13.6314H1.58671ZM7.004 7.42074C8.91122 7.42074 10.4659 5.72981 10.4659 3.6623C10.4659 1.61076 8.91929 0 7.004 0C5.10474 0 3.54207 1.64282 3.54207 3.6783C3.54207 5.73781 5.09672 7.42074 7.004 7.42074ZM7.004 6.21062C5.83399 6.21062 4.83228 5.09672 4.83228 3.6783C4.83228 2.28391 5.81796 1.21008 7.004 1.21008C8.19805 1.21008 9.17573 2.25987 9.17573 3.6623C9.17573 5.08071 8.18198 6.21062 7.004 6.21062Z"
                                    fill="white"
                                />
                            </svg>
                            <Typography fontSize={14} color="#FFFFFF" flex={1}>
                                View profile
                            </Typography>
                            {/* <img src={`${HOST}/images/profile_short.svg`} alt="Profile" width={20} height={19} /> */}
                        </Stack>
                    </MenuItem>
                </Link>
                <Link to="/generations" onClick={handleClosePopover}>
                    <MenuItem sx={{ borderRadius: '0px !important', px: '16px !important', py: 1.5 }}>
                        <Stack direction="row" alignItems="center" spacing={1.5} flex={1}>
                            <Iconify icon="ion:folder-outline" sx={{ m: '0px !important', color: 'white' }} />
                            <Typography fontSize={14} color="#FFFFFF" flex={1}>
                                Generations
                            </Typography>
                            {/* <img src={`${HOST}/images/short_g.svg`} alt="Profile" width={20} height={19} /> */}
                        </Stack>
                    </MenuItem>
                </Link>
                <Link to="/settings" onClick={handleClosePopover}>
                    <MenuItem sx={{ borderRadius: '0px !important', px: '16px !important', py: 1.5 }}>
                        <Stack direction="row" alignItems="center" spacing={1.5} flex={1}>
                            <svg
                                width="16"
                                height="16"
                                style={{ margin: 0 }}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.698374 5.13393L5.37063 9.81281C6.3051 10.7406 7.29919 10.7406 8.2071 9.83272L9.08853 8.95128C9.99649 8.04332 9.99649 7.04923 9.06868 6.12142L4.39641 1.44251C3.46197 0.51469 2.46787 0.51469 1.55993 1.42263L0.67849 2.30407C-0.22945 3.212 -0.22945 4.20611 0.698374 5.13393ZM1.29483 4.44469C0.811039 3.96089 0.817667 3.45722 1.29483 2.97342L2.22928 2.03235C2.69982 1.55518 3.21675 1.56181 3.70055 2.03897L8.47222 6.81064C8.95603 7.29445 8.94936 7.79811 8.47222 8.28192L7.54441 9.22296C7.06721 9.70016 6.55032 9.69354 6.06651 9.21635L1.29483 4.44469ZM4.62175 6.53228C4.72115 6.6317 4.88021 6.6317 4.97299 6.53228C5.06578 6.43953 5.0724 6.28707 4.97299 6.18765L2.49438 3.70906C2.40822 3.62953 2.24254 3.61627 2.14313 3.70906C2.05034 3.81509 2.04372 3.97415 2.14313 4.06693L4.62175 6.53228ZM5.43691 5.72374C5.52969 5.82316 5.70202 5.80993 5.78815 5.72374C5.88757 5.61109 5.88757 5.45867 5.79477 5.36589L3.31617 2.89389C3.21675 2.79448 3.0577 2.80111 2.97154 2.89389C2.87213 2.99331 2.87876 3.14574 2.97154 3.23852L5.43691 5.72374ZM13.9662 15.8901L15.1526 14.7038C15.2917 14.558 15.2718 14.3459 15.1393 14.1404L13.7542 12.0727C13.615 11.8673 13.4029 11.7215 13.1644 11.6883L12.5679 11.5955L9.32052 8.3482L8.71082 9.05732L12.1835 12.5234L12.9258 12.6493L14.1916 14.3326L13.5885 14.9357L11.9118 13.6699L11.7792 12.9276L8.33304 9.48147L7.71673 10.1773L10.8514 13.312L10.9442 13.9085C10.9774 14.1471 11.1298 14.3592 11.3286 14.4917L13.4029 15.8967C13.615 16.0359 13.8204 16.0359 13.9662 15.8901Z"
                                    fill="white"
                                    fillOpacity="0.85"
                                />
                                <path
                                    d="M1.03647 15.1079C2.18963 16.2478 3.73379 16.261 4.86706 15.1211C6.29192 13.683 6.06661 12.066 10.4141 7.65217C11.8324 8.32153 13.5489 8.09617 14.6888 6.9629C15.8817 5.77003 16.0871 3.88785 15.199 2.36356L13.3964 4.16619C13.2175 4.34513 12.9922 4.35839 12.7999 4.16619L11.9914 3.35104C11.7992 3.15222 11.7793 2.92026 11.9583 2.74132L13.7676 0.94532C12.2565 0.0638886 10.381 0.262708 9.18806 1.46225C8.04817 2.60215 7.81619 4.312 8.48556 5.73021C4.07841 10.0777 2.46135 9.85243 1.02322 11.2707C-0.123308 12.4039 -0.110054 13.9547 1.03647 15.1079Z"
                                    fill="white"
                                />
                                <path
                                    d="M1.03647 15.1079C2.18963 16.2478 3.73379 16.261 4.86706 15.1211C6.29192 13.683 6.06661 12.066 10.4141 7.65217C11.8324 8.32153 13.5489 8.09617 14.6888 6.9629C15.8817 5.77003 16.0871 3.88785 15.199 2.36356L13.3964 4.16619C13.2175 4.34513 12.9922 4.35839 12.7999 4.16619L11.9914 3.35104C11.7992 3.15222 11.7793 2.92026 11.9583 2.74132L13.7676 0.94532C12.2565 0.0638886 10.381 0.262708 9.18806 1.46225C8.04817 2.60215 7.81619 4.312 8.48556 5.73021C4.07841 10.0777 2.46135 9.85243 1.02322 11.2707C-0.123308 12.4039 -0.110054 13.9547 1.03647 15.1079ZM9.9038 6.24055C8.72415 5.06089 8.67778 3.19198 9.80443 2.07859C10.4407 1.43574 11.3486 1.17728 12.2168 1.31645L11.2558 2.27741C10.7654 2.76121 10.7786 3.38417 11.289 3.88122L12.2698 4.85543C12.7602 5.34586 13.3898 5.35911 13.8537 4.88857L14.8279 3.90773C14.9737 4.78917 14.7086 5.69713 14.0658 6.34659C12.9457 7.46662 11.0901 7.41357 9.9038 6.24055ZM1.69258 14.4518C0.930437 13.6764 0.943688 12.6889 1.6727 11.9533C3.2765 10.3628 4.23084 11.0387 8.92961 6.44601C9.03565 6.5918 9.15492 6.72436 9.28086 6.85687C9.40675 6.98281 9.54593 7.1087 9.69172 7.21473C5.10564 11.9135 5.78164 12.8678 4.19107 14.4584C3.45544 15.2007 2.46135 15.2073 1.69258 14.4518ZM2.92526 14.0542C3.37591 14.0542 3.74704 13.683 3.74704 13.2324C3.74704 12.7817 3.37591 12.4172 2.92526 12.4172C2.4746 12.4172 2.1101 12.7817 2.1101 13.2324C2.1101 13.683 2.4746 14.0542 2.92526 14.0542Z"
                                    fill="black"
                                    fillOpacity="0.85"
                                />
                            </svg>
                            <Typography fontSize={14} color="#FFFFFF" flex={1}>
                                Settings
                            </Typography>
                            {/* <img src={`${HOST}/images/short_s.svg`} alt="Profile" width={20} height={19} /> */}
                        </Stack>
                    </MenuItem>
                </Link>

                <Divider sx={{ borderColor: '#EAECF020', m: '0px !important' }} />
                <Link to="/pricing" onClick={handleClosePopover}>
                    <MenuItem
                        sx={{
                            borderRadius: '0px !important',
                            px: '16px !important',
                            paddingTop: '10px',
                            paddingBottom: '10px'
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ width: '100%' }}
                        >
                            {!user.plan && (
                                <>
                                    <Stack alignItems="start !important">
                                        <Typography fontSize={14} color="#fff" fontWeight={500}>
                                            Free Plan
                                        </Typography>
                                        <Iconify
                                            icon="bx:infinite"
                                            sx={{ color: '#FFFFFFBF', height: '18px !important' }}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography
                                            fontFamily="SF"
                                            fontSize="12px !important"
                                            color="#67FC84"
                                            fontWeight={500}
                                        >
                                            Upgrade
                                        </Typography>
                                    </Stack>
                                </>
                            )}

                            {user.plan && (
                                <>
                                    <Stack alignItems="start !important">
                                        <Typography fontSize={14} color="#fff" fontWeight={500}>
                                            {selectedPlan?.title}
                                        </Typography>
                                        <Typography fontSize="12px !important" color="#FFFFFFBF">
                                            Active
                                        </Typography>
                                    </Stack>
                                    <Stack>
                                        <Typography fontSize="12px !important" color="#67FC84" fontWeight={500}>
                                            Upgrade
                                        </Typography>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </MenuItem>
                </Link>
                <Divider sx={{ borderColor: '#EAECF020', m: '0px !important' }} />
                <MenuItem
                    onClick={handleLogout}
                    sx={{
                        borderTopRightRadius: '0px !important',
                        borderTopLeftRadius: '0px !important',
                        px: '16px !important',
                        py: 1.5
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                        <GalleraiIcon name='logout' width={16} height={16} />
                        <Typography fontSize={14} fontFamily="var(--primary-font-family)" color="#FFFFFFD9" fontWeight={500}>
                            Logout
                        </Typography>
                    </Stack>
                </MenuItem>
            </MenuPopover>
            <IconButtonAnimate
                {...{ onClick: (e: React.MouseEvent<HTMLElement>) => handleOpenPopover(e) }}
                disableRipple
                sx={{
                    p: 0,
                    gap: 1,
                    borderRadius: 2,
                    alignItems: 'center',
                    ...(openPopover && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            position: 'absolute'
                        }
                    })
                }}
            >
                <GalleraiAvatar user={user} width={36} height={36} size={9} scale={4} />
            </IconButtonAnimate>
        </>
    );
};

export default HeaderUserMenu;
