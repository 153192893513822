import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import useResponsive from '../../../hooks/useResponsive';
import GalleraiIcon from 'components/shared/gallerai-icon';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { getPosts, setFilter } from 'store/home';
import { setFilterForGenerations } from 'store/generations';
import { InitFilter } from 'utils/constants/home';
import { useEffect, useState } from 'react';
import styles from './header-logo.module.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuPopover from 'components/menu-popover';
import { Stack, Typography } from '@mui/material';
// import { Stack, Typography } from '@mui/material';
// import { HOST } from 'config';
// import Image from 'components/image';
import { HOST } from 'config';

const HeaderLogo = () => {
    // Hook
    const dispatch = useDispatch<AppDispatch>();
    const isMobile = useResponsive('down', 'sm');
    const isTablet = useResponsive('down', 1024);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const matchHome = useMatch('/home');
    const matchHomeWithId = useMatch('/home/:id');
    const matchGenerate = useMatch('/generate/:type');
    const matchGenerations = useMatch('/generations');

    const isHomePage = Boolean(matchHome) || Boolean(matchHomeWithId);
    const isGeneratePage = Boolean(matchGenerate);
    const isGenerationsPage = Boolean(matchGenerations);

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const handlePopover = (event: React.MouseEvent<HTMLElement>) => {
        if (openPopover === null) {
            setOpenPopover(event.currentTarget);
        } else {
            setOpenPopover(null);
        }
    }

    const handleClosePopover = () => {
        setOpenPopover(null);
    };
    
    const handleLogo = () => {
        if(Boolean(matchHome) === true) {
            window.location.reload();
        } else {
            dispatch(setFilter(InitFilter));
            dispatch(getPosts());

            const homeContainer = document.getElementById('home-list');
            if (homeContainer) {
                const displayStyle = window.getComputedStyle(homeContainer).getPropertyValue('display');
                if (displayStyle === 'none') {
                    homeContainer.style.display = 'block';
                }
            }

            // Clear scroll position if pageNumber == 1
            const customEvent = new CustomEvent('clearScrollPositoin', { detail: { status: true, message: 'clear scroll position' } });
            window.dispatchEvent(customEvent);
            
            navigate('/home');
        }
    };

    useEffect(() => {
        // If pathname is not '/home' or '/home/:id', initialize filter
        if (!isHomePage) {
            dispatch(setFilter(InitFilter));
        }

        if (!isGenerationsPage) {
            dispatch(setFilterForGenerations({ pageNumber: 1, pageSize: 25, searchQuery: '' }));
        }
    }, [dispatch, pathname, isHomePage, isGenerationsPage]);

    return (
        <>
            <div className={styles.container} onClick={isGeneratePage ? handlePopover : handleLogo}>
                {isMobile ? <GalleraiIcon name='mobileLogo' width={39} height={28} /> : <GalleraiIcon name='mainLogo' width={96} height={22} />}
                {(isGeneratePage && !isMobile) && (openPopover != null ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
            </div>
            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: isTablet ? 220 : 360 }}
                anchorPosition={{ top: 100, left: 0 }}
                sx={{
                    p: '12px !important',
                    m: 0,
                    top: '50px !important',
                    left: '12px !important',
                    background: '#222222',
                    borderRadius: '6px !important',
                    '& span': {
                        border: 'none !important'
                    }
                }}
            >
                <Stack className={styles.drop_down_container}>
                    <div className={styles.image_container}>
                        <img
                            src={`${HOST}/images/header/misfits-poster.png`}
                            className={styles.img}
                        />
                        <div className={styles.overlay}>
                            <div className={styles.description_container}>
                                <div className={styles.header_wrapper}>
                                    <Typography className={styles.header_wrapper_title}>Gallerai x Misfits</Typography>
                                    <Typography className={styles.header_wrapper_description}>Generate traits from Misfits NFT collection.</Typography>
                                </div>
                                {/* <Typography className={styles.more_info} onClick={() => navigate('https://gallerai.ai/forum/topic/666350b535d424de1335c66a/show')}>More Info</Typography> */}
                            </div>
                        </div>
                    </div>
                    <div className={styles.menu_container}>
                        <div className={styles.menu_item}>
                            <div className={styles.wrapper} onClick={() => navigate('/home')}>
                                <Typography className={styles.title}>Home Feed</Typography>
                                <Typography className={styles.description}>Get inspired by community shared content.</Typography>
                            </div>
                        </div>
                        <div className={styles.menu_item}>
                            <div className={styles.wrapper} onClick={() => navigate('/tools')}>
                                <Typography className={styles.title}>Tools</Typography>
                                <Typography className={styles.description}>See the latest AI tolls available and whats in development.</Typography>
                            </div>
                        </div>
                        <div className={styles.menu_item}>
                            <div className={styles.wrapper} onClick={() => navigate('/pricing')}>
                                <Typography className={styles.title}>Pricing</Typography>
                                <Typography className={styles.description}>Start for free and upgrade when you love it.</Typography>
                            </div>
                        </div>
                        <div className={styles.menu_item}>
                            <div className={styles.wrapper} onClick={() => navigate('/forum/topic/list')}>
                                <Typography className={styles.title}>Resources</Typography>
                                <Typography className={styles.description}>Read the lates topics and open-source attribution.</Typography>
                            </div>
                        </div>
                    </div>
                </Stack>
            </MenuPopover>
        </>
    );
};

export default HeaderLogo;