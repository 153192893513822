import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import styles from './comment-input.module.scss';
import { Stack, Typography } from '@mui/material';
import { get, ref, push, set } from 'firebase/database';
import { realTimeDB } from 'utils/firebase';
import { serverTimestamp } from 'firebase/database';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setReply } from 'store/home/detail';
import Textarea from 'components/shared/textarea';
// import GalleraiIcon from 'components/shared/gallerai-icon';
import { RegExpMatcher, TextCensor, englishDataset, englishRecommendedTransformers } from 'obscenity';
import GalleraiAvatar from 'components/shared/gallerai-avatar';
import { HOST } from 'config';

const matcher = new RegExpMatcher({
	...englishDataset.build(),
	...englishRecommendedTransformers,
});

const keepStartCensorStrategy = () => '****';
const censor = new TextCensor().setStrategy(keepStartCensorStrategy);

const CommentInput = ({ roomID, totalComments, message, openEmoji, openGiphy, setMessage, setShowComments, setOpenGiphy, setOpenEmoji }) => {
    const dispatch = useDispatch<AppDispatch>();
    
    const { user, setOpenAuthModal } = useContext(AuthContext);
    const reply = useSelector((state: RootState) => state.homeDetail.reply);

    const handleSignIn = () => setOpenAuthModal(true);

    const handlePost = async () => {
        if (!user || !message || message.length > 200) return;

        let sendMessage = message;
        const matches = matcher.getAllMatches(sendMessage);
        sendMessage = censor.applyTo(sendMessage, matches);
    
        try {
            if (reply.replayID != null && reply.receiver != null) {
                const correctMessage = sendMessage.replace('@' + reply.receiver?.username.replace(/\s/g, ''), '');

                const replyPath = `comments/${roomID}/${reply.replayID}`;
                const replySnapshot = await get(ref(realTimeDB, replyPath));
    
                if (replySnapshot.exists()) {
                    const newReplyKey = push(ref(realTimeDB, `${replyPath}/replies`)).key;
                    const newReplyRef = ref(realTimeDB, `${replyPath}/replies/${newReplyKey}`);
    
                    const replyData = {
                        message: correctMessage,
                        sender: {
                            username: user.username,
                            email: user.email,
                            avatar: user.avatar,
                            blocky: user.blocky
                        },
                        receiver: reply.receiver,
                        created_at: serverTimestamp()
                    };
    
                    await set(newReplyRef, replyData);
                } else {
                    console.log('No data found for reply');
                }
    
                dispatch(setReply({ replayID: null, receiver: null }));
            } else {
                const newMessageKey = push(ref(realTimeDB, `comments/${roomID}`)).key;
                const newMessageRef = ref(realTimeDB, `comments/${roomID}/${newMessageKey}`);
    
                const messageData = {
                    message: sendMessage,
                    sender: {
                        username: user.username,
                        email: user.email,
                        avatar: user.avatar,
                        blocky: user.blocky
                    },
                    created_at: serverTimestamp()
                };
    
                await set(newMessageRef, messageData);
            }
    
            setMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setShowComments(true);
            dispatch(setReply({ replayID: null, receiver: null }));
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            handlePost();
        }
    };

    const handleClickTextArea = () => {
        if(totalComments == 0) return;
        setShowComments(true);
    };

    const handleMessageInput = (content: string) => {
        // Close Giphy popup if user types
        setOpenGiphy(false);

        // Check if content length exceeds 200 characters
        if (content.length > 200) return;
    
        // Reset reply if both replyID and receiver are not null and content doesn't start with reply prefix
        if (reply.replayID != null && reply.receiver != null) {
            const prefix = '@' + reply.receiver?.username.replace(/\s/g, '') + ' ';
            if (!content.startsWith(prefix)) {
                dispatch(setReply({ replayID: null, receiver: null }));
            }
        }
    
        // Set message content
        setMessage(content);
    }

    useEffect(() => {
        if(!reply.replayID || !reply.receiver) return;

        const modifiedString = '@' + reply.receiver?.username.replace(/\s/g, '');

        setMessage(modifiedString + ' ');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reply]);

    // Init message when roomID is changed. It means clean message when user click or visit another image
    useEffect(() => {
        setMessage('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomID]);

    return (
        <React.Fragment>
        {
            user ?
            <div className={styles.comments_input_container}>
                <div className={styles.comments_input_bar}>
                    <GalleraiAvatar user={user} width={24} height={24} size={12} scale={2} />
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Textarea 
                            id="comment_input_base"
                            aria-label="minimum height"
                            minRows={1}
                            maxRows={5}
                            placeholder="Add a comment"
                            value={message}
                            onClick={handleClickTextArea}
                            onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                                if (event.keyCode === 13) {
                                    // Execute your function here
                                    // For example, you can call a function named handleEnterKey
                                    event.preventDefault();
                                    handleKeyPress(event);
                                }
                            }}
                            className={styles.textarea_styles}
                            onChange={(event) => handleMessageInput(event.target.value)}
                        />
                    </div>                    
                </div>
                <div className={styles.comments_handler_group}>
                    {
                        message != '' ? (
                            <Stack className={styles.giphy_emoji_btn_group}>
                                <img
                                    id='emoji_btn_png'
                                    src={`${HOST}/images/emoji.png`}
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    onClick={() => {
                                        if(openEmoji || openGiphy) {
                                            setOpenEmoji(false)
                                            setOpenGiphy(false)
                                        } else {
                                            setOpenEmoji(true)    
                                        }
                                    }} 
                                />
                                <Typography className={message == '' ? styles.btn_post : styles.btn_post_highlight } onClick={handlePost}>
                                    Post
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack className={styles.giphy_emoji_btn_group}>
                                <img
                                    id='emoji_btn_png'
                                    src={`${HOST}/images/emoji.png`}
                                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                                    onClick={() => {
                                        if(openEmoji || openGiphy) {
                                            setOpenEmoji(false)
                                            setOpenGiphy(false)
                                        } else {
                                            setOpenEmoji(true)    
                                        }
                                    }} 
                                />
                                {/* <GalleraiIcon
                                    name='gif'
                                    width={29}
                                    height={20}
                                    className={styles.btn_giphy}
                                    onClick={() => setOpenGiphy((prev: boolean) => !prev)} 
                                /> */}
                            </Stack>
                        )
                    }
                </div>
            </div>
            :
            <div className={styles.sign_in_to_comment} onClick={handleSignIn}>
                <Typography className={styles.sign_in_to_comment_text}>
                    Sign in to comment
                </Typography>
            </div>
        }
        </React.Fragment>
    );
}

export default CommentInput;