import { PAY_PERSONAL } from './constants';
import getUTCtime from './getUTCtime';

export const sortData = (inputData: any[], key: string) => {
    inputData.sort((a, b) => b[key] - a[key]);

    return inputData;
};

export const handleTime = (billingTime: number, expireCount: number) => {
    const utcTime = getUTCtime();

    return utcTime - billingTime < expireCount;
};

export const numberWithCommas = (value: number) => {
    if (isNaN(value)) {
        return '0';
    }
    
    return value.toLocaleString();
};

export const generateRandomArray = (max: number) => {
    const numSections = Math.ceil(max / 10);

    const randomArray = []
    for (let i = 0; i < numSections; i++) {
        const start = i * 10;
        const end = Math.min((i + 1) * 10 - 1, max);
        const randomNumber = Math.floor(Math.random() * (end - start + 1)) + start;

        if(randomNumber == max) {
            randomArray.push(Math.min(randomNumber - 1, max));
        } else {
            randomArray.push(randomNumber);
        }        
    }

    return randomArray;
}

export const validateEmail = (email: string) => {
    // Regular expression pattern for validating email
    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailPattern.test(email);
};

export const getPlan = (plan: string) => {
    const item = PAY_PERSONAL.find((item) => item.plan == plan);

    if(item == undefined) {
        return { price: 0, plan: 'Free', maxBalance: 0 };
    } else {
        return { price: item.price, plan: item.title, maxBalance: item.max_balance };
    }
}

export const calculateCreditsTime = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    if (hours > 0) {
        return `~ ${hours} hr : ${minutes} min remaining`;
    } else if (minutes > 0) {
        return `~ ${minutes} min : ${seconds} sec remaining`;
    } else {
        return `~ ${seconds} sec remaining`;
    }
}

export const calculateCreditsTimeSimply = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    if (hours > 0) {
        // return `${hours} hr : ${minutes} min`;

        return { type: 'hour', hours: hours, minutes: minutes };
    } else if (minutes > 0) {
        return { type: 'minute', minutes: minutes, seconds: seconds };
    } else {
        return { type: 'second', seconds: seconds };
    }
}

export const extractHashtags = (text: string) => {
    // Regular expression to match hashtags without line breaks
    const hashtagRegex = /#[^\s#]+/g;

    // Replace multiple consecutive whitespace characters with a single space
    text = text.replace(/\s+/g, ' ');

    // Match all hashtags in the text using the regular expression
    const hashtags = text.match(hashtagRegex);

    return { hashtags: hashtags || [], filteredCaption: text }
};