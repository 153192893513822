export const PAYMENT_OPTIONS = ['Personal', 'Business'];

export const PAY_PERSONAL = [
    {
        title: 'Starter',
        plan: 'free',
        price: 'Free',
        priceId: '',
        type: 'subscription',
        limited_time: false,
        desc: 'Dive in and explore your creativity without spending a dime.',
        required: 'No credit card required',
        features: [
            {
                startIcon: 'checked',
                text: 'All available AI\'s',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: 'Private generations',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: '3 generations daily',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: 'Unlimited storage',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: 'Mint NFT images and collections',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: 'Locked generation parameters',
                endIcon: 'infoExclamation'
            },
            {
                startIcon: 'checked',
                text: 'Ad supported',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: '30 second cooldowns',
                endIcon: ''
            }
        ],
        btnLabel: 'Get started',
        link: '#',
        recommended: false
    },
    {
        title: 'Essential',
        plan: 'essential',
        price: '$5',
        priceId: process.env.REACT_APP_STRIPE_ESSENTIAL_ID,
        max_balance: 2070393,
        type: 'subscription',
        limited_time: true,
        desc: 'Enjoy essential features at out lowest price.',
        required: '',
        features: [
            {
                startIcon: 'doubleChecked',
                text: 'Everything available in Starter',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: '2,355,000 Inference credits',
                endIcon: 'infoExclamation'
            },
            {
                startIcon: 'checked',
                text: 'All generation parameters',
                endIcon: 'infoExclamation'
            },
            {
                startIcon: 'checked',
                text: 'Ad free generation',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: '10 second cooldowns',
                endIcon: ''
            }
        ],
        btnLabel: '',
        link: '/payment',
        recommended: false
    },
    {
        title: 'Pro Lite',
        plan: 'pro_lite',
        price: '$16',
        priceId: process.env.REACT_APP_STRIPE_PRO_LITE_ID,
        max_balance: 6627257,
        type: 'subscription',
        limited_time: true,
        desc: 'Upgrade to the perfect balance of GPU power and affordability.',
        required: '',
        features: [
            {
                startIcon: 'doubleChecked',
                text: 'Everything available in Essential',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: '15,942,000 Inference credits',
                endIcon: 'infoExclamation'
            }
        ],
        btnLabel: '',
        link: '/payment',
        recommended: false
    },
    {
        title: 'Pro',
        plan: 'pro',
        price: '$35',
        priceId: process.env.REACT_APP_STRIPE_PRO_ID,
        max_balance: 14492753,
        type: 'subscription',
        limited_time: true,
        desc: 'The ultimate choice for the most demanding of users.',
        required: '',
        features: [
            {
                startIcon: 'doubleChecked',
                text: 'Everything available in Pro Lite',
                endIcon: ''
            },
            {
                startIcon: 'checked',
                text: '25,362,000 Inference credits',
                endIcon: 'infoExclamationGreen'
            }
        ],
        btnLabel: '',
        link: '/payment',
        recommended: true
    }
];

export const PAY_BUSINESS = [
    {
        title: 'Business',
        price: 'Comming Soon',
        desc: 'Great for startups looking to develop, test and launch an MVP',
        features: [],
        btnLabel: 'Coming soon',
        link: '#',
        recommended: true
    },
    {
        title: 'Enterprise',
        price: 'Comming Soon',
        desc: 'Great for serious traffic, 100% uptime and limitless generations',
        list: [],
        btnLabel: 'Coming soon',
        link: '/payment',
        recommended: false
    }
];

export const TESTIMONIALS = [
    {
        icon: 'checkedGreen',
        title: 'Always Private',
        content: 'Keep your generations \n private by default. Public \n sharing is optional.'
    },
    {
        icon: 'checkedGreen',
        title: 'Pay for what you use',
        content: 'Our millisecond-accurate \n pricing saves you money and \n ensures fair usage.'
    },
    {
        icon: 'checkedGreen',
        title: 'No charge cold ctarts',
        content: 'We don\'t charge for server \n delays or model cold starts. \n Only what you use!'
    },
    {
        icon: 'checkedGreen',
        title: 'Always With Workflow',
        content: 'Search the best prompts \n with complete workflow and \n get countless of ideas.'
    },
]

export const FAQS = [
    {
        id: 'faq_1',
        question: 'Why do most people upgrade to the Pro plan?',
        answer: 'The Pro plan is favored by users looking for uninterrupted content creation. With full generation parameters, shorter cooldown times, and larger credit balance with top-up options, you can generate more content seamlessly. This makes it the perfect choice for most users.'
    },
    {
        id: 'faq_2',
        question: 'Can I create NSFW content?',
        answer: 'Yes, you can create NSFW (Not Safe For Work) content on our platform, but please ensure it complies with our community guidelines before sharing publicly on the platform. View our terms of use '
    },
    {
        id: 'faq_3',
        question: 'Which Gallerai plan is right for me?',
        answer: 'Choosing the right plan is all about your needs. The Starter plan is perfect for exploration, while the Essential, Pro Lite, and Pro plans cater to creators at varying experience levels. With plans offering a range of features, access, and inference time, there\'s definitely a plan that\'s just right for you!'
    },
    {
        id: 'faq_4',
        question: 'Are my generations private?',
        answer: 'Your creations, your privacy—always. All plans, including our free tier, offer private generations by default. This is typically a feature reserved for paid users on competing platforms unfortunately. Not here!'
    },
    {
        id: 'faq_5',
        question: 'Can I upgrade from one plans in the middle of the billing period?',
        answer: 'Yes, you can upgrade your plan at any time. Your new plan will take effect immediately and you will be charged or credited the prorated difference.'
    },
    {
        id: 'faq_6',
        question: 'Can I use the content I made in Gallerai for commercial purposes?',
        answer: 'Yes, you can upgrade your plan at any time. Your new plan will take effect immediately and you will receive your additional credits with your remaining amount.'
    },
    {
        id: 'faq_7',
        question: 'Why does it take varying credit amounts per generation?',
        answer: 'The credit amount per generation varies based on the complexity and length of your generation. Keep in mind, the more complex the generation, the more inference time/credit is needed to render the content. In addition to being accurately charged for usage down to the millisecond, Gallerai does not charge a base amount of credits per generation. We also do not charge credits for server delays or model cold starts.'
    },
    {
        id: 'faq_8',
        question: 'How do I top up my credit balance if I finish my monthly balance?',
        answer: 'For now, credit packs are exclusive to Pro plan subscribers. You can top up your credit balance at any time by purchasing additional credits through your account settings or when prompted.'
    },
    {
        id: 'faq_9',
        question: 'When will the coming soon features be available?',
        answer: 'We\'re continually enhancing our platform with the latest AI tools, features, and improvements to ensure the best possible user experience. Stay updated on our announcements for the latest updates and releases!'
    },
    {
        id: 'faq_10',
        question: 'How frequently are AI models updated on Gallerai?',
        answer: 'AI\'s and models on our platform are updated regularly at the speed of open source. We carefully select and integrate only the best and in demand models to ensure you have access to the latest advancements in AI technology.'
    },
]