import api from 'utils/api';
import { RootState } from 'store';
import { HomeImage } from 'types/gallerai/homeTypes';
import { Dispatch, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { generateRandomArray } from 'utils/utils';
import { HOST } from 'config';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

export const getRelatedImages = createAsyncThunk('home/detail/getRelatedImages', async (_, { getState, dispatch }: Redux) => {
    const isloading = getState().homeDetail.isLoading;
    if (isloading) {
        return null
    } else {
        dispatch(setLoading(true));
    }

    const filter = getState().homeDetail.filter;
    if (filter.pageNumber == 1) dispatch(initRelatedImageAdapter());

    const response = await api.get(`/api/home/detail/images?imageId=${filter.imageId}&pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}`);

    if (filter.pageNumber == 1 && response.data?.length == 0) {
        dispatch(setKeepRelatedImagesBox(false));
    }

    return response.data;
});

export const getPost = createAsyncThunk('home/getPost', async (id: string, { dispatch }: Redux) => {
    dispatch(setReply({
        replayID: null,
        receiver: null,
    }))
    
    const response = await api.get(`/api/post/${id}`);

    return response.data;
});

export const updateLikes = createAsyncThunk('home/detail/updateLikes', async(id: string) => {
    const response = await api.get(`/api/home/image/likes?id=${id}`);

    return response.data;
});

const relatedImagesAdapter = createEntityAdapter<HomeImage>({});

export const { selectById: selectRelatedImageById, selectAll: selectAllRelatedImages } = relatedImagesAdapter.getSelectors(
    (state: RootState) => state.homeDetail
);

export const homeDetailSlice = createSlice({
    name: 'home/detail',
    initialState: relatedImagesAdapter.getInitialState({
        isLoading: false,
        filter: {
            imageId: '',
            pageNumber: 1,
            pageSize: 30
        },
        reply: {
            replayID: null,
            receiver: null,
        },
        comments: {},
        keepRelatedImagesBox: true
    }),
    reducers: {
        setKeepRelatedImagesBox: (state, action) => {
            state.keepRelatedImagesBox = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = { ...state.filter, ...action.payload };
        },
        initRelatedImageAdapter: (state) => {
            relatedImagesAdapter.removeAll(state);
        },
        setReply: (state, action) => {
            state.reply = { ...state.reply, ...action.payload };
        },
        setComments: (state, action) => {
            state.comments = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRelatedImages.fulfilled, (state, action) => {
                const data = action.payload;
                const ads = generateRandomArray(data?.length ?? 0);

                if (data) {
                    data.map((item: any, index: number) => {
                        const isAd = ads.includes(index);

                        if(isAd) {
                            const adImage = {
                                id: `${item.id}_ad`,
                                isAd: true,
                                width: 0,
                                height: 0,
                                thumbnail: `${HOST}/images/injected_ad_placeholder.png`,
                                original: `${HOST}/images/injected_ad_placeholder.png`,
                                caption: '',
                                isNSFW: false,
                                hideSeed: false,
                                createdAt: '0',
                                favorite: [],
                                metadata: null,
                                modelId: null, 
                                user: null
                            };

                            relatedImagesAdapter.addOne(state, adImage);
                        }

                        const newImage = {
                            id: item.id,
                            isAd: false,
                            width: item.width,
                            height: item.height,
                            thumbnail: item.thumbnail,
                            original: item.original,
                            caption: item.caption,
                            isNSFW: item.isNSFW,
                            hideSeed: item.hideSeed,
                            createdAt: item.createdAt,
                            favorite: item.favorite,
                            metadata: item.metadata,
                            modelId: item.modelId,
                            user: item.user
                        };
    
                        relatedImagesAdapter.addOne(state, newImage);
                    });
                    state.isLoading = false;
    
                    if (data.length > 0) {
                        state.filter.pageNumber += 1;
                    }
                } else {
                    state.isLoading = false;
                }
            })
            .addCase(getRelatedImages.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export const { setLoading, setFilter, initRelatedImageAdapter, setReply, setComments, setKeepRelatedImagesBox } = homeDetailSlice.actions;

export default homeDetailSlice.reducer;
