import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import firebase from 'firebase/compat/app';
import { getDatabase } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import axiosServices from './axios';
// import jwt from 'jsonwebtoken';
// import { useContext } from 'react';
// import { AuthContext } from 'contexts/AuthContext';

// import getUTCtime from './getUTCtime';

const firebaseConfig = {
    apiKey: 'AIzaSyBYOltasQOFMJ1SfzCYBaQQrLQy9Juos4k',
    authDomain: 'auth.gallerai.ai', //'gallerai-bd007.firebaseapp.com',
    databaseURL: 'https://gallerai-bd007-default-rtdb.firebaseio.com',
    projectId: 'gallerai-bd007',
    storageBucket: 'gallerai-bd007.appspot.com',
    messagingSenderId: '487844443513',
    appId: '1:487844443513:web:b3af14f1caaba01fb6aba8',
    measurementId: 'G-NQT1NYJSGS'
};

export enum SocialLoginType {
    google = 'google',
    facebook = 'facebook',
    twitter = 'twitter',
    apple = 'apple'
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const realTimeDB = getDatabase(app);

const functions = getFunctions(app);
export const createIntent = httpsCallable(functions, 'createIntent');
export const verifyIntent = httpsCallable(functions, 'verifyIntent');
export const fetchData = httpsCallable(functions, 'fetch');
export const createSessionCheckout = httpsCallable(functions, 'createSessionCheckout');

export const auth = firebase.auth();
export const database = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');

googleProvider.setCustomParameters({ prompt: 'select_account' });
facebookProvider.setCustomParameters({ prompt: 'select_account' });
twitterProvider.setCustomParameters({ prompt: 'select_account' });

export const signInWithSocial = async (type: string) => {
    let provider;
    if (type === SocialLoginType.google) {
        provider = googleProvider;
    } else if (type === SocialLoginType.facebook) {
        provider = facebookProvider;
        provider.setCustomParameters({
            display: 'popup'
        });
    } else if (type === SocialLoginType.twitter) {
        provider = twitterProvider;
        provider.setCustomParameters({
            lang: 'en'
        });
    } else if (type === SocialLoginType.apple) {
        provider = appleProvider;
        provider.addScope('email');
        provider.addScope('name');
    }
    auth.signInWithPopup(provider)
        .then(async (result) => {
            const { user } = result;
            const idToken = await user.getIdToken();

            window.localStorage.setItem('idToken', idToken);
            window.dispatchEvent(new Event('storage'));
        })
        .catch((error) => {
            console.log('--- Login Error: ' + JSON.stringify(error) + ' ---\n');

            const customEvent = new CustomEvent('stopSocialProcess');
            window.dispatchEvent(customEvent);
        });
};

export const signInMobileWithSocial = (type: string) => {
    let provider;
    if (type === SocialLoginType.google) {
        provider = googleProvider;
    } else if (type === SocialLoginType.facebook) {
        provider = facebookProvider;
        provider.setCustomParameters({
            display: 'popup'
        });
    } else if (type === SocialLoginType.twitter) {
        provider = twitterProvider;
        provider.setCustomParameters({
            lang: 'en'
        });
    } else if (type === SocialLoginType.apple) {
        provider = appleProvider;
        provider.addScope('email');
        provider.addScope('name');
    }
    auth.signInWithRedirect(provider)
        .then()
        .catch((error) => {
            console.log('--- Login Error: ' + JSON.stringify(error) + ' ---\n');
        });
};
