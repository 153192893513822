import { InputBase, Stack, Tab, Tabs, Typography, CircularProgress } from '@mui/material';
import GalleraiIcon from 'components/shared/gallerai-icon';
import styles from './header-search-bar.module.scss';
import { numberWithCommas } from 'utils/utils';
import { useEffect, useRef, useState } from 'react';
import { getSearchResults, initSearchResult, setIsSelectedSearchItem, setOpenSearchDropDown } from 'store/header';
import { getPosts, setFilter } from 'store/home';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import useDebounce from 'hooks/useDebounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchTabs } from 'utils/constants';
import { InitFilter } from 'utils/constants/home';

const HeaderSearchBar = ({ className, dropDownClassName }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const searchRef = useRef(null);
    const [seletedTab, setSelectedTab] = useState(0);

    const filter = useSelector((state: RootState) => state.home.filter);
    const debouncedSearchText = useDebounce(filter.searchQuery, 500);
    const isSearching = useSelector((state: RootState) => state.header.isSearching);
    const isSelectedSearchItem = useSelector((state: RootState) => state.header.isSelectedSearchItem);
    const openSearchDropDown = useSelector((state: RootState) => state.header.openSearchDropDown);
    const searchResults = useSelector((state: RootState) => state.header.searchResults);

    const handleTabChange = (event: any, newTab: number) => {
        setSelectedTab(newTab);
    }

    const handleTabClick = (tab: string) => {
        console.log(tab)
    }

    const handleSearchInput = (value: string) => {
        dispatch(setIsSelectedSearchItem(false));
        dispatch(initSearchResult());

        if (value != '' && value != undefined && value != null) {
            dispatch(setFilter({ ...filter, 'searchQuery': value }));
            dispatch(setOpenSearchDropDown(true));
        } else {
            dispatch(setFilter(InitFilter));
            dispatch(getPosts());
            dispatch(setOpenSearchDropDown(false));
        }
    }

    const handleClickSearchItem = (type: string, value: string) => {
        dispatch(setFilter({ 'pageNumber': 1, 'pageSize': 30, 'searchQuery': value, 'searchType': type }));
        dispatch(setIsSelectedSearchItem(true));
        dispatch(setOpenSearchDropDown(false));
        dispatch(initSearchResult());
        setSelectedTab(0);

        if(pathname === '/home') {
            dispatch(getPosts());
        } else {
            const homeContainer = document.getElementById('home-list');
            if (homeContainer) {
                const displayStyle = window.getComputedStyle(homeContainer).getPropertyValue('display');
                if (displayStyle === 'none') {
                    homeContainer.style.display = 'block';
                }
            }

            // Clear scroll position if pageNumber == 1
            const customEvent = new CustomEvent('clearScrollPositoin', { detail: { status: true, message: 'clear scroll position' } });
            window.dispatchEvent(customEvent);

            dispatch(getPosts());
            navigate('/home');
        }
    }

    const handleSearchClose = () => {
        dispatch(setFilter(InitFilter));
        dispatch(getPosts());
        dispatch(setOpenSearchDropDown(false));
        dispatch(setIsSelectedSearchItem(false));

        // Clear scroll position if pageNumber == 1
        const customEvent = new CustomEvent('clearScrollPositoin', { detail: { status: true, message: 'clear scroll position' } });
        window.dispatchEvent(customEvent);
    }

    useEffect(() => {
        if (debouncedSearchText.trim() && !isSelectedSearchItem) {
            dispatch(getSearchResults(debouncedSearchText));
            dispatch(setOpenSearchDropDown(true));
        } else {
            dispatch(setOpenSearchDropDown(false));
        }
    }, [debouncedSearchText, dispatch, isSelectedSearchItem]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const elements = document.getElementsByClassName('header-search-bar-class');
            if (elements.length === 0) return; // No elements found, exit early
        
            // Loop through elements to check if any contain the event target
            let isOutside = true;
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].contains(event.target as Node)) {
                    isOutside = false;
                    break;
                }
            }
        
            if (isOutside) {
                if (!openSearchDropDown) return;
        
                dispatch(setOpenSearchDropDown(false));
        
                const isIconButtonClicked =
                    event.target === document.getElementById('header_search_icon') ||
                    event.target === document.getElementById('header_search_icon_svg') ||
                    event.target === document.getElementById('header_search_icon_path');
        
                if (!isIconButtonClicked) {
                    dispatch(setFilter({
                        'pageNumber': 1,
                        'pageSize': 30,
                        'searchQuery': '',
                        'searchType': ''
                    }));

                    dispatch(initSearchResult());

                    dispatch(getPosts());
                }
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openSearchDropDown, filter]);

    // Keyboard event handler for '/'
    useEffect(() => {
        const handleKeyPress = (event: any) => {
            if (!openSearchDropDown && event.key === '/') {
                event.preventDefault();
                dispatch(setOpenSearchDropDown(true));

                const ref = document.getElementById('header_search_input_bar');
                if(ref) ref.focus();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openSearchDropDown])

    return (
        <Stack ref={searchRef} className={`${className} header-search-bar-class`}>
            <Stack direction="row" alignItems="center" spacing={1} className={styles.container}>
                <GalleraiIcon name='search' width={17} height={16} />
                <InputBase id='header_search_input_bar' value={filter.searchQuery} onChange={(event) => handleSearchInput(event.target.value)} className={styles.input_base} placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
                {
                    filter.searchQuery != '' && (<GalleraiIcon onClick={handleSearchClose} name='close' style={{ cursor: 'pointer' }} width={10} height={10} />)
                }
                {
                    !openSearchDropDown && (
                        <div className={styles.slash_icon_container} onClick={() => dispatch(setOpenSearchDropDown(!openSearchDropDown))}>
                            <Typography className={styles.slash_text}>/</Typography>
                        </div>
                    )
                }
            </Stack>
            {
                openSearchDropDown && (
                    <Stack className={dropDownClassName}>
                        <Tabs
                            style={{ borderBottom: '1px solid rgba(112, 112, 112, 0.2)', paddingLeft: '1rem', paddingRight: '1rem' }}
                            value={seletedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant='scrollable'
                            scrollButtons={false}
                            onChange={handleTabChange}>
                            {
                                SearchTabs.map((modelTab, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            label={
                                                <p style={{ fontFamily: 'var(--primary-font-family)', fontWeight: '500', fontSize: '14px', lineHeight: '20px' }}>
                                                    {modelTab}
                                                    <span style={{ borderRadius: '4px', padding: '4px 6px', gap: '10px', background: 'var(--secondary-gray-color)', fontWeight: '500', fontSize: '12px', lineHeight: '20px', fontFamily: 'var(--primary-font-family)', marginLeft: '6px' }}>
                                                        {modelTab == 'Prompts' ? searchResults.prompts.length : searchResults.hashtags.length}
                                                    </span>
                                                </p>
                                            }
                                            onClick={() => handleTabClick(modelTab)}
                                            className={seletedTab === index ? styles.selected_tab : styles.unselected_tab} />
                                    );
                                })
                            }
                        </Tabs>
                        {
                            isSearching ?
                                <Stack style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularProgress size={24} />
                                </Stack>
                                :
                                <Stack style={{ height: '100%', overflow: 'scroll' }}>
                                    {
                                        seletedTab == 0 ?
                                            <>
                                                {
                                                    searchResults.prompts.map((prompt, index) => {
                                                        return (
                                                            <Stack key={index} className={styles.searched_item} onClick={() => handleClickSearchItem('prompt', prompt.prompt)}>
                                                                <Typography className={styles.searched_item_title}>
                                                                    {prompt.prompt}
                                                                </Typography>
                                                                <Typography className={styles.searched_item_content}>
                                                                    {numberWithCommas(parseInt(prompt.count, 10))} posts
                                                                </Typography>
                                                            </Stack>
                                                        );
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    searchResults.hashtags.map((hashtag, index) => {
                                                        return (
                                                            <Stack key={index} className={styles.searched_item} onClick={() => handleClickSearchItem('hashtag', hashtag.tag)}>
                                                                <Typography className={styles.searched_item_title}>
                                                                    {hashtag.tag}
                                                                </Typography>
                                                                <Typography className={styles.searched_item_content}>
                                                                    {numberWithCommas(parseInt(hashtag.count, 10))} posts
                                                                </Typography>
                                                            </Stack>
                                                        );
                                                    })
                                                }
                                            </>
                                    }
                                </Stack>
                        }
                    </Stack >
                )
            }
        </Stack >
    );
};

export default HeaderSearchBar;
