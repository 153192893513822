import api from 'utils/api';
import { RootState } from 'store';
import { database } from 'utils/firebase';
import { enqueueSnackbar } from 'notistack';
import { HomeImage, FavoriteType } from 'types/gallerai/homeTypes';
import { Dispatch, createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { HOST } from 'config';
import { generateRandomArray } from 'utils/utils';

interface Redux {
    getState: any;
    dispatch: Dispatch<any>;
    rejectWithValue: any;
}

interface ApiResponse {
    images: any[]; // Replace ImageType with the type of your images
    params: any; // Adjust the type of params as needed
}

export const getPosts = createAsyncThunk<ApiResponse, void>(
    'home/getPosts',
    async (_, { getState, dispatch }: Redux) => {
        const loading = getState().home.loading
        if (loading) {
            return null
        } else {
            dispatch(setLoading(true));
        }
    
        const filter = getState().home.filter;
        if (filter.pageNumber == 1) dispatch(initAdapter());
    
        const response = await api.get('/api/posts', {
            params: {
                pageNumber: filter.pageNumber,
                pageSize: filter.pageSize,
                searchQuery: filter.searchQuery,
                searchType: filter.searchType
            }
        });
    
        return { images: response.data, params: filter };
});

// export const getPosts = createAsyncThunk('home/getPosts', async (_, { getState, dispatch }: Redux) => {
    // const loading = getState().home.loading
    // if (loading) {
    //     return null
    // } else {
    //     dispatch(setLoading(true));
    // }

    // const filter = getState().home.filter;
    // if (filter.pageNumber == 1) dispatch(initAdapter());

    // const response = await api.get(`/api/home/images?pageNumber=${filter.pageNumber}&pageSize=${filter.pageSize}&searchQuery=${filter.searchQuery}&searchType=${filter.searchType}`);

    // return response.data;
// });

export const getNsfwList = createAsyncThunk('home/getNsfwList', async (email: string) => {
    const response = await api.get('/api/nsfws', { params: { email: email } });

    return response.data;
});

export const updateNsfwList = createAsyncThunk('/home/updateNsfwList', async (data: any) => {
    const response = await api.post('/api/nsfws/update', data);

    return response.data;
})

export const updateFavorite = createAsyncThunk('home/updateFavorite', async (obj: FavoriteType) => {
    const data = (await database.collection('posts').doc(obj.item.id).get()).data();
    const favorite = data?.favorite || [];
    const _isFav = favorite.indexOf(obj.email);

    if (obj.isFav) {
        if (_isFav !== -1) {
            favorite.splice(_isFav, 1);
        }
    } else {
        if (_isFav === -1) {
            favorite.push(obj.email);
        }
    }

    await database.collection('posts').doc(obj.item.id).update({ favorite });
    enqueueSnackbar('Favorite Updated!');
    obj.item = { ...obj.item, favorite: favorite };

    return obj;
});

export const deletePost = createAsyncThunk('home/deletePost', async (id: string) => {
    const response = await api.delete(`/api/post/${id}`);

    return response.data;
});

const imagesAdapter = createEntityAdapter<HomeImage>({});

export const { selectById: selectImageById, selectAll: selectAllImages } = imagesAdapter.getSelectors(
    (state: RootState) => state.home
);

export const homeSlice = createSlice({
    name: 'home',
    initialState: imagesAdapter.getInitialState({
        loading: false,
        isDeleting: false,
        deleteResult: {
            status: false,
            message: ''
        },
        nsfwList: { hide: [], unhide: [] },
        filter: {
            pageNumber: 1,
            pageSize: 30,
            searchType: '',
            searchQuery: ''
        },
        savedScrollTop: 0,
        savedImageId: null
    }),
    reducers: {
        initDeleteResult: (state) => {
            state.deleteResult.status = false;
            state.deleteResult.message = '';
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = { ...state.filter, ...action.payload };
        },
        initAdapter: (state) => {
            imagesAdapter.removeAll(state);
        },
        setSavedScrollTop: (state, action) => {
            state.savedScrollTop = action.payload;
        },
        setSavedImageId: (state, action) => {
            state.savedImageId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPosts.fulfilled, (state, action) => {
                if (action.payload && action.payload?.images) {
                    const { images, params } = action.payload;
                    
                    const ads = generateRandomArray(images.length);
                    images.map((item: any, index: number) => {
                        const isAd = ads.includes(index);

                        if(isAd) {
                            const adImage = {
                                id: `${item.id}_ad`,
                                isAd: true,
                                width: 0,
                                height: 0,
                                thumbnail: `${HOST}/images/injected_ad_placeholder.png`,
                                original: `${HOST}/images/injected_ad_placeholder.png`,
                                caption: '',
                                isNSFW: false,
                                hideSeed: false,
                                createdAt: '0',
                                favorite: [],
                                metadata: null,
                                modelId: null,
                                user: null
                            };

                            imagesAdapter.addOne(state, adImage);
                        }

                        const newImage = {
                            id: item.id,
                            isAd: false,
                            width: item.width,
                            height: item.height,
                            thumbnail: item.thumbnail,
                            original: item.original,
                            caption: item.caption,
                            isNSFW: item.isNSFW,
                            hideSeed: item.hideSeed,
                            createdAt: item.createdAt,
                            favorite: item.favorite,
                            metadata: item.metadata,
                            modelId: item.modelId,
                            user: item.user
                        };
    
                        imagesAdapter.addOne(state, newImage);
                    });
                    state.loading = false;
    
                    if (images.length > 0 && params.pageNumber == state.filter.pageNumber) {
                        state.filter.pageNumber += 1;
                    }
                } else {
                    state.loading = false;
                }
            })
            .addCase(getPosts.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getNsfwList.fulfilled, (state, action) => {
                const data = action.payload;
                state.nsfwList.hide = data?.hide || [];
                state.nsfwList.unhide = data?.unhide || [];
            })
            .addCase(updateFavorite.fulfilled, (state, action) => {
                console.log(state);
                console.log(action);

                imagesAdapter.upsertOne(state, action.payload.item);
            })
            .addCase(deletePost.pending, (state) => {
                state.isDeleting = true;
            })
            .addCase(deletePost.fulfilled, (state, action) => {
                if (action.payload.status == true) {
                    imagesAdapter.removeOne(state, action.meta.arg);
                    state.deleteResult.status = true;
                    state.deleteResult.message = action.payload.message ?? 'success';
                } else {
                    state.deleteResult.status = false;
                    state.deleteResult.message = 'Unexpected error occurred';
                }
                state.isDeleting = false;
            })
            .addCase(deletePost.rejected, (state) => {
                state.isDeleting = false;
            });
    }
});

export const { setLoading, setFilter, initAdapter, initDeleteResult, setSavedScrollTop, setSavedImageId } = homeSlice.actions;

export default homeSlice.reducer;
