import { Box, Stack, Typography } from '@mui/material';
import { getNsfwList, setSavedImageId } from 'store/home';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store';
import useResponsive from 'hooks/useResponsive';
import { AuthContext } from 'contexts/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import styles from './home-detail-modal.module.scss';
import { getPost, setKeepRelatedImagesBox } from 'store/home/detail';
import ImageInfo from 'components/home/detail/image-info';
import RelatedImages from 'components/home/detail/related-images';
import ImageContainer from 'components/home/detail/image-container';
import Header from 'components/header/header';
import { Footer } from 'components/footer';
import WestIcon from '@mui/icons-material/West';
import GalleraiCookieConsent from 'components/shared/gallerai-cookie-consent';
import useRole from 'hooks/useRole';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// import Footer from 'components/footer';

const HomeDetailModal = () => {
    const modalRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();    

    // Hook
    const { id } = useParams();
    const isDownMD = useResponsive('down', 'md');

    // Context
    // const { isAdmin } = useAdmin();
    const { isAdmin } = useRole();
    const { user: currentUser } = useContext(AuthContext);

    // Component state
    const [image, setImage] = useState(null);
    const [isNSFW, setIsNSFW] = useState(false);
    const [hideSeed, setHideSeed] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    // Redux state
    const nsfwList = useSelector((state: RootState) => state.home.nsfwList);
    const savedScrollTop = useSelector((state: RootState) => state.home.savedScrollTop);
    const keepRelatedImagesBox = useSelector((state: RootState) => state.homeDetail.keepRelatedImagesBox);

    const handleImageLoad = () => setImageLoaded(true);
    const handleImageError = () => setImageLoaded(false);

    const handleBack = () => {
        const homePage = document.getElementById('home-list');
        if(homePage) {
            homePage.style.display = 'block';
        }

        dispatch(setSavedImageId(null));
        navigate('/home');
    }

    // Get image info
    useEffect(() => {
        if(!id) return;

        dispatch(getPost(id)).then((res) => {
            const data = res.payload;
            setImage(data);
        });

        // eslint-disable-next-line
    }, [id]);

    // Handle location when user refresh or reload page, it will also handle when user click browser back button
    const handleLocation = () => {
        const remainingPath = window.location.href.substring(window.location.origin.length);
        if (remainingPath === `/home/${id}`) {
            console.log('URL has changed!', remainingPath);
            const element = document.getElementById('home-list');
            if(element) {
                element.style.display = 'none';
            }
        }
    }

    useEffect(() => {
        handleLocation();
        // const observerRefValue = modalRef.current;
        // disableBodyScroll(observerRefValue);
        
        return () => {
            // if (observerRefValue) {
                // enableBodyScroll(observerRefValue);
                // console.log('------------------ From savedScrollTop', savedScrollTop);
                const element = document.getElementById('home-list');
                if(element) {
                    element.style.display = 'block';

                    // setTimeout(() => {
                    //     window.scrollTo(0, savedScrollTop);
                    // }, 100); // Adjust the delay as needed
                }

                dispatch(setKeepRelatedImagesBox(true));

                setTimeout(() => {
                    window.scrollTo(0, savedScrollTop);
                }, 10); // Adjust the delay as needed
            // }
        };

        // eslint-disable-next-line
    }, []);

    // Handle nsfw flag once image is loaded
    useEffect(() => {
        if(!image) return;

        setHideSeed(image?.hideSeed ?? false);

        if(currentUser) {
            let lists = null;
            dispatch(getNsfwList(currentUser?.email)).then((res) => {
                lists = res.payload;
    
                let hide_list = [];
                let unhide_list = [];
    
                hide_list = lists?.hide || [];
                unhide_list = lists?.unhide || [];
    
                if (image?.isNSFW) {
                    if (unhide_list.indexOf(id) !== -1) {
                        setIsNSFW(false)
                    } else {
                        setIsNSFW(true);
                    };
                } else {
                    if (hide_list.indexOf(id) !== -1) {
                        setIsNSFW(true)
                    } else {
                        setIsNSFW(false);
                    };
                }
            });
        } else {
            setIsNSFW(image?.isNSFW);
        }
        //eslint-disable-next-line
    }, [image, id, currentUser]);

    return (
        // <Stack id='home-detail-container' ref={modalRef} position="relative" className='modal-wrapper'>
        <Stack id='home-detail-container' ref={modalRef} position="relative">
            <Header type='main' />
            <div className={styles.btn_back_to_home_container} onClick={handleBack}>
                <WestIcon style={{ color: 'var(--secondary-color)', width: '24px', height: '24px' }} />
                <Typography className={styles.btn_back_to_home}>Back</Typography>
            </div>
            <Box
                sx={{
                    marginTop: (theme) => theme.spacing(7),
                    paddingTop: (theme) => theme.spacing(4),
                    // paddingBottom: (theme) => theme.spacing(6),
                    minHeight: 'calc(100vh - 120px)',
                    justifyContent: 'center',
                    display: 'flex',
                    zIndex: 10,
                    overflowY: 'scroll'
                }}
            >
                <Stack sx={{ width: '100%', px: 0 }}>
                    <Stack key={`home_detail_${id}`} className={`${styles.main_container}`}>
                        <Stack className={!isDownMD ? styles.image_main_container : styles.image_main_container_md}>
                            <ImageContainer
                                // key={`home_detail_${id}_image_container`}
                                image={image}
                                imageLoaded={imageLoaded}
                                user={currentUser}
                                isNSFW={isNSFW}
                                hideSeed={hideSeed}
                                handleNSFW={setIsNSFW}
                                onLoad={handleImageLoad}
                                onError={handleImageError}
                            />
                            <ImageInfo
                                // key={`home_detail_${id}_image_info`}
                                id={id}
                                image={image}
                                imageLoaded={imageLoaded}
                                isDownMD={isDownMD}
                                user={currentUser}
                                isAdmin={isAdmin}
                                isNSFW={isNSFW}
                                nsfwList={nsfwList}
                                handleNSFW={setIsNSFW}
                                hideSeed={hideSeed}
                                handleHideSeed={setHideSeed}
                            />
                        </Stack>
                    </Stack>
                    {keepRelatedImagesBox && (<RelatedImages id={id} user={currentUser} nsfwList={nsfwList}/>)}
                </Stack>
            </Box>
            <Footer />
            <GalleraiCookieConsent />
        </Stack>
    );
}

export default HomeDetailModal;