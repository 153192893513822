export const NETWORKS = {
    sui: {
        value: 'sui',
        id: '101',
        token: 'SUI',
        scan: 'https://suiscan.xyz',
        rpcUrl: 'https://sui-rpc.publicnode.com',
        chainName: 'Sui',
        // imageUrl: '/images/sui.svg',
        // imageAlt: 'sui'
        icon: 'sui'
    },
    sui_testnet: {
        value: 'sui_testnet',
        id: '101',
        token: 'tSUI',
        scan: 'https://suiscan.xyz',
        rpcUrl: 'https://sui-rpc.publicnode.com',
        chainName: 'Sui Testnet',
        // imageUrl: '/images/sui.svg',
        // imageAlt: 'sui'
        icon: 'sui'
    },
    // binance: {
    //     value: 'binance',
    //     id: '0x38',
    //     token: 'BNB',
    //     scan: 'https://bscscan.com/',
    //     rpcUrl: 'https://koge-rpc-bsc.48.club',
    //     chainName: 'Binance Smart Chain',
    //     // imageUrl: '/images/bnb.svg',
    //     // imageAlt: 'bnb',
    //     icon: 'binance'
    // },
    // binance_testnet: {
    //     value: 'binance_testnet', 
    //     id: '0x61',
    //     token: 'tBNB',
    //     scan: 'https://testnet.bscscan.com/',
    //     rpcUrl: 'https://bsc-testnet-rpc.publicnode.com',
    //     chainName: 'Binance Smart Chain Testnet',
    //     icon: 'binance'
    // },
    // ethereum: {
    //     value: 'ethereum',
    //     id: '0x1',
    //     token: 'ETH',
    //     scan: 'https://etherscan.io/',
    //     rpcUrl: 'https://ethereum.publicnode.com',
    //     chainName: 'Ethereum',
    //     // imageUrl: '/images/eth.svg',
    //     // imageAlt: 'eth'
    //     icon: 'ethereum'
    // },
    // polygon: {
    //     value: 'polygon',
    //     id: '0x89',
    //     token: 'MATIC',
    //     scan: 'https://polygonscan.com/',
    //     rpcUrl: 'https://polygon.llamarpc.com',
    //     chainName: 'Polygon',
    //     // imageUrl: '/images/polygon.webp',
    //     // imageAlt: 'matic',
    //     icon: 'polygon'
    // },
    // bsctest: {
    //     testnet: true,
    //     value: 'bsctest',
    //     id: '0x61',
    //     token: 'BNB',
    //     scan: 'https://testnet.bscscan.com/',
    //     rpcUrl: 'https://bsc-testnet.publicnode.com',
    //     chainName: 'Binance Smart Chain Test Net',
    //     imageUrl: '/images/bnb.svg',
    //     imageAlt: 'tBNB'
    // },
    // goerlitest: {
    //     testnet: true,
    //     value: 'goerlitest',
    //     id: '0x5',
    //     token: 'ETH',
    //     scan: 'https://goerli.etherscan.io/',
    //     rpcUrl: 'https://ethereum-goerli.publicnode.com',
    //     chainName: 'Goerli Ethereum Test Net',
    //     imageUrl: '/images/eth.svg',
    //     imageAlt: 'eth'
    // },
    // mumbai: {
    //     testnet: true,
    //     value: 'mumbai',
    //     id: '0x13881',
    //     token: 'MATIC',
    //     scan: 'https://mumbai.polygonscan.com/',
    //     rpcUrl: 'https://polygon-mumbai-bor.publicnode.com',
    //     chainName: 'Mumbai',
    //     // imageUrl: '/images/polygon.webp',
    //     // imageAlt: 'matic',
    //     icon: 'polygon'
    // }
};

export const LORAS = [
    {
        name: 'Misfits (Sui)',
        key: 'misfits',
        value: '<lora:misfits1:1>',
        description: 'Generate traits from Misfits NFT.',
        url: '/images/lora/misfits.webp'
    },
    {
        name: 'Eyes',
        key: 'eyes',
        value: 'Lora eyes <lora:Loraeyes:0.8>',
        description: 'Enhance deformed eyes in your image.',
        url: '/images/lora/eyes.webp'
    },
    {
        name: 'Add More Details|Detail Enhancer',
        key: 'enhaner',
        value: '<lora:more_details:0.8>',
        description: 'Enhance outputs with greater detail.',
        url: '/images/lora/enhancer.webp'
    },
    {
        name: 'Instant photo/Polaroid',
        key: 'instant',
        value: '<lora:InstantPhoto:0.8>',
        description: 'Replicate quality Polaroid photos.',
        url: '/images/lora/instant.webp'
    },
    {
        name: 'All Disney Princess',
        key: 'pricess',
        value: '<lora:princess_xl:0.8>',
        description: 'Infuse your outputs with Disney magic.',
        url: '/images/lora/princess.webp'
    },
    {
        name: 'Fog Things',
        key: 'fog',
        value: '<lora:fog_creatures:0.8>',
        description: 'Conjure a misty haze to any scene.',
        url: '/images/lora/fog.webp'
    }
]

export const RESOLUTIONS = [
    {
        ratio: '1:1',
        value: '1024 x 1024',
        icon: {
            width: 20,
            height: 20
        },
        width: 1024,
        height: 1024
    },
    {
        ratio: '2:3',
        value: '682 x 1024',
        icon: {
            width: 14,
            height: 20,
        },
        width: 682,
        height: 1024
    },
    {
        ratio: '3:2',
        value: '1024 x 682',
        icon: {
            width: 20,
            height: 14
        },
        width: 1024,
        height: 682
    },
    {
        ratio: '3:4',
        value: '768 x 1024',
        icon: {
            width: 20,
            height: 16
        },
        width: 768,
        height: 1024
    },
    {
        ratio: '4:3',
        value: '1024 x 768',
        icon: {
            width: 16,
            height: 20
        },
        width: 1024,
        height: 768
    },
    {
        ratio: '9:16',
        value: '576 x 1024',
        icon: {
            width: 20,
            height: 12
        },
        width: 576,
        height: 1024
    },
    {
        ratio: '16:9',
        value: '1024 x 576',
        icon: {
            width: 12,
            height: 20
        },
        width: 1024,
        height: 576
    }
]

export const InitResolutionForPaidUser = {
    width: 512,
    height: 512
}

export const MaxResolution = {
    width: 1024,
    height: 1024
}

export const ZoomLimitation = {
    max: 3200,
    min: 3
}

export const defaultZoomPercent = 50;

export const Samplers = [
    { value: 'DPM++ 2M', label: 'DPM++ 2M' },
    { value: 'DPM++ SDE', label: 'DPM++ SDE' },
    { value: 'DPM++ 2M SDE', label: 'DPM++ 2M SDE' },
    { value: 'DPM++ 2M SDE Heun', label: 'DPM++ 2M SDE Heun' },
    { value: 'DPM++ 2S a', label: 'DPM++ 2S a' },
    { value: 'DPM++ 3M SDE', label: 'DPM++ 3M SDE' },
    { value: 'Euler a', label: 'Euler a' },
    { value: 'Euler', label: 'Euler' },
    { value: 'LMS', label: 'LMS' },
    { value: 'Heun', label: 'Heun' },
    { value: 'DPM2', label: 'DPM2' },
    { value: 'DPM2 a', label: 'DPM2 a' },
    { value: 'DPM fast', label: 'DPM fast' },
    { value: 'DPM adaptive', label: 'DPM adaptive' },
    { value: 'Restart', label: 'Restart' },
    { value: 'DDIM', label: 'DDIM' },
    { value: 'PLMS', label: 'PLMS' },
    { value: 'UniPC', label: 'UniPC' },
    { value: 'LCM', label: 'LCM' },
];

export const Schedulers = [
    { value: 'automatic', label: 'Automatic' },
    { value: 'uniform', label: 'Uniform' },
    { value: 'karras', label: 'Karras' },
    { value: 'exponential', label: 'Exponential' },
    { value: 'polyexponential', label: 'Polyexponential' },
    { value: 'sgm_uniform', label: 'Sgm Uniform' }
];