import injectedModule from '@web3-onboard/injected-wallets';
import { init, Web3OnboardProvider } from '@web3-onboard/react';

const ethereum = {
    id: '0x1',
    token: 'ETH',
    label: 'Ethereum',
    rpcUrl: 'https://ethereum.publicnode.com'
};

const binance = {
    id: '0x38',
    token: 'BNB',
    label: 'Binance Smart Chain',
    rpcUrl: 'https://koge-rpc-bsc.48.club'
};

const bsctest = {
    id: '0x61',
    token: 'tBNB',
    label: 'Binance Smart Chain Test Net',
    rpcUrl: 'https://bsc-testnet.publicnode.com'
};

const goerlitest = {
    id: '0x5',
    token: 'ETH',
    label: 'Goerli Ethereum Test Net',
    namespace: 'evm',
    rpcUrl: 'https://ethereum-goerli.publicnode.com'
};

const mumbai = {
    id: '0x13881',
    token: 'MATIC',
    label: 'Mumbai Polygon Test Net',
    namespace: 'evm',
    rpcUrl: 'wss://polygon-mumbai-bor.publicnode.com'
};

const polygon = {
    id: '0x89',
    token: 'MATIC',
    label: 'Polygon',
    namespace: 'evm',
    rpcUrl: 'https://polygon.llamarpc.com'
};

const chains = [binance, ethereum, bsctest, goerlitest, mumbai, polygon];
const wallets = [injectedModule()];
const web3Onboard = init({
    wallets,
    chains,
    appMetadata: {
        name: 'Gallerai',
        icon: '<svg>App Icon</svg>',
        description: 'images by prompts'
    },
    theme: 'dark',
    accountCenter: { mobile: { enabled: false }, desktop: { enabled: false } }
});
function OnBoardProvider({ children }) {
    return <Web3OnboardProvider web3Onboard={web3Onboard}>{children}</Web3OnboardProvider>;
}
export default OnBoardProvider;
