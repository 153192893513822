import Iconify from 'components/iconify/Iconify';

const navConfig = [
    { title: 'pricing', path: '/pricing' },
    { title: 'legal', path: '/legal' },
    { title: 'Site Map', path: '/site_map' }
];

export const socials = [
    {
        title: 'Youtube',
        path: 'https://www.youtube.com/@GalleraiOfficial',
        icon: <Iconify icon="bi:youtube" width={16} height={16} />
    },
    {
        title: 'Tiktok',
        path: 'https://www.tiktok.com/@galleraiofficial',
        icon: <Iconify icon="bi:tiktok" width={16} height={16} />
    },
    {
        title: 'Instagram',
        path: 'https://www.instagram.com/galleraiofficial',
        icon: <Iconify icon="bi:instagram" width={16} height={16} />
    }
];

export const socialsLarge = [
    {
        title: 'Youtube',
        path: 'https://www.youtube.com/@GalleraiOfficial',
        icon: <Iconify icon="bi:youtube" width={28} height={22} />
    },
    {
        title: 'Tiktok',
        path: 'https://www.tiktok.com/@galleraiofficial',
        icon: <Iconify icon="bi:tiktok" width={22} height={22} />
    },
    {
        title: 'Instagram',
        path: 'https://www.instagram.com/galleraiofficial',
        icon: <Iconify icon="bi:instagram" width={22} height={22} />
    }
];

export default navConfig;
