export const InitFilter = {
    pageNumber: 1,
    pageSize: 30,
    searchType: '',
    searchQuery: ''
}

export const InitScrollTopMax = 9999999;

export const HomeBreakPointCols = {
    default: 8,
    2560: 8,
    2048: 7,
    1920: 6,
    1600: 5,
    1366: 4,
    1280: 4,
    960: 3,
    600: 2,
    480: 1
};
