import '../assets/css/index.css';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';
import { useMemo } from 'react';
import customShadows from 'theme/customShadows';
import GlobalStyles from 'theme/globalStyles';
import componentsOverride from 'theme/overrides';
import palette from 'theme/palette';
import shadows from 'theme/shadows';
import typography from 'theme/typography';

type MuiThemeProviderProps = {
    children: React.ReactNode;
};

// ** Declare Theme Provider
const MuiThemeProvider: React.FC<MuiThemeProviderProps> = ({ children }) => {
    const { themeMode, themeDirection } = useConfig();

    const themeOptions = useMemo(
        () => ({
            palette: palette(themeMode),
            typography,
            shape: { borderRadius: 8 },
            direction: themeDirection,
            shadows: shadows(themeMode),
            customShadows: customShadows(themeMode)
        }),
        [themeDirection, themeMode]
    ) as any;

    const theme = createTheme(themeOptions);

    theme.components = componentsOverride(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </MUIThemeProvider>
        </StyledEngineProvider>
    );
};

export default MuiThemeProvider;
