// import { Button, Stack, Typography } from '@mui/material';
import { Stack } from '@mui/material';
// import Image from 'components/image';
// import { HOST } from 'config';
// import { useState } from 'react';
import { Link } from 'react-router-dom';
// import useResponsive from '../../../hooks/useResponsive';
// import MenuPopover from '../../menu-popover';
import styles from './header-main-menu.module.scss';
// import GalleraiIcon from 'components/shared/gallerai-icon/galleriai-icon';

const HeaderMainMenu = () => {
    // State
    // const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    // Hook
    // const isTablet = useResponsive('down', 1024);

    // const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    //     setOpenPopover(event.currentTarget);
    // };

    // const handleClosePopover = () => {
    //     setOpenPopover(null);
    // };

    return (
        <Stack style={{ display: 'flex', alignItems: 'center', gap: '0.75rem', flexDirection: 'row' }}>
            <Link to="/tools/" className={styles.link}>
                Tools
            </Link>
            <Link to="/pricing/" className={styles.link}>
                Pricing
            </Link>
            <Link to="/forum/topic/list/" className={styles.link}>
                Resources
            </Link>
        </Stack>
    );
};

export default HeaderMainMenu;
